import React, { createContext, useContext, useState } from "react";
import { v4 } from "uuid";
// Create the context
const ReportEditorContext = createContext();

// Custom hook to use the context
export const useReportEditor = () => useContext(ReportEditorContext);

// Provider component
export const ReportEditorProvider = ({ children }) => {
  const [elements, setElements] = useState([]);
  const [pageStructure, setPageStructure] = useState([]);
  const [activeElementId, setActiveElementId] = useState(null);
  const [headings, setHeadings] = useState([]);

  function elementSetter(id, elements) {
    setPageStructure((prev) => [...prev, { id, elements }]);
  }

  const [activeElementIndex, setActiveElementIndex] = useState(null);
  // Add history stacks for undo/redo
  const [history, setHistory] = useState([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(-1);

  // Helper to save state to history
  const saveToHistory = (newElements) => {
    const newHistory = history.slice(0, currentHistoryIndex + 1);
    newHistory.push([...newElements]);
    setHistory(newHistory);
    setCurrentHistoryIndex(newHistory.length - 1);
  };

  // Modified setElements to track history
  const updateElements = (newElements) => {
    setElements(newElements);
    saveToHistory(newElements);
  };

  const undo = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(currentHistoryIndex - 1);
      setElements([...history[currentHistoryIndex - 1]]);
    }
  };

  const redo = () => {
    if (currentHistoryIndex < history.length - 1) {
      setCurrentHistoryIndex(currentHistoryIndex + 1);
      setElements([...history[currentHistoryIndex + 1]]);
    }
  };

  const addElement = (
    type,
    activePageId,
    content = {},
    chartConfig = null,
    chartType = null,
    cardData = null,
    x = 50,
    y = 50,
    additionalProps = {},
    shape = {}
  ) => {
    const elementId = v4();
    const newElement = {
      type: type,
      content:
        type === "heading"
          ? "Heading Text"
          : type === "subheading"
          ? "Subheading Text"
          : type === "image"
          ? ""
          : type === "chart"
          ? ""
          : type === "data-card"
          ? ""
          : type === "content"
          ? ""
          : type === "icon"
          ? ""
          : type === "shape"
          ? ""
          : "Body Text",
      fontSize: type === "heading" ? 32 : type === "subheading" ? 24 : 16,
      color: "#000000",
      backgroundColor: "#ffffff",
      borderColor: "#ffffff",
      borderWidth: "0",
      borderStyle: "none",
      borderRadius: "",
      fontWeight:
        type === "heading" ? "bold" : type === "subheading" ? "600" : "normal",
      fontStyle: "normal",
      fontFamily: "calibri",
      textAlign: "left",
      letterSpacing: 0,
      lineHeight: 1.5,
      opacity: 1,
      x: cardData?.x || x,
      y: cardData?.y || y,
      editable:
        type !== "image" &&
        type !== "chart" &&
        type !== "data-card" &&
        type !== "content-style" &&
        type !== "icon"&&
        type !== "shape",
      imageUrl: type === "image" ? content : null,
      // iconUrl: type === "icon" ? content : null,
      iconUrl: type === "icon" ? content : null, // Assign content.name for icon URL
      component: type === "icon" ? content.component : null,
      chartConfig: type === "chart" ? chartConfig : null,
      chartType: type === "chart" ? chartType : null,
      width:
        type === "image" || type === "chart"
          ? 400
          : type === "data-card"
          ? 200
          : type === "heading"
          ? 200
          : type === "subheading"
          ? 200
          : type === "body"
          ? 100
          : "auto",
      height:
        type === "image" || type === "chart"
          ? 300
          : type === "data-card"
          ? 100
          : type === "heading"
          ? 45
          : type === "subheading"
          ? 40
          : type === "body"
          ? 30
          : "auto",
      cardData:
        type === "data-card"
          ? cardData
          : type === "content-style"
          ? additionalProps
          : null,
      //cardData: type === "data-card" || type === "content-style" ? additionalProps : null,
      activePageId,
      shape: type === "shape" ? shape : null,
      elementId: elementId,
      // component: type === "icon" ? FaBeer : null,
      ...additionalProps,
    };

    // If it's a heading or subheading, add it to the headings array
    if (type === "heading" || type === "subheading") {
      setHeadings((prevHeadings) => [
        ...prevHeadings,
        {
          title: newElement.content,
          level: type === "heading" ? 0 : 1,
          pageNumber: activePageId, // Assuming activePageId can be used as page number
        },
      ]);
    }

    const toc = generateTOC();
    // console.log(toc, "head");
    // console.log("Content:", content);

    setActiveElementId(elementId);
    const newElements = [...elements, newElement];
    updateElements(newElements);
    setActiveElementIndex(elements.length);
    // console.log(newElements, "newElements");
    // console.log(headings, "headings");
    return newElements;
  };

  const generateTOC = () => {
    return headings.map((heading, index) => {
      return {
        title: heading.title,
        level: heading.level,
        pageNumber: heading.pageNumber,
      };
    });
  };

  const updateElementPosition = (index, x, y, elementId) => {
    const updatedElements = [...elements];
    // updatedElements[index].x = x;
    // updatedElements[index].y = y;

    // Update single element by id
    for (let i = 0; i < updatedElements.length; i++) {
      if (updatedElements[i].elementId === elementId) {
        updatedElements[i].x = x;
        updatedElements[i].y = y;
      }
    }
    // updatedElements.filter(x=>x.elementId===elementId)?.[0]?.x = x;
    // updatedElements.filter(x=>x.elementId===elementId)?.[0]?.y = y;

    updateElements(updatedElements);
    elementSetter(updatedElements[index]?.activePageId, updatedElements);

    return updatedElements;
  };

  const updateActiveElement = (key, value, elementId) => {
    if (elementId !== null) {
      const updatedElements = [...elements];

      for (let i = 0; i < updatedElements.length; i++) {
        if (updatedElements[i].elementId === elementId) {
          updatedElements[i][key] = value;
        }
      }
      updateElements(updatedElements);
      return updatedElements;
    }
  };

  const duplicateElement = (index, elementId, activePageId) => {
    const elementToDuplicate = elements.filter(
      (x) => x.elementId === elementId
    )?.[0];
    if (!elementToDuplicate) return;
    if (elementToDuplicate?.activePageId === activePageId) {
      const duplicatedElement = {
        ...elementToDuplicate,
        elementId: v4(),
        x: elementToDuplicate.x + 30,
        y: elementToDuplicate.y + 30,
      };
      const newElements = [...elements, duplicatedElement];
      updateElements(newElements);
      return newElements;
    }
  };

  const deleteElement = (index, elementId, activePageId) => {
    if (!elementId) return;
    const newElements = [
      ...elements?.filter((element) => element?.elementId !== elementId),
    ];
    updateElements(newElements);
    return newElements;
  };

  return (
    <ReportEditorContext.Provider
      value={{
        elements,
        setElements: updateElements,
        activeElementIndex,
        addElement,
        updateElementPosition,
        updateActiveElement,
        duplicateElement,
        deleteElement,
        setActiveElementIndex,
        activeElementId,
        setActiveElementId,
        undo,
        redo,
        elementSetter,
        pageStructure,
        canUndo: currentHistoryIndex > 0,
        canRedo: currentHistoryIndex < history.length - 1,
      }}
    >
      {children}
    </ReportEditorContext.Provider>
  );
};
