import React, { useState } from "react";
import flightIcon from "../../../../../assets/Bulk Upload.png";
import CreateReportModal from "../components/CreateReportModal";
import Pagination from "../../../../../components/pagination/Pagination";
import ConfirmModal from "../../../../../components/Modal/ConfirmModal";
import TypeConfirmModal from "../../../../../components/Modal/TypeConfirmModal";
import { useQuery } from "react-query";
import { api } from "../../../../../api/report";
import toast from "react-hot-toast";

import { ScaleLoader } from "react-spinners";
import TypeSearch from "../../../../../components/Inputs/TypeSearch";
import Summary from "../components/Summary";
import moment from "moment";
import { useNavigate } from "react-router";


const ViewReport: React.FC = () => {
  const navigate = useNavigate();
  const [openNewReportModal, setOpenNewReportModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<Admin | null>(null);

  const user = JSON.parse(window.sessionStorage.getItem("user") || "{}");
  const orgId = user?.org?.id;

  type DropdownType = string | null;
  type SelectedItemsType = Array<string | number>;
  

  const [selectedItems, setSelectedItems] = useState<SelectedItemsType>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [currentDropdown, setCurrentDropdown] = useState<DropdownType>(null);
  const [sortBy, setSortBy] = useState<string>("createdAt"); // Default sort
  const [filterBy, setFilterBy] = useState<string | null>(null);

  type Admin = {
    id: string | number;
    name: string;
    email: string;
    type: string;
    createdAt: string | number
    updatedAt: string | number
    // Add other fields that are part of your admin data
  };

  type QueryResponse = {
    data: Admin[];
    total: number;
    currentPage: number;
    perPage: number;
    meta?:{}
   
  };

  type QueryError = {
    response: {
      data: {
        message: string;
       
      };
    };
  };

  const {
    data: admins,
    isLoading,
    refetch,
  } = useQuery<QueryResponse, QueryError>(
    ["all-admins", search, perPage, currentPage],
    async () => {
      const response = await api.getAllReports({
        orgId,
        search,
        perPage,
        currentPage,
      });
      return response; // Ensure the API response matches the `QueryResponse` type
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );


  // const handleSelectAll = (): void => {
  //   if (selectAll) {
  //     setSelectedItems([]);
  //   } else {
  //     setSelectedItems(admins?.map((content: Admin) => content.id)); // Explicitly typing 'content'
  //   }
  //   setSelectAll(!selectAll);
  // };

  const handleSelect = (id: string | number): void => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const toggleDropdown = (dropdown: string): void => {
    setCurrentDropdown(currentDropdown === dropdown ? null : dropdown);
  };

  const handleSort = (type: string): void => {
    setSortBy(type);
    setCurrentDropdown(null);
    toast.success(`Sorted by ${type}`);
  };

  const handleFilter = (type: string): void => {
    setFilterBy(type);
    setCurrentDropdown(null);
    toast.success(`Filtered by ${type}`);
  };

  // const isLoading = false
  // const admins: never[] = []

 
  const deleteAdmin = async () => {
    if (!selectedReport) return;
    try {
      const response = await api.deleteReport({
        orgId,
        reportId: selectedReport.id,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="p-5 sm:p-10">
      <div>
        <div className="flex justify-between">
          <h1 className="text-lg font-semibold text-[#091F46]">Report</h1>
          <button
            type="button"
            onClick={() => {
              setOpenNewReportModal(true);
            }}
            className="p-3 bg-customBlue text-white text-sm rounded-md"
          >
            <i className="bi bi-plus-lg"></i> Create Report
          </button>
        </div>
      </div>
      <div className="flex justify-center">
        <Summary />
      </div>
      <div className="p-4 md:p-6 overflow-auto ">
        <div className="flex gap-4 justify-between items-center mb-3">
          <div className="w-1/2">
            <TypeSearch
              onChange={setSearch}
              placeholder="Search Content"
              className={"w-full"}
              // debounceDelay={500} // Optional, defaults to 300ms
            />
          </div>
          <div className="flex gap-2 text-[#667085] text-sm font-medium">
            {/* Sort by Button */}
            <div className="relative">
              <button
                className="bg-white rounded-md shadow-lg border px-4 py-3"
                onClick={() => toggleDropdown("sort")}
              >
                Sort by <i className="ml-1 bi bi-sliders"></i>
              </button>
              {currentDropdown === "sort" && (
                <div className="absolute mt-2 bg-white border rounded-md shadow-lg">
                  <ul>
                    <li
                      onClick={() => handleSort("alphabet")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Alphabet
                    </li>
                    <li
                      onClick={() => handleSort("createdAt")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Date
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* Filter by Button */}
            <div className="relative">
              <button
                className="bg-white rounded-md shadow-lg border px-4 py-3"
                onClick={() => toggleDropdown("filter")}
              >
                Filter by <i className="ml-1 bi bi-sliders"></i>
              </button>
              {currentDropdown === "filter" && (
                <div className="absolute mt-2 bg-white border rounded-md shadow-lg">
                  <ul>
                    <li
                      onClick={() => handleFilter("draft")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Draft
                    </li>
                    <li
                      onClick={() => handleFilter("published")}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                    >
                      Published
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* <div>
              {selectedItems.length > 0 && (
                <button
                  onClick={() => handleDeleteMultiple()}
                  className="bg-white rounded-md shadow-lg border px-4 py-3"
                >
                  Delete
                  <i className="bi bi-trash3-fill"></i>
                </button>
              )}
            </div> */}
          </div>
        </div>

        <div className="bg-white p-3 rounded-lg shadow-sm">
          {isLoading ? (
            <div className="mt-10 flex justify-center items-center">
              <ScaleLoader color="#FF9100" />
            </div>
          ) : (
            <div className="mt-4 ">
              <div className="">
                <table className="table-container">
                  <thead className="table-header">
                    <tr>
                      <th className="table-head text-left">
                        <input
                          type="checkbox"
                          checked={selectAll}
                          // onChange={handleSelectAll}
                        />
                      </th>
                      <th className="table-head text-left">S/N</th>
                      <th className="table-head text-left">Report Name</th>
                      <th className="table-head">Type</th>
                      <th className="table-head">Status</th>
                      <th className="table-head">Created By</th>
                      <th className="table-head">Last Updated</th>
                      <th className="table-head">Action</th>
                    </tr>
                  </thead>

                  <tbody className="table-body text-xs">
                    {admins?.data && admins.data.length > 0 ? (
                      admins.data.map((admin, index) => (
                        <tr key={index}>
                          <td className="table-data">
                            <input
                              type="checkbox"
                              checked={selectedItems.includes(admin.id)}
                              onChange={() => handleSelect(admin.id)}
                            />
                          </td>
                          <td className="table-data">{index + 1}</td>
                          <td className="table-data text-left">{admin.name}</td>
                          <td className="table-data text-left">
                            {admin.name}
                          </td>
                          <td className="table-data text-left">
                            {admin.email}
                          </td>

                          <td className="table-data ">
                            {moment(admin.createdAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>
                          <td className="table-data ">
                            {moment(admin.updatedAt).format(
                              "YYYY/MM/DD, h:mm a"
                            )}
                          </td>

                          <td className="table-data flex gap-4 justify-center px-2">
                            <i
                              className="bi bi-pencil-fill cursor-pointer"
                              onClick={() => {
                                navigate(
                                  `/admin/report/create?action=edit&reportId=${admin.id}&type=${admin.type}`
                                );
                                // setSelectedUser(admin);
                                // setOpenNewUserModal(true);
                              }}
                            ></i>
                            <i
                              className="bi bi-trash3-fill  text-red-500 cursor-pointer"
                              onClick={() => {
                                setSelectedReport(admin);
                                setDeleteModal(true);
                              }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key="no-data">
                          
                        <td className="table-data flex justify-center">
                          <p className="text-center">No Data Available</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                  {deleteModal && (
                    <TypeConfirmModal
                      title="Delete Admin"
                      description={`Are you sure you want to delete ‘‘${selectedReport?.name}”?`}
                      onYes={deleteAdmin}
                      onClose={() => setDeleteModal(false)}
                    />
                  )}
                </table>
              </div>
            </div>
          )}
          <section className={`mt-1 px-3 rounded-lg bg-white`}>
            <Pagination
              data={admins?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
        </div>
      </div>

      <CreateReportModal
        openNewReportModal={openNewReportModal}
        setOpenNewReportModal={setOpenNewReportModal}
        selectedReport={selectedReport}
        setSelectedReport={setSelectedReport}
      />
    </div>
  );
};

export default ViewReport;
