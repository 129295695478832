import React, { useState } from 'react'

const TestImage = () => {
    const [imageUrl, setImageUrl] = useState('');
    const [base64Result, setBase64Result] = useState('');

    const convertToBase64 = async () => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error converting image:', error);
            return null;
        }
    };

    const handleConvert = async () => {
        const result = await convertToBase64();
        if (result) {
            setBase64Result(result as string);
        }
    };

    return (
        <div>
            <input 
                type="text" 
                value={imageUrl} 
                onChange={(e) => setImageUrl(e.target.value)}
                placeholder="Enter image URL"
                style={{ marginRight: '10px' }}
            />
            <button onClick={handleConvert}>Convert to base64</button>
            {base64Result && (
                <div style={{ marginTop: '20px' }}>
                    <img src={base64Result} alt="Converted" style={{ maxWidth: '200px' }} />
                    <pre style={{ maxWidth: '100%', overflow: 'auto' }}>
                        {base64Result}
                    </pre>
                </div>
            )}
            
        </div>
    )
}

export default TestImage