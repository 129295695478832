import React, { useState } from 'react';
import SidebarControls from './components/SidebarControls';
import CanvasEditor from './components/CanvasEditor';


const BrandEditor = () => {
  const [logo, setLogo] = useState(null);
  const [logoPosition, setLogoPosition] = useState("top-left");
  const [showLogo, setShowLogo] = useState(true);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [headerPosition, setHeaderPosition] = useState("top");

  const [footerVisible, setFooterVisible] = useState(true);
  const [footerPosition, setFooterPosition] = useState("bottom");

  const [showPageNumber, setShowPageNumber] = useState(true);
  const [pageNumberPosition, setPageNumberPosition] = useState("bottom-right");

  return (
    <div className="flex h-screen">
      <SidebarControls
        logo={logo}
        setLogo={setLogo}
        showLogo={showLogo}
        setShowLogo={setShowLogo}
        logoPosition={logoPosition}
        showPageNumber={showPageNumber}
        setShowPageNumber={setShowPageNumber}
        setLogoPosition={setLogoPosition}
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
        headerPosition={headerPosition}
        setHeaderPosition={setHeaderPosition}
        footerVisible={footerVisible}
        setFooterVisible={setFooterVisible}
        footerPosition={footerPosition}
        setFooterPosition={setFooterPosition}
        pageNumberPosition={pageNumberPosition}
        setPageNumberPosition={setPageNumberPosition}
      />
      <CanvasEditor
        logo={logo}
        setLogo={setLogo}
        showLogo={showLogo}
        showPageNumber={showPageNumber}
        logoPosition={logoPosition}
        headerVisible={headerVisible}
        headerPosition={headerPosition}
        footerVisible={footerVisible}
        footerPosition={footerPosition}
        pageNumberPosition={pageNumberPosition}
      />
    </div>
  );
};

export default BrandEditor;
