import React, { useState } from 'react'
import { api as courses } from '../../../../../../../api/courses'; 
import { useQuery } from 'react-query';
import { moduleType } from './CourseContent';
import CenteredModal from '../../../../../../../components/Modal/CenteredModal';
import Pagination from '../../../../../../../components/pagination/Pagination';
import Loader from '../../../../../../../components/loader/Loader';

type selectModulesType = {
    setSelectedModules: React.Dispatch<React.SetStateAction<moduleType[]>>
    selectedModule: moduleType[],
    openModal: boolean,
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SelectModules: React.FC<selectModulesType> = (prop): React.ReactElement => {

    const [perPage, setPerPage] = useState<number>(6);
    const [search, setSearch] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);

    const { data: modules, isLoading, } = useQuery(
        ["Modules", currentPage, search, perPage ],
        () => {
          return courses.getModules({ currentPage, search, perPage });
        },
        {
          cacheTime: 10000,
          staleTime: 10000,
          refetchOnWindowFocus: true,
        }
    );

    const setSelectedModules = (module:any) => {
            const {selectedModule: selectedModules} = prop
            if(selectedModules?.length){
                const moduleExist = selectedModules.findIndex( module_ => {
                   return module_?.id === module.id;
                })
                if(moduleExist === -1 ){
                   prop.setSelectedModules([...selectedModules, module]);
                }
                else prop.setSelectedModules( selectedModules.filter( module_ => module_?.id !== module?.id ) )
            }
            else prop.setSelectedModules([...selectedModules, module]);
    }

  return (
     <CenteredModal
        title={
            <h5 className='-ml-3 text-sm font-semibold'> 
             Select Modules ({prop.selectedModule.length})
        </h5>
        }
        bgColor={'bg-primary-gray'}
        open={prop.openModal}
        setOpen={prop.setOpenModal}
        style={{padding: 0}}
        width={'w-[600px] h-auto overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-2 sticky'}
        withCloseBtn
        closeButtonLabel={'Ok'}
        btnBgColor={'bg-secondary'}
        onClose={ () : void => {
          prop.setOpenModal(false)
        }}
     >
         <div className='text-left'> {console.log(prop.selectedModule)
         }
            <input 
                type="search" 
                className='input-style bg-primaryGray w-full'
                placeholder='&#128269; Search modules....'
                onChange={ (event: React.ChangeEvent<HTMLInputElement>) : void => {
                    setSearch(event.target.value)
                }}
            />
            <section className='grid mt-5 pt-3 grid-cols-3 gap-5'>
          {
            isLoading ?  
              <div className='flex w-[600px] justify-center items-center h-[200px] overflow-hidden'>
                   <Loader /> 
              </div>
            :
            modules?.data?.length ? 
             modules?.data?.map( (module: any) => {
                const moduleExist = prop.selectedModule.length ? prop?.selectedModule?.findIndex( (module_: any) => {
                    return module?.id === module_.id
                 }) : -1

                return(
                    <button key={module?.id} className={`shadow bg-white pb-4 py-1 px-3 ${ moduleExist === -1 ? '' : 'border-primary-blue'} border hover:border-primary-blue rounded-lg transform transition-transform duration-300 hover:scale-110`}>
                         <span className='flex justify-end w-full'>
                             <span 
                                 className={`h-[18px] w-[18px] border flex items-center justify-center ${ 
                                    moduleExist === -1 ? 'border-gray-800' : 'border-primary-blue'
                                  } p-1 rounded-full`}
                                 onClick={ () => setSelectedModules(module) }
                              >
                                <span className={`${moduleExist !== -1 ? 'bg-primary-blue' : ''} h-[10px] block w-[10px] rounded-full`} />
                             </span>
                         </span>
                        <span className='text-xs capitalize'>{module?.name}</span>
                    </button>
                )
             } )
            : null
          }
            </section>
            <Pagination
                data={modules?.meta}
                perPage={perPage}
                pageNo={currentPage}
                setPageNo={setCurrentPage}
                setPerPage={setPerPage}
          />
        </div>
     </CenteredModal>
  )
}

export default SelectModules