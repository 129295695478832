import React, { useState } from 'react'
import { useReportEditor } from '../../hooks/ReportEditorContext'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { errorResponse } from '../../../../../../components/utils/errorResponse'
import toast from 'react-hot-toast'
import ResourceLoader from '../../../../../../components/loader/ResourceLoader'
import { useMutation, useQuery } from 'react-query'
import { createResource } from '../../../../../../api/uploadResourse'
import { setFieldValueType } from '../../../accountSetup/components/ProfileInformation'
import { api } from '../../../../../../api/content'
import Loader from '../../../../../../components/loader/Loader'
import Search from '../../../../../../components/Inputs/Search'
import Pagination from '../../../../../../components/pagination/Pagination'

type initValueType = {
  name: string
  description?: string | null
  size: number,
  status: "published",
  filePath: string
  tagIds?: null | string[]
  type: 'image',
}

type PhotosType = {
  activePageId?: string | null
  elementSetter: any
  pageStructure: any
  setPageStructure: any
}

const Photos: React.FC<PhotosType>  = ({activePageId, elementSetter, pageStructure, setPageStructure}) => {

  //const { addElement } = useReportEditor();
  const orgId = JSON.parse(sessionStorage.getItem('user') || '{}')?.org?.id
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [search, setSearch] = useState<string>("")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(8)
  const [currentTab, setCurrentTab] = useState<'library' | 'upload'>("library")
  const {mutateAsync, isLoading} = useMutation(api.createContent)

  const { undo, redo, canUndo, canRedo,
        elements,
        setElements,
        // elementSetter,
         addElement,
        //pageStructure,
       //  setPageStructure,
        activeElementId, 
        setActiveElementId,
      } = useReportEditor();

  const initialValues: initValueType = {
    name: "",
    size: 12,
    status: "published",
    filePath: "",
    type: "image"
  }

  const { data: images, isLoading: imagesLoading, refetch } = useQuery(
    ['get images', search, perPage, currentPage ] , 
    async (): Promise<any> => {
     return await api.getAllContents({
      orgId, type: 'image', search, perPage, currentPage,
      sortBy: '', filterBy: ''
    })
  }, { select: response => response})

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name must be at least 2 characters'),
    filePath: Yup.string()
      .required('no photo selected')
  });

  const handleImageClick = (imageUrl: string) => {
   // addElement('image', imageUrl);
   elementSetter(
    activePageId,
    addElement(
      "image",
      activePageId,
      imageUrl,
      null,
      null,
      null,
    ),
    setPageStructure
  );
  };

  const uploadPhoto = async ( 
    event: React.ChangeEvent<HTMLInputElement>, setFieldValue: setFieldValueType
   ) : Promise<void> => {
         setIsUploading(true)
         try{
            const file: File =  event?.target?.files![0]; 
         if (file) {
            const formData: FormData = new FormData();
            formData.append("file", file);
            const response = await createResource.uploadResource(formData)
            toast.success(response?.data?.message)
            setFieldValue('filePath',response?.data?.data)
            setIsUploading(false)
         } 
         } catch(error){
            setIsUploading(false)
            errorResponse(error)
         }
 }

  const createPhoto = async (values: initValueType,  action: any) : Promise<void> => {
    action.setSubmitting(false);
    try{
      toast.loading('Loading...')
      let payload = {...values ,title: values.name}
       let response: any = await mutateAsync({orgId, payload})
      toast.remove()
      toast.success(response?.message)
      setCurrentTab('library')
      refetch()
    }
    catch(error: any){
       toast.remove()
       toast.error(error?.response?.data?.message)
    }
  }

  const handleDragStart = (e: React.DragEvent, imageUrl: string) => {
    const data = {
      type: 'image',
      imageUrl:  imageUrl
    };
    e.dataTransfer.setData('text/plain', JSON.stringify(data));
  };

  return (
    <div>  
      { isUploading && <ResourceLoader /> }
       <div className='grid grid-cols-2 gap-2 mt-3'>
         <button className={  currentTab === "library" ? 'btn btn-primary' : 'btn-light'} 
           onClick={() => setCurrentTab("library")}
           >Photos</button>
         <button
           className={ currentTab === "upload" ? 'btn btn-primary' : 'btn-light'} 
           onClick={() => setCurrentTab("upload")}
          >Uploads</button>
       </div>
       {
         currentTab === "library" ? (
           <div className='mt-5'>
             <Search
                onChange={setSearch}
                placeholder={"Search library..."}
                className={"w-full mb-3"}
                bgColor={null}
                iconColor={null}
                onClick={null}
                width={'w-full'}
                value={null}
                mb={''}
                showButton={false}
              />
             <section className='grid mt-3 justify-center grid-cols-2 gap-5'>
               {  
                imagesLoading ? <div className='w-full h-[120px] bg-white rounded-md flex items-center justify-center'>
                  <Loader />
                </div> :
                images?.data?.map((photo: any) => {
                  return (
                    <div 
                      key={photo?.id}
                      className='w-[120px] hover:cursor-pointer hover:scale-105 transition-all duration-300 border border-slate-200 h-[120px] bg-white rounded-md'
                      draggable
                      onDragStart={async (e) => {
                      //  const base64Image = await toBase64(photo?.filePath);
                        handleDragStart(e, photo?.filePath);
                      }}
                    >
                      <img 
                        src={photo?.filePath}
                        alt={photo?.filePath}
                       // crossOrigin="anonymous"
                        className='w-[150px] h-[100px] object-contain'
                        onClick={() => handleImageClick(photo?.filePath)}
                      />
                      <p className='text-xs text-center text-slate-950'>{photo?.title}</p>
                    </div>
                  );
                })
               }
             </section>
               <Pagination
                    data={images?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                  />
           </div>
         ) : (
           <div className='mt-3'>
             <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={createPhoto}
            >
            {({ values, setFieldError, setFieldValue, setFieldTouched }) => (
              <Form> 
              <div className='pt-3'>
                  <div className='mb-3'>
                     <Field 
                       name="name" className='input-style bg-primaryGray'
                       placeholder='Photo Name'
                     />
                    <p className='text-red-500 text-xs mt-1'>
                      <ErrorMessage name="name" component="span" />
                    </p>
                  </div>
                  {/*<div className='mb-3'>
                     <Field 
                       name="description" className='input-style bg-primaryGray'
                       placeholder='Description'
                     />
                    <p className='text-red-500 text-xs mt-1'>
                      <ErrorMessage name="description" component="span" />
                    </p>
                  </div>*/}
                  <input
                     name="filePath" accept="image/*" type="file" 
                     id='file-input' className='hidden'
                     onChange={(event: React.ChangeEvent<HTMLInputElement>) => uploadPhoto(event, setFieldValue)}
                  />
                    {
                      values.filePath ? 
                        <div className='w-[150px] mx-auto h-[120px] bg-slate-300 rounded-md relative group'>
                          <img 
                             src={values.filePath} alt={values.name}
                             className='w-full h-full object-cover'
                          />
                          <div className='absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center'>
                            <span 
                              className="bi bi-trash cursor-pointer btn bg-red-500 pt-2 pb-2 px-3 text-xs"
                              onClick={() => setFieldValue('filePath', '')}
                            >
                              &nbsp; Delete
                            </span>
                          </div>
                        </div>
                      : 
                      <label 
                       htmlFor="file-input" 
                        className='flex h-[200px] border-2 border-dotted border-slate-200 items-center flex-col justify-center'
                      >
                        <i className="bi bi-cloud-arrow-up text-[30px]"></i>
                        <p className='text-sm mb-3 text-primary'>
                          Click to browse files
                        </p>
                        <span className='btn bg-primary-blue'>
                          Browse Files
                        </span>
                        </label>
                    }
                    <p className='text-red-500 text-xs mt-1'>
                      <ErrorMessage name="filePath" component="span" />
                    </p>
                   <button 
                    className='btn w-[90%] mx-auto text-center block btn-primary mt-5'
                    style={{opacity: (isLoading || isUploading) ? 0.5 : 1}}
                    disabled={isLoading || isUploading}
                    >
                      Create Photo
                  </button>
              </div>
            </Form>
          )}
        </Formik>
           </div>
         )
       }
    </div>
  )
}

export default Photos