import React, { useMemo, useState } from 'react'
import CenteredModal from '../../../../../../../components/Modal/CenteredModal'
import { set } from 'date-fns'
import { useQuery } from 'react-query'
import { api } from '../../../../../../../api/courses'
import Table from '../../../../../../../components/table/Table'
import ReactStarsRating from 'react-awesome-stars-rating';
import EmptyPlaceHolder from '../../../../../../../components/EmptyPlaceHolder'
import Pagination from '../../../../../../../components/pagination/Pagination'
type RatingsTableType = {
   open: boolean,
   setOpen: React.Dispatch<React.SetStateAction<boolean>>
   activeCourse: any
}
const RatingsTable: React.FC<RatingsTableType> = ({
  open,
  setOpen,
  activeCourse
}) => {

     const [perPage, setPerPage] = useState<number>(5);
     const [search, setSearch] = useState<string>("");
     const [currentPage, setCurrentPage] = useState<number>(1);

    const { data: courseRatings, isLoading, refetch } = useQuery(
        ["get courses", search, perPage, currentPage, activeCourse?.id],
        () => {
        return api.getCourseRatings({ search, perPage, currentPage, courseId: activeCourse?.id });
        },
    {
      cacheTime:  300000, // 5 minutes
      staleTime: 300000,
      refetchOnWindowFocus: true,
      select: (data) => data,
    }
  );

  let columns = useMemo(
          () => [
            {
              title: "ID",
              selector: null,
              key: "id",
              render: ({ index } : {index: number}) =>
                index +
                1 +
                ((courseRatings?.meta?.page || 1) - 1) *
                  (courseRatings?.meta?.perPage || perPage),
            },
            {
              title: "Learner Name",
              selector: null,
              key: null,
              render: ({ row } : {row: any}) => {
                return row?.user?.name
              },
            },
            {
              title: "Email",
              selector: null,
              key: null,
              render: ({ row } : {row: any}) => {
                return row?.user?.email
              },
            },
            {
              title: "Comment",
              selector: null,
              key: null,
              render: ({ row } : {row: any}) => {
                return row?.comments || <EmptyPlaceHolder count={3} />
              },
            }, 
            {
              title: "Rating (5 star)",
              selector: null,
              key: null,
              render: ({ row } : {row: any}) => {
                return  <span className='pointer-events-none'>
                <ReactStarsRating 
                  value={row?.rating} 
                  starGap={5}
                />
              </span>
              },
            },
            {
              title: "Date Commented",
              selector: "createdAt",
              key: "createdAt",
              render: ({ item }: { item : string }) => {
                return (
                  <span className="">{`${new Date(item).toLocaleDateString(
                    "en-ng"
                  )}`}</span>
                );
              },
            },
          ], [isLoading, perPage, currentPage ])

  const tableProps = { isLoading, columns, data: courseRatings?.data };

  return (
    <div> 
     <CenteredModal
          title={
            <h5 className='text-md capitalize bi bi-eye'> 
                &nbsp; View {activeCourse?.name} 
                Ratings ({courseRatings?.meta?.total || 0})
           </h5>
          }
        bgColor={undefined}
        open={open}
        setOpen={setOpen}
        style={{padding: 0}}
        width={'w-[800px] h-auto overflow-y-auto'}
        headerClassName={'flex justify-between px-3 pt-2 sticky'}
        withCloseBtn
        closeButtonLabel={'Close'}
        btnBgColor={'bg-secondary'}
        onClose={ () : void => {
           setOpen(false)
        }}
      >  
          <section className='text-left px-3 pb-2 bg-white'>
             <Table {...tableProps} />
             <Pagination
                    data={courseRatings?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                  />
          </section>
      </CenteredModal>
    </div>
  )
}

export default RatingsTable