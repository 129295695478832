import React, { ChangeEvent, MouseEventHandler } from 'react';
import UserSelect from '../../createCohort/components/UserSelect';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx/xlsx.mjs';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { errorResponse } from '../../../../../../components/utils/errorResponse';
import EmptyData from '../../../../../../components/EmptyData';
import addGroupImage from '../../../../../../assets/Add Group.png';
import { api as learningPath } from '../../../../../../api/learningPath';
import { cohorts } from '../../../../../../api/cohort';

interface Learner {
  name: string;
  email: string;
  employeeId: string;
  status: boolean;
}

const AddLearnersToCohort: React.FC<any> = ({
  setCurrentStep,
  cohortId,
  learners,
  setLearners,
  defaultLearners,
  setDefaultLearners,
  checked,
  setChecked,
  duplicateLearners,
  setDuplicateLearners,
  invalidLearners,
  setInvalidLearners,
  cohort
}) => {

  const orgId = JSON.parse(sessionStorage.getItem('user') ?? '{}')?.org?.id;

  const addParticipantsMutation: UseMutationResult = useMutation(cohorts.addLearnersToCohort, {
    onSuccess: () => {
      toast.remove();
      toast.success('Learners added to cohort successfully.');
      setCurrentStep(4);
    },
    onError: (error) => errorResponse(error),
    onMutate: () => {
      toast.loading('Loading...');
    },
  });

  const { data: users, isLoading, refetch } = useQuery(
    ["cohort users"],
    () => {
      return learningPath.getCohortUsers(
        { id: orgId, cohortId: cohort?.id, search: '', perPage: 10000, currentPage: 1 }
      ) 
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
      onSuccess: response => {
        if(response?.length){
           setLearners(response?.map( (learner: any) : Learner => {
             return {
                name: learner?.participant?.user?.name,
                email: learner?.participant?.user?.email,
                employeeId: learner?.participant?.user?.id,
                status: true
             }
           } ))
        }
      }
    }
  );

  const uploadLearners = (event: ChangeEvent<HTMLInputElement>) => {
    setInvalidLearners([]);
    setDuplicateLearners([]);

    const excelSheet = event.target.files?.[0];
    if (excelSheet?.name?.endsWith('xlsx') || excelSheet?.name?.endsWith('csv')) {
      const promise = new Promise<Learner[]>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(excelSheet);
        fileReader.onload = (e: ProgressEvent<FileReader>) => {
          const bufferArray = e.target?.result as ArrayBuffer;
          const workBook = XLSX.read(bufferArray, { type: 'buffer' });
          const workSheetName = workBook.SheetNames[0];
          const workSheet = workBook.Sheets[workSheetName];
          const dataArray: any[] = XLSX.utils.sheet_to_json(workSheet);
          resolve(dataArray);
        };
        fileReader.onerror = (error: any) => reject(error);
      });

      promise.then((data) => {
        const checkKey = (key: string) => data[0]?.hasOwnProperty(key);
        if (!checkKey('Name') || !checkKey('Email') || !checkKey('EmployeeId')) {
          toast.error('Invalid excel template');
          return;
        }

        const learners = data.map((learner: any) => ({
          name: learner.Name,
          email: learner.Email,
          employeeId: learner.EmployeeId,
          status: true,
        }));

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const uniqueEmails = new Set<string>();
        const validLearners: Learner[] = [];
        const invalidLearnersList: Learner[] = [];
        const duplicateLearnersList: Learner[] = [];

        learners.forEach((learner) => {
          if (!emailRegex.test(learner.email)) {
            invalidLearnersList.push(learner);
          } /*else if (uniqueEmails.has(learner.email)) {
            duplicateLearnersList.push(learner);
          }*/ else {
            uniqueEmails.add(learner.email);
            validLearners.push(learner);
          }
        }); 

        //console.log({validLearners});
        
        setLearners(validLearners);
        setDefaultLearners(validLearners);
      /*  if (invalidLearnersList.length) {
          setInvalidLearners(invalidLearnersList);
          event.target.value = '';
        } else if (duplicateLearnersList.length) {
          setDuplicateLearners(duplicateLearnersList);
          event.target.value = '';
        } else {
          setLearners(validLearners);
          setDefaultLearners(validLearners);
          event.target.value = '';
        } */
      });
    } else {
      toast.error('Error! Please upload a valid xlsx file.');
    }
  }

  const updateLearners = (learner: Learner) => {
    const updatedLearners = (list: Learner[]) =>
      list.map((item) =>
        item.email === learner.email ? { ...item, status: !item.status } : item
      );

    setLearners(updatedLearners(learners));
    setDefaultLearners(updatedLearners(defaultLearners));
  };

  const selectAll: MouseEventHandler<HTMLButtonElement> = () => {
    const allSelected = learners.every((learner: any) => learner.status);
    setChecked(!allSelected);
    setLearners(
      learners.map((learner: any) => ({ ...learner, status: !allSelected }))
    );
  };

  const searchLearner = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    const filteredLearners = defaultLearners.filter((learner: any) =>
      learner.email.toLowerCase().includes(query)
    );
    setLearners(query ? filteredLearners : defaultLearners);
  };

  const addLearnersToCohort = () => {
    const participants = learners.filter((learner: any) => learner.status);
    if (participants.length) {
      addParticipantsMutation.mutate({
        data: { participants },
        cohortId: cohort?.id,
        id: orgId,
      });
    } else {
      toast.error('Select learners to proceed.');
    }
  };

  return (
    <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
               <section className='w-[54%] rounded-e-lg bg-white p-5 px-8'>
              <div>
              <header className="flex justify-between items-center">
                    <h5 className='text-md font-semibold'>
                      Add Learners To Cohort
                    </h5>
                  <a
                    href="/assets/sample learners (1).xlsx"
                    className="bi bi-download py-3 text-center font-bold  w-[200px] rounded-lg px-4 bg-secondary text-xs text-white"
                    download="sample learners"
                  >
                    &nbsp; Download Sample
                  </a>
              </header>
              <div className="border-t border-gray-300 mt-3 pt-3">
                <section className="flex justify-between mt-3">
                  <button onClick={selectAll} className={`bi bi-check-square text-lg ${checked ? 'text-primary' : 'text-gray-900'}`}>
                    <span className="text-gray-900 text-xs ml-1">Select All</span>
                  </button>
                  <input 
                      type='search' 
                      onChange={searchLearner}
                      className='input-style ml-3 bg-primaryGray w-[300px]'
                      placeholder='&#128269; search learners...'
                  />
                  <label htmlFor="upload-participants" className="bi px-3 flex items-center bg-primaryGray bi-cloud-upload ml-3 text-xs border rounded-lg">
                    <input 
                      type="file" id="upload-participants" 
                      onChange={uploadLearners} className="hidden" 
                    />
                    &nbsp;
                  </label>
                </section>
                <div className="mt-2 w-[90%] h-[300px] pr-5 overflow-y-auto">
                  {learners.length ? learners.map((learner: any) => (
                     <div className='mt-3'>
                         <UserSelect
                          key={learner.email}
                          name={learner.email}
                          selected={learner.status}
                          onSelect={() => updateLearners(learner)}
                        />
                     </div>
                  )) : <EmptyData text="Upload Learners and assign them to a group" />}
                </div>
              </div>
              <div className="flex mt-5 justify-end">
                <button className="w-[200px] text-center btn bg-primary-blue"  onClick={addLearnersToCohort} >
                  Add Learners To Cohort
                </button>
              </div>
            </div>
             </section>
    </section>
  );
};

export default AddLearnersToCohort;
