// import React, { createContext, useContext, useState } from 'react';

// const BrandContext = createContext();

// export const useBrandContext = () => {
//   return useContext(BrandContext);
// };

// export const BrandProvider = ({ children }) => {

//     const [headerText, setHeaderText] = useState("Header");
//     const [footerText, setFooterText] = useState("Footer");

//   const [logo, setLogo] = useState(null);
//   const [logoPosition, setLogoPosition] = useState("top-left");
//   const [showLogo, setShowLogo] = useState(true);

//   const [headerVisible, setHeaderVisible] = useState(true);
//   const [headerPosition, setHeaderPosition] = useState("top");

//   const [isLogoOpen, setLogoIsOpen] = useState(false);
//   const [isHeaderOpen, setHeaderIsOpen] = useState(false);
//   const [isFooterOpen, setFooterIsOpen] = useState(false);
//   const [isPageOpen, setPageIsOpen] = useState(false);

//   const [footerVisible, setFooterVisible] = useState(true);
//   const [footerPosition, setFooterPosition] = useState("bottom");

//   const [showPageNumber, setShowPageNumber] = useState(true);
//   const [pageNumberPosition, setPageNumberPosition] = useState("bottom-right");

//   const positionClassMap = {
//     "top-left": "top-0 left-0",
//     "top": "top-0 left-1/2 transform -translate-x-1/2",
//     "top-right": "top-0 right-0",
//     "center": "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
//     "bottom-left": "bottom-0 left-0",
//     "bottom": "bottom-0 left-1/2 transform -translate-x-1/2",
//     "bottom-right": "bottom-0 right-0",
//   };

//   const positions = [
//     "top-left",
//     "top",
//     "top-right",
//     "center",
//     "bottom-left",
//     "bottom",
//     "bottom-right",
//   ];

//   const handleLogoChange = (e) => {
//     const file = e.target.files[0];
//     if (file) setLogo(URL.createObjectURL(file));
//   };

//   const toggleDropdown = () => {
//     setLogoIsOpen(!isLogoOpen);
//   };

//   const toggleHeaderDropdown = () => {
//     setHeaderIsOpen(!isHeaderOpen);
//   };

//   const toggleFooterDropdown = () => {
//     setFooterIsOpen(!isFooterOpen);
//   };

//   const togglePageDropdown = () => {
//     setPageIsOpen(!isPageOpen);
//   };

//   return (
//     <BrandContext.Provider
//       value={{
//         logo,
//         setLogo,
//         logoPosition,
//         setLogoPosition,
//         showLogo,
//         setShowLogo,
//         headerVisible,
//         setHeaderVisible,
//         headerPosition,
//         setHeaderPosition,
//         footerVisible,
//         setFooterVisible,
//         footerPosition,
//         setFooterPosition,
//         showPageNumber,
//         setShowPageNumber,
//         pageNumberPosition,
//         setPageNumberPosition,
//         positionClassMap,
//         positions,
//         handleLogoChange,
//         isLogoOpen,
//         setLogoIsOpen,
//         isHeaderOpen,
//         setHeaderIsOpen,
//         isFooterOpen,
//         setFooterIsOpen,
//         isPageOpen,
//         setPageIsOpen,
//         toggleDropdown,
//         toggleHeaderDropdown,
//         toggleFooterDropdown,
//         togglePageDropdown,
//         headerText,
//         setHeaderText,
//         footerText,
//         setFooterText,
//       }}
//     >
//       {children}
//     </BrandContext.Provider>
//   );
// };

import React, { createContext, useContext, useState, useEffect } from "react";

const BrandContext = createContext();

export const useBrandContext = () => {
  return useContext(BrandContext);
};

export const BrandProvider = ({ children }) => {
  // Separate state for logo
  const [logo, setLogo] = useState(() => {
    try {
      return sessionStorage.getItem("brandLogo");
    } catch (error) {
      console.warn("Error accessing logo from sessionStorage:", error);
      return null;
    }
  });

  // Initialize brandData from sessionStorage or use default values
  // Rest of brand data
  const [brandData, setBrandData] = useState(() => {
    const savedData = sessionStorage.getItem("brandData");
    return savedData
      ? JSON.parse(savedData)
      : {
          headerText: "Header",
          footerText: "Footer",
          logoPosition: "top-left",
          showLogo: true,
          headerVisible: true,
          headerPosition: "top",
          footerVisible: true,
          footerPosition: "bottom",
          showPageNumber: true,
          pageNumberPosition: "bottom-right",
          isLogoOpen: false,
          isHeaderOpen: false,
          isFooterOpen: false,
          isPageOpen: false,
        };
  });
  //   const [brandData, setBrandData] = useState(() => {
  //     const savedData = sessionStorage.getItem('brandData');
  //     return savedData ? JSON.parse(savedData) : {
  //       headerText: "Header",
  //       footerText: "Footer",
  //       logo: null,
  //       logoPosition: "top-left",
  //       showLogo: true,
  //       headerVisible: true,
  //       headerPosition: "top",
  //       footerVisible: true,
  //       footerPosition: "bottom",
  //       showPageNumber: true,
  //       pageNumberPosition: "bottom-right",
  //       isLogoOpen: false,
  //       isHeaderOpen: false,
  //       isFooterOpen: false,
  //       isPageOpen: false,
  //     };
  //   });

  // Save to sessionStorage whenever brandData changes
  //   useEffect(() => {
  //     sessionStorage.setItem('brandData', JSON.stringify(brandData));
  //   }, [brandData]);

  // Save brand data to sessionStorage
  useEffect(() => {
    try {
      sessionStorage.setItem("brandData", JSON.stringify(brandData));
    } catch (error) {
      console.warn("Error saving brand data to sessionStorage:", error);
    }
  }, [brandData]);

  // Helper functions to update specific fields
  const updateBrandData = (field, value) => {
    setBrandData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  //   const handleLogoChange = (e) => {
  //     const file = e.target.files[0];
  //     if (file) {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         const base64String = reader.result;
  //         updateBrandData('logo', base64String);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Add file size check
      if (file.size > 2 * 1024 * 1024) {
        // 2MB limit
        alert("Logo file is too large. Please choose an image under 2MB.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        try {
          const base64String = reader.result;
          setLogo(base64String);
        } catch (error) {
          console.error("Error processing logo:", error);
          alert("Error processing logo. Please try a different image.");
        }
      };
      reader.onerror = () => {
        console.error("Error reading file");
        alert("Error reading file. Please try again.");
      };
      reader.readAsDataURL(file);
    }
  };

  // Save logo separately
  useEffect(() => {
    try {
      if (logo) {
        sessionStorage.setItem("brandLogo", logo);
      }
    } catch (error) {
      console.warn("Error saving logo to sessionStorage:", error);
    }
  }, [logo]);

  const toggleDropdown = (field) => {
    updateBrandData(field, !brandData[field]);
  };

  const positionClassMap = {
    "top-left": "top-0 left-0",
    top: "top-0 left-1/2 transform -translate-x-1/2",
    "top-right": "top-0 right-0",
    center: "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
    "bottom-left": "bottom-0 left-0",
    bottom: "bottom-0 left-1/2 transform -translate-x-1/2",
    "bottom-right": "bottom-0 right-0",
  };

  const positions = [
    "top-left",
    "top",
    "top-right",
    "center",
    "bottom-left",
    "bottom",
    "bottom-right",
  ];
  const resetBrandData = () => {
    const defaultData = {
      headerText: "Header",
      footerText: "Footer",
      logo: null,
      // ... other default values
    };
    setBrandData(defaultData);
  };

  const clearBrandData = () => {
    sessionStorage.removeItem("brandData");
    resetBrandData();
  };
  return (
    <BrandContext.Provider
      value={{
        // Spread all brandData properties
        ...brandData,

        // Setters for each property
        logo,
        setHeaderText: (value) => updateBrandData("headerText", value),
        setFooterText: (value) => updateBrandData("footerText", value),
        setLogo: (value) => updateBrandData("logo", value),
        setLogoPosition: (value) => updateBrandData("logoPosition", value),
        setShowLogo: (value) => updateBrandData("showLogo", value),
        setHeaderVisible: (value) => updateBrandData("headerVisible", value),
        setHeaderPosition: (value) => updateBrandData("headerPosition", value),
        setFooterVisible: (value) => updateBrandData("footerVisible", value),
        setFooterPosition: (value) => updateBrandData("footerPosition", value),
        setShowPageNumber: (value) => updateBrandData("showPageNumber", value),
        setPageNumberPosition: (value) =>
          updateBrandData("pageNumberPosition", value),

        // Toggle functions
        toggleDropdown: () => toggleDropdown("isLogoOpen"),
        toggleHeaderDropdown: () => toggleDropdown("isHeaderOpen"),
        toggleFooterDropdown: () => toggleDropdown("isFooterOpen"),
        togglePageDropdown: () => toggleDropdown("isPageOpen"),

        // Other functions and constants
        handleLogoChange,
        positionClassMap,
        positions,
        resetBrandData,
        clearBrandData,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};
