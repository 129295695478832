import React, { useState, useRef } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { useReportEditor } from "../../hooks/ReportEditorContext";
import { IconContext } from "react-icons";
import { SketchPicker } from "react-color";
import iconList from "../../../../../../data/icons";
import { DndContext, useDraggable, useDroppable } from "@dnd-kit/core";
import Shapes from "./Shapes";

const ItemType = {
  ICON: "icon",
  UPLOADED_ICON: "uploaded_icon",
};

const DraggableUploadedIcon = ({ iconUrl }) => {
  const handleDragStart = (e) => {
    const data = {
      type: "image",
      imageUrl: iconUrl,
    };
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };

  return (
    <div
      draggable
      onDragStart={(e) => handleDragStart(e, iconUrl)}
      className="p-2 border rounded cursor-pointer hover:bg-gray-200"
    >
      <img
        src={iconUrl}
        alt="Uploaded Icon"
        className="w-8 h-8 object-contain"
      />
    </div>
  );
};

const DraggableIcon = ({ icon: IconComponent, onClick }) => {
 

  const handleDragStart = (e, IconComponent) => {
    const iconName = IconComponent.displayName || IconComponent.name; 
    // console.log(iconName, "name")
    const data = {
      type: "icon",
      iconName: iconName, // Pass the icon name (or any unique identifier)
    };
  
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };

  return (
    <div
      draggable
      onDragStart={(e) => handleDragStart(e, IconComponent)}
      className="p-2 border w-fit rounded cursor-pointer hover:bg-gray-200"
      onClick={onClick}
    >
      <IconContext.Provider value={{ size: "24px" }}>
        {/* Dynamically render the icon component */}
        {IconComponent && <IconComponent />}
      </IconContext.Provider>
    </div>
  );
};

const Graphics = ({
  activePageId,
  elementSetter,
  pageStructure,
  setPageStructure,
}) => {
  const {
    elements,
    setElements,
    addElement,
    activeElementIndex,
    setActiveElementIndex,
  } = useReportEditor();
  const [activeTab, setActiveTab] = useState("Icons");
  const [uploadedIcons, setUploadedIcons] = useState([]);
  const cardRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleIcons, setVisibleIcons] = useState(iconList.slice(0, 50));

  const [showIconList, setShowIconList] = useState(true);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter icons based on the search query
    const filteredIcons = iconList.filter((icon) =>
      icon.name.toLowerCase().includes(query.toLowerCase())
    );
    setVisibleIcons(filteredIcons);
  };

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedIcons((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index].x = x;
    updatedElements[index].y = y;
    setElements(updatedElements);
  };

  const updateElementProperty = (index, key, value) => {
    if (index === null || !elements[index]) return;
    const updatedElements = [...elements];
    updatedElements[index][key] = value;
    setElements(updatedElements);
  };

  const handleDeselect = () => {
    setActiveElementIndex(null); // Deselect the active element
    setShowIconList(true); // Show the icon list again
  };

  const handleIconClick = (iconUrl) => {
    elementSetter(
      activePageId,
      addElement("icon", activePageId, iconUrl, null, null, null),
      setPageStructure
    );
  };

  return (
    <div className="">
      <h3 className="mb-4 text-lg font-bold">Icons</h3>
      <div className="flex w-full text-sm font-medium mb-4">
        <button
          onClick={() => setActiveTab("Icons")}
          className={`px-10 py-2 rounded-t  ${
            activeTab === "Icons"
              ? "bg-customBlue text-white"
              : "bg-white shadow  text-[#8E8E8E]"
          }`}
        >
          Icons
        </button>
        {/* <button
          onClick={() => setActiveTab("Uploaded Icons")}
          className={`px-4 py-2 rounded-t  ${
            activeTab === "Uploaded Icons"
              ? "bg-customBlue text-white"
              : "bg-white shadow  text-[#8E8E8E]"
          }`}
        >
          Uploaded Icons
        </button> */}
        <button
          onClick={() => setActiveTab("Shapes")}
          className={`px-4 py-2 rounded-t  ${
            activeTab === "Shapes"
              ? "bg-customBlue text-white"
              : "bg-white shadow  text-[#8E8E8E]"
          }`}
        >
          Shapes
        </button>
      </div>
      <div>
        {activeTab === "Icons" && (
          <div>
            <input
              type="text"
              placeholder="Search icons..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full p-2 mb-4 border rounded"
            />
            {showIconList && (
              <div className="grid grid-cols-6 gap-4">
                {visibleIcons?.map((icon, index) => (
                  <DraggableIcon
                    key={index}
                    icon={icon.component}
                    onClick={() => handleIconClick(icon)}
                    // onClick={() => setSelectedIcon(icon.component)} // Set selected icon
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {/* {activeTab === "Uploaded Icons" && (
          <div>
            <div class="items-center justify-center max-w-xl mx-auto">
              <label
                class="flex justify-center w-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                id="drop"
                for="file-input"
              >
                <span class="flex flex-col items-center space-x-2 pt-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-6 h-6 text-gray-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <span class="font-medium text-[#FF9100] text-xs pt-5">
                    Drag & drop new images here
                  </span>
                  <label
                    for="file-input"
                    className="my-5 cursor-pointer text-white bg-customBlue hover:bg-darkBlue font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                  >
                    Browse Files
                  </label>
                </span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleUpload}
                  id="file-input"
                  className="mb-4 hidden"
                />
              </label>
            </div>

            <div className="grid grid-cols-3 gap-4">
              {uploadedIcons?.map((iconUrl, index) => (
                <DraggableUploadedIcon key={index} iconUrl={iconUrl} />
              ))}
            </div>
          </div>
        )} */}
        {activeTab === "Shapes" && ( <Shapes  activePageId={activePageId}
                      elementSetter={elementSetter}
                      pageStructure={pageStructure}
                      setPageStructure={setPageStructure} />)}
      </div>

      {activeElementIndex !== null && elements[activeElementIndex] && (
        <div className="mt-4 bg-white p-4 shadow-lg rounded-lg">
          <div className="mb-2">
            <label>Font Size:</label>
            <input
              type="number"
              value={elements[activeElementIndex]?.fontSize || 24}
              onChange={(e) =>
                updateElementProperty(
                  activeElementIndex,
                  "fontSize",
                  parseInt(e.target.value, 10)
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mt-4">
            <label className="block text-base font-medium text-gray-700 mb-2">
              Icon Opacity
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={elements[activeElementIndex]?.opacity}
              onChange={(e) =>
                updateElementProperty(
                  activeElementIndex,
                  "opacity",
                  parseFloat(e.target.value)
                )
              }
              className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
            />
          </div>
          <div className="mb-2">
            <label>Color:</label>
            <SketchPicker
              color={elements[activeElementIndex]?.color || "#000"}
              onChangeComplete={(color) =>
                updateElementProperty(activeElementIndex, "color", color.hex)
              }
            />
          </div>
          <button
            onClick={handleDeselect} // Deselect icon
            className="mt-4 p-2 text-red-500"
          >
            Deselect Icon
          </button>
          <div className="flex justify-between"></div>
        </div>
      )}
    </div>
  );
};

export default Graphics;
