import React, { useState } from "react";
import EditMeeting from "./EditMeeting";
import EditZoomSettings from "./EditZoomSettings";

import addGroupImage from '../../../../../assets/Add Group.png'

const EditMultiStepForm = () => {
  const [step, setStep] = useState(1); // Manage current step
  const [formData, setFormData] = useState({}); // Store form data across steps

  const nextStep = (data) => {
    setFormData({ ...formData, ...data }); // Merge new data with existing form data
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  // Render current step component
  const renderStep = () => {
    switch (step) {
      case 1:
        return <EditMeeting nextStep={nextStep} />;
      case 2:
        return <EditZoomSettings nextStep={nextStep} prevStep={prevStep} formData={formData} />;
      default:
        return null;
    }
  };

  return (
    <div className="border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 my-8">
      {/* Left side: Static image */}
      <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
      {/* Right side: Steps */}
      <div className="w-2/3 p-6 flex items-center justify-center bg-white">
        {renderStep()}
      </div>
    </div>
  );
};

export default EditMultiStepForm;
