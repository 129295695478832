import React, { useState } from 'react'
const CenteredModal: any = React.lazy(() => import('../../../../../../components/Modal/CenteredModal'))

type ShareReportModalType = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ShareReportModal: React.FC<ShareReportModalType> = (props) : JSX.Element => {
   
    const { open, setOpen } = props

  return (
    <div>
      <CenteredModal
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        title="Share Report"
        width={'w-[500px]'}
      >
         <section className='text-left p-3'>
            <p className='text-sm mb-2 opacity-60'>
              Invite your team to review report
            </p>
            <div className='flex items-center'>
              <input
                type="email" placeholder='Enter email address'
              className='w-full border border-gray-300 rounded-md p-2 text-sm'
                  
              />
              <button className='btn ml-3 bg-primary pl-8 pr-8'>
                  Sent
              </button>
            </div>
            <div className='flex justify-end mt-8'>
                <button className='btn-light bi bi-download mr-5 bg-slate-200 pl-8 pr-8'>
                     &nbsp; Download Report
                </button>
                <button  onClick={() => setOpen(false)} className='btn bg-primary-blue pl-8 pr-8'>
                    Done
                </button>
            </div>
         </section>
      </CenteredModal>
    </div>
  )
}

export default ShareReportModal