import React, { useState } from 'react'
import CompanyRoleButton from '../../../../../../../Auth/components/CompanyRoleButton'
import Counter from './Counter'
import AddLearners from './AddLearners'
import { useMutation } from 'react-query'
import { api } from '../../../../../../../../api/teams'
import toast from 'react-hot-toast'
import Button from '../../../../../../../../components/Buttons/Button'
import { useNavigate } from 'react-router-dom'

const TeamSetting = ({ selectedTeams, selectedCohort }) => {

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
 // 1 for automatic | 2  for manual selection
 const [ mapLearnerMethod, setMapLearnerMethod ] = useState(1)
 const [value, setValue] = useState(2)
 const [ openModal, setOpenModal ] = useState(false)
 const navigate = useNavigate()

 const addLearnersToTeamsMutation = useMutation(api.assignOrganisationTeamsToCohort, {
  onSuccess: () => {
      toast.remove()
      toast.success('Teams Settings Applied successfully!')
      navigate(-1)
  },
  onError: error => {
      toast.remove()
      if(error.response.status === 500) toast.error(error.response.statusText)
      else if(error.response.status === 400) toast.error(error?.response?.data?.message)
      else{
         if(error?.response?.data?.errors?.length > 1){
          error?.response?.data?.errors?.map( error => {
            toast?.error(error?.message)
          })
         }
         else toast.error(error?.response?.data?.errors[0]?.message)
      }
  },
  onMutate: () => {
      toast.loading('Loading...')
  }
})

 const increment = () => {
    setValue(value + 1 )
 }

 const decrement = () =>  {
    if(value > 1 ) {
        setValue(value - 1 )
    }
 }

 const addLearnersToTeams = () => {
    const payload = {
      orgId, payload: {
        teams: selectedTeams?.map( team => {
        return {
            id: team?.id,
            assignType: mapLearnerMethod === 1 ? "auto" : "manual",
            cohortId: selectedCohort?.value,
            numberOfParticipants: mapLearnerMethod === 1 ? value : null
        }
      })
    }
    }
    addLearnersToTeamsMutation.mutate(payload)
 }

  return (
    <div className='h-[100vh]'>
        <AddLearners openModal={openModal} setOpenModal={setOpenModal} />
        <h2 className='mt-5 mb-3'>Step 2:</h2>
        <section className='p-5 py-8 mt-5 rounded-lg bg-white min-[500px]:w-[70%]'>
             <h2>
                 Add Learners To Teams &nbsp;&nbsp;&nbsp;&nbsp;
             </h2>
             <p className='text-xs mb-3'>Assign learners to team automatically in batches or manually</p>
             <h3 className='flex text-sm items-center'>
                <CompanyRoleButton 
                   text={'Automatic'} 
                   active={ mapLearnerMethod === 1}
                   onClick={ () => setMapLearnerMethod(1) }
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                    text={'Manual'}
                    active={mapLearnerMethod === 2}
                    onClick={ () => {
                        setMapLearnerMethod(2)
                        //setOpenModal(true)
                    } }
                />
                {
                  mapLearnerMethod === 1 ? 
                  <>
                     <span className='text-xs ml-5 mr-3'>Batch Of</span>
                     <Counter value={value} increment={increment} decrement={decrement} />
                  </>
                  : null
                }
             </h3>
        </section>
        <div className="flex justify-end mt-8 w-[70%]">
           <Button 
                  text={'Save Changes'} width={'w-[160px]'} 
                  bgColor={'bg-customBlue'} 
                  onClick={addLearnersToTeams}
             />
        </div>
    </div>
  )
}

export default TeamSetting