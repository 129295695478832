import {
  AiFillCheckCircle,
  AiOutlineUser,
  AiFillAlert,
  AiOutlineHome,
  AiFillHome,
  AiOutlineSearch,
  AiOutlinePlus,
  AiFillPlusCircle,
  AiOutlineMinus,
  AiFillMinusCircle,
  AiOutlineEdit,
  AiFillEdit,
  AiOutlineDelete,
  AiFillDelete,
  AiOutlineMail,
  AiFillMail,
  AiOutlineAppstore,
  AiFillAppstore,
  AiOutlineInfoCircle,
  AiFillInfoCircle,
  AiOutlineWarning,
  AiFillWarning,
  AiOutlineFile,
  AiFillFile,
  AiOutlineFolder,
  AiFillFolder,
  AiOutlineCloud,
  AiFillCloud,
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
  AiOutlineLock,
  AiFillLock,
  AiOutlineUnlock,
  AiFillUnlock,
  AiOutlinePhone,
  AiFillPhone,
  AiOutlineMessage,
  AiFillMessage,
  AiOutlineNotification,
  AiFillNotification,
  AiOutlineStar,
  AiFillStar,
  AiOutlineHeart,
  AiFillHeart,
  AiOutlineThunderbolt,
  AiFillThunderbolt,
  AiOutlineCheck,
  AiOutlineClose,
  AiFillCloseCircle,
  AiOutlineCamera,
  AiFillCamera,
  AiOutlineFileText,
  AiFillFileText,
  AiOutlineFileAdd,
  AiFillFileAdd,
  AiOutlineCompass,
  AiFillCompass,
  AiOutlineCar,
  AiFillCar,
  AiOutlineGift,
  AiFillGift,
  AiOutlineRocket,
  AiFillRocket,
  AiOutlineFlag,
  AiFillFlag,
  AiOutlineTrophy,
  AiFillTrophy,
  AiOutlineWallet,
  AiFillWallet,
  AiOutlineHistory,
  AiOutlineApi,
  AiFillApi,
  AiOutlineShop,
  AiFillShop,
  AiOutlineShareAlt,
  AiOutlinePushpin,
  AiFillPushpin,
  AiOutlineBarcode,
  AiOutlineBell,
  AiFillBell,
  AiOutlineCalendar,
  AiFillCalendar,
  AiOutlineFund,
  AiFillFund,
  AiOutlineLink,
  AiOutlineGithub,
  AiFillGithub,
  AiOutlineTwitter,
  AiOutlineFacebook,
  AiFillFacebook,
  AiOutlineInstagram,
  AiFillInstagram,
  AiOutlineLinkedin,
  AiFillLinkedin,
  AiOutlineWechat,
  AiFillWechat,
  AiOutlineSkype,
  AiFillSkype,
  AiOutlineSlack,
} from "react-icons/ai";

const iconList = [
  { name: "Check Circle", component: AiFillCheckCircle },
  { name: "User", component: AiOutlineUser },
  { name: "Alert", component: AiFillAlert },
  { name: "Home", component: AiOutlineHome },
  { name: "Filled Home", component: AiFillHome },
  { name: "Search", component: AiOutlineSearch },
  { name: "Plus", component: AiOutlinePlus },
  { name: "Filled Plus", component: AiFillPlusCircle },
  { name: "Minus", component: AiOutlineMinus },
  { name: "Filled Minus", component: AiFillMinusCircle },
  { name: "Edit", component: AiOutlineEdit },
  { name: "Filled Edit", component: AiFillEdit },
  { name: "Delete", component: AiOutlineDelete },
  { name: "Filled Delete", component: AiFillDelete },
  { name: "Mail", component: AiOutlineMail },
  { name: "Filled Mail", component: AiFillMail },
  { name: "Appstore", component: AiOutlineAppstore },
  { name: "Filled Appstore", component: AiFillAppstore },
  { name: "Info Circle", component: AiOutlineInfoCircle },
  { name: "Filled Info Circle", component: AiFillInfoCircle },
  { name: "Warning", component: AiOutlineWarning },
  { name: "Filled Warning", component: AiFillWarning },
  { name: "File", component: AiOutlineFile },
  { name: "Filled File", component: AiFillFile },
  { name: "Folder", component: AiOutlineFolder },
  { name: "Filled Folder", component: AiFillFolder },
  { name: "Cloud", component: AiOutlineCloud },
  { name: "Filled Cloud", component: AiFillCloud },
  { name: "Cloud Download", component: AiOutlineCloudDownload },
  { name: "Cloud Upload", component: AiOutlineCloudUpload },
  { name: "Lock", component: AiOutlineLock },
  { name: "Filled Lock", component: AiFillLock },
  { name: "Unlock", component: AiOutlineUnlock },
  { name: "Filled Unlock", component: AiFillUnlock },
  { name: "Phone", component: AiOutlinePhone },
  { name: "Filled Phone", component: AiFillPhone },
  { name: "Message", component: AiOutlineMessage },
  { name: "Filled Message", component: AiFillMessage },
  { name: "Notification", component: AiOutlineNotification },
  { name: "Filled Notification", component: AiFillNotification },
  { name: "Star", component: AiOutlineStar },
  { name: "Filled Star", component: AiFillStar },
  { name: "Heart", component: AiOutlineHeart },
  { name: "Filled Heart", component: AiFillHeart },
  { name: "Thunderbolt", component: AiOutlineThunderbolt },
  { name: "Filled Thunderbolt", component: AiFillThunderbolt },
  { name: "Check", component: AiOutlineCheck },
  { name: "Close", component: AiOutlineClose },
  { name: "Filled Close Circle", component: AiFillCloseCircle },
  { name: "Camera", component: AiOutlineCamera },
  { name: "Filled Camera", component: AiFillCamera },
  { name: "File Text", component: AiOutlineFileText },
  { name: "Filled File Text", component: AiFillFileText },
  { name: "File Add", component: AiOutlineFileAdd },
  { name: "Filled File Add", component: AiFillFileAdd },
  { name: "Compass", component: AiOutlineCompass },
  { name: "Filled Compass", component: AiFillCompass },
  { name: "Car", component: AiOutlineCar },
  { name: "Filled Car", component: AiFillCar },
  { name: "Gift", component: AiOutlineGift },
  { name: "Filled Gift", component: AiFillGift },
  { name: "Rocket", component: AiOutlineRocket },
  { name: "Filled Rocket", component: AiFillRocket },
  { name: "Flag", component: AiOutlineFlag },
  { name: "Filled Flag", component: AiFillFlag },
  { name: "Trophy", component: AiOutlineTrophy },
  { name: "Filled Trophy", component: AiFillTrophy },
  { name: "Wallet", component: AiOutlineWallet },
  { name: "Filled Wallet", component: AiFillWallet },
  { name: "History", component: AiOutlineHistory },
  { name: "Api", component: AiOutlineApi },
  { name: "Filled Api", component: AiFillApi },
  { name: "Shop", component: AiOutlineShop },
  { name: "Filled Shop", component: AiFillShop },
  { name: "Share Alt", component: AiOutlineShareAlt },
  { name: "Pushpin", component: AiOutlinePushpin },
  { name: "Filled Pushpin", component: AiFillPushpin },
  { name: "Barcode", component: AiOutlineBarcode },
  { name: "Bell", component: AiOutlineBell },
  { name: "Filled Bell", component: AiFillBell },
  { name: "Calendar", component: AiOutlineCalendar },
  { name: "Filled Calendar", component: AiFillCalendar },
  { name: "Fund", component: AiOutlineFund },
  { name: "Filled Fund", component: AiFillFund },
  { name: "Link", component: AiOutlineLink },
  { name: "Github", component: AiOutlineGithub },
  { name: "Filled Github", component: AiFillGithub },
  { name: "Twitter", component: AiOutlineTwitter },
  { name: "Facebook", component: AiOutlineFacebook },
  { name: "Filled Facebook", component: AiFillFacebook },
  { name: "Instagram", component: AiOutlineInstagram },
  { name: "Filled Instagram", component: AiFillInstagram },
  { name: "Linkedin", component: AiOutlineLinkedin },
  { name: "Filled Linkedin", component: AiFillLinkedin },
  { name: "Wechat", component: AiOutlineWechat },
  { name: "Filled Wechat", component: AiFillWechat },
  { name: "Skype", component: AiOutlineSkype },
  { name: "Filled Skype", component: AiFillSkype },
  { name: "Slack", component: AiOutlineSlack },
];

export default iconList;
