import React, { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Table from '../../../../../../../components/table/Table';
import EmptyPlaceHolder from '../../../../../../../components/EmptyPlaceHolder';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { set } from 'date-fns';
import RatingsTable from './RatingsTable';

type CoursesTableType = {
    perPage: number,
    courses: any,
    isLoading: boolean
}

const CoursesTable: React.FC<CoursesTableType> = ({courses, perPage, isLoading}) : React.ReactElement => {

  const navigate = useNavigate()
  const [openCourseRatingFeedback, setOpenCourseRatingFeedback] = React.useState<boolean>(false);
  const [activeCourse, setActiveCourse] = React.useState(null);

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index } : {index: number}) =>
              index +
              1 +
              ((courses?.meta?.page || 1) - 1) *
                (courses?.meta?.perPage || perPage),
          },
          {
            title: "Image",
            selector: "imagePath",
            key: "imagePath",
            render: ({ item } : {item: string}) => {
              return (
                <img
                  className="w-[35px] rounded-lg object-cover h-[35px] ml-2"
                  alt=""
                  src={item}
                />
              );
            },
          },
          {
            title: "Course Name",
            selector: "name",
            key: "name",
          },
          {
            title: "Status",
            selector: "status",
            key: "status",
            render: ({ item }: { item : string }) => {
              if (item === "published") {
                return <span className="text-[#00984C] w-[80px] block text-center py-1 rounded-md bg-green-200">{item}</span>;
              } else return <span className="text-[#FF9100] block w-[80px] text-center py-1 rounded-md bg-orange-200">{item}</span>;
            },
          },
          {
            title: "Category",
            selector: "category",
            key: "category",
            render: ({ row } : any ) => {
              return <span className="">{row?.category?.name}</span>;
            },
          },
          {
            title: "Multi Language",
            selector: null,
            key: null,
            render: ({ row } : any) => {
              return <span 
              className={ row?.languages?.length > 1 ? 'bi bi-check-circle text-primary-green text-sm pl-3' :
                 'bi bi-x-octagon text-red-500 text-sm pl-3'
              }
             />
            },
          },
          {
            title: "Course Code",
            selector: "courseCode",
            key: "courseCode",
            render: ({ item } : { item : string }) => {
              return <span className="">{item || <EmptyPlaceHolder count={1} /> }</span>;
            },
          }, 
          // {
          //   title: "Tag",
          //   selector: "courseTag",
          //   key: "courseTag",
          //   render: ({ item } : { item : string }) => {
          //     return (
          //       <span className="">{item ||  <EmptyPlaceHolder count={1} /> }</span>
          //     );
          //   },
          // },
          {
            title: "Average Rating",
            selector: "averageRating",
            key: "averageRating",
            render: ({ item } : { item : string }) => {
              return (
                <span className="">{item ||  <EmptyPlaceHolder count={1} /> }</span>
              );
            },
          },
          {
            title: "Date Added",
            selector: "createdAt",
            key: "createdAt",
            render: ({ item }: { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
            title: "Last Updated",
            selector: "updatedAt",
            key: "updatedAt",
            render: ({ item } : { item : string }) => {
              return (
                <span className="">{`${new Date(item).toLocaleDateString(
                  "en-ng"
                )}`}</span>
              );
            },
          },
          {
                title: "Action",
                selector: null,
                key: null,
                render: ({ row } : any) => {           
                  return (
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger asChild>
                        <button
                            className="text-sm bi bi-three-dots-vertical font-semibold" 
                            aria-label="Customise options" 
                          />
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Portal>
                        <DropdownMenu.Content className="DropdownMenuContent" sideOffset={5}>
                        <DropdownMenu.Item
                            className="DropdownMenuItem text-left  hover:cursor-pointer"
                            onClick={() => {
                              sessionStorage.setItem("active-course", JSON.stringify(row));
                              navigate(`/admin/course/edit/${row?.id}`)
                            }}
                        >
                            <i className='bi bi-pencil-square pr-1' /> Edit Profile
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator" />
                        <DropdownMenu.Item 
                            className="DropdownMenuItem text-left hover:cursor-pointer"
                            onClick={ () => {
                                setActiveCourse(row);
                               setOpenCourseRatingFeedback(true);
                            } }
                        >
                            <i className='bi bi-eye pr-1' /> View Course Ratings
                        </DropdownMenu.Item>
                        <DropdownMenu.Separator className="DropdownMenuSeparator hidden" />
                        </DropdownMenu.Content>
                    </DropdownMenu.Portal>
                  </DropdownMenu.Root>
                  )
                },
              },
        ],
        [1, 20, 20]
      );
    
    const tableProps = { isLoading, columns, data: courses?.data };

  return (
    <div>
      { openCourseRatingFeedback &&
        <RatingsTable
           open={openCourseRatingFeedback}
           setOpen={setOpenCourseRatingFeedback}
           activeCourse={activeCourse}
        />
      }
         <Table {...tableProps} />
    </div>
  )
}

export default CoursesTable