import React, { useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Switch from 'react-switch'
import { FontFamilyType, FontStyleType } from '../ChartsWidget';
import { tableData } from '../../CreateReport';
import { OtherChartType } from '../../types/charts.types';

type ChartSettingsProps = {
  otherChartTitle: string;
  setOtherChartTitle: React.Dispatch<React.SetStateAction<string>>;
  setShowOtherChartTitle: React.Dispatch<React.SetStateAction<boolean>>;
  showOtherChartTitle: boolean;
  otherChartFontStyle: FontStyleType;
  setOtherChartFontStyle: React.Dispatch<React.SetStateAction<FontStyleType>>;
  otherChartFontFamily: FontFamilyType;
  setOtherChartFontFamily: React.Dispatch<React.SetStateAction<FontFamilyType>>;
  tableData: tableData;
  showXAxis: boolean;
  setShowXAxis: React.Dispatch<React.SetStateAction<boolean>>;
  showYAxis: boolean;
  setShowYAxis: React.Dispatch<React.SetStateAction<boolean>>;
  xAxisLabel: string;
  setXAxisLabel: React.Dispatch<React.SetStateAction<string>>;
  yAxisLabel: string;
  setYAxisLabel: React.Dispatch<React.SetStateAction<string>>;
  isPyramidChart: boolean;
  activeOtherChart: OtherChartType;
  otherChartBackgroundColor: string | string[];
  setOtherChartBackgroundColor: React.Dispatch<React.SetStateAction<string | string[]>>;
  otherChartLineColor: string;
  setOtherChartLineColor: React.Dispatch<React.SetStateAction<string>>;
  otherChartDotColor: string;
  setOtherChartDotColor: React.Dispatch<React.SetStateAction<string>>;
  pyramidChartColor: string[];
  setPyramidChartColor: React.Dispatch<React.SetStateAction<string[]>>;
}

const OtherChartsSettings: React.FC<ChartSettingsProps> = ({ 
    otherChartTitle, setOtherChartTitle, setShowOtherChartTitle,
     showOtherChartTitle, 
     otherChartFontStyle, setOtherChartFontStyle,
     otherChartFontFamily, setOtherChartFontFamily, 
     tableData, showXAxis, setShowXAxis, showYAxis, setShowYAxis,
     xAxisLabel, setXAxisLabel, yAxisLabel, setYAxisLabel,
     isPyramidChart, activeOtherChart,
     otherChartBackgroundColor, setOtherChartBackgroundColor,
     otherChartLineColor, setOtherChartLineColor,
     otherChartDotColor, setOtherChartDotColor,
     pyramidChartColor, setPyramidChartColor,
 }) : JSX.Element => {
  
 const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleAccordionChange = (uuids: string[]) => {
    setExpandedItems(uuids);
  };

  return (
    <div>
        <Accordion 
          allowMultipleExpanded={false}
          allowZeroExpanded={true}
          preExpanded={[]}
          onChange={handleAccordionChange}
          className='border-0'
        >
            <AccordionItem className='border-0' uuid="item-1">
                <AccordionItemHeading className='bg-white border-t mt-3 p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Chart Title
                        {expandedItems.includes("item-1") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                    <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Chart Title
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showOtherChartTitle}
                          onChange={() => setShowOtherChartTitle(!showOtherChartTitle)}
                        /> 
                    </header>
                     { !isPyramidChart && 
                    <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show X Axis
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showXAxis}
                          onChange={() => setShowXAxis(!showXAxis)}
                        /> 
                    </header>}
                    { !isPyramidChart && 
                    <header className='flex py-2 border-b border-slate-300 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Y Axis
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showYAxis}
                          onChange={() => setShowYAxis(!showYAxis)}
                        /> 
                    </header>}
                     <div className='mt-2'>
                        <label htmlFor="chart-title" className='text-[11px] font-medium'>
                            Chart Title
                        </label>
                     <input
                        value={otherChartTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtherChartTitle(e.target.value)}
                        placeholder="Enter chart title"
                        className="input-style bg-primaryGray w-full text-[12px]"
                     />
                     </div>
                     { !isPyramidChart && 
                     <div className='mt-2'>
                        <label htmlFor="x-axis-label" className='text-[11px] font-medium'>
                            X Axis Label
                        </label>
                     <input
                        value={xAxisLabel}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setXAxisLabel(e.target.value)}
                            placeholder="Enter X Axis label"
                            className="input-style mt-2 bg-primaryGray w-full text-[12px]"
                        />
                     </div>}
                     { !isPyramidChart && 
                     <div className='mt-2'>
                        <label htmlFor="y-axis-label" className='text-[11px] font-medium'>
                            Y Axis Label
                        </label>
                        <input
                            value={yAxisLabel}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setYAxisLabel(e.target.value)}
                            placeholder="Enter Y Axis label mt-2"
                            className="input-style bg-primaryGray w-full text-[12px]"
                     />
                     </div>}
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-3">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Fonts
                        {expandedItems.includes("item-3") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                     <section className='flex mt-3 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Style
                        </p>
                        <select 
                            value={otherChartFontStyle}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setOtherChartFontStyle(e.target.value as FontStyleType)
                            }}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                        >
                            <option value="normal">Normal</option>
                            <option value="italic">Italic</option>
                            <option value="oblique">Oblique</option>
                        </select>
                     </section>
                     <section className='flex mt-4 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Family
                        </p>
                        <select 
                            value={otherChartFontFamily}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setOtherChartFontFamily(e.target.value as FontFamilyType)
                            }}
                        >
                            <option value="Public Sans">Public Sans</option>
                            <option value="Noto Sans">Noto Sans</option>
                            <option value="sans-serif">Sans-serif</option>
                            <option value="serif">Serif</option>
                            <option value="monospace">Monospace</option>    
                        </select>
                     </section>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-4">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Chart Settings
                        {expandedItems.includes("item-4") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                    {
                        isPyramidChart ? (
                            <section className='flex flex-col gap-2'>
                                {tableData.body.map((row, index) => (
                                    <div key={index} className="flex items-center justify-between">
                                        <span className="text-xs mr-3 text-slate-600">{row[1]}</span>
                                        <input 
                                            type="color" 
                                            value={pyramidChartColor[index] || '#0A3059'}
                                            onChange={(e) => {
                                                const newColors = [...pyramidChartColor];
                                                newColors[index] = e.target.value;
                                                setPyramidChartColor(newColors);
                                            }}
                                            className='w-[60px] h-[30px] border border-slate-300 rounded-lg' 
                                        />
                                    </div>
                                ))}
                            </section>
                        ) : (
                            <React.Fragment>
                                {activeOtherChart === 'Area Chart' && <section className='flex mt-3 justify-between items-center'>
                                    <p className='text-xs font-medium'>
                                        Background Color
                                    </p>
                                    <input 
                                       type="color" value={otherChartBackgroundColor}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtherChartBackgroundColor(e.target.value)} 
                                       className='w-[60px] h-[30px] border border-slate-300 rounded-lg'
                                   />
                                </section>}
                                { (activeOtherChart === 'Area Chart' || activeOtherChart === 'Line Chart') && 
                                <section className='flex mt-3 justify-between items-center'>
                                    <p className='text-xs font-medium'>
                                        Line Color
                                    </p>
                                    <input 
                                       type="color" value={otherChartLineColor}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtherChartLineColor(e.target.value)} 
                                       className='w-[60px] h-[30px] border border-slate-300 rounded-lg'
                                   />
                                </section>}
                                <section className='flex mt-3 justify-between items-center'>
                                    <p className='text-xs font-medium'>
                                        Dot Color
                                    </p>
                                    <input 
                                       type="color" value={otherChartDotColor}
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtherChartDotColor(e.target.value)} 
                                       className='w-[60px] h-[30px] border border-slate-300 rounded-lg'
                                   />
                                </section>
                            </React.Fragment>
                        )
                    }
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    </div>
  )
}

export default OtherChartsSettings