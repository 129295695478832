import React, { Suspense, useEffect, useState } from "react";
import Header, { ReportData } from "./components/Header";
import Data from "./components/Datas";
import Photos from "./components/Photos";
import Contents from "./components/Contents";
import Brand from "./components/Brand";
import Slides from "./components/Slides";
import Templates from "./components/Templates";
import Page from "./components/Page";
import ShareReportModal from "./components/ShareReportModal";
import { v4 } from "uuid";
import ConfirmModal from "../../../../../components/Modal/ConfirmModal";
import Text from "./components/Text";
import { useReportEditor } from "../hooks/ReportEditorContext";
import undoIcon from "../../../../../assets/svgs/redo.svg";
import redoIcon from "../../../../../assets/svgs/icon.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  elements,
} from "chart.js";
import Loader from "../../../../../components/loader/Loader";
import {
  initDataTable,
  initPieChartDataTable,
  initProgressChartDataTable,
  initStackedBarChartDataTable,
  navTabs, 
} from "./utils/charts.utils";
import {
  BarChartTitles,
  OtherChartType,
  pieChartType,
  chartType as activeChartType
} from "./types/charts.types";
import { DataCardType } from "./types/data.types";
import { dataCards as cards } from "./utils/data.utils";
import Graphics from "./components/Graphics";
import { DndContext } from "@dnd-kit/core";
import { useDroppable } from "@dnd-kit/core";
import { Rnd } from "react-rnd";
import { set } from "date-fns";
import { useQuery } from "react-query";
import { api } from "../../../../../api/report";
import toast from "react-hot-toast";
import { useLocation } from "react-router";
import PreviewHeader from "./components/PreviewHeader";
import { BarChartColorType, FontFamilyType, FontStyleType } from "./components/ChartsWidget";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export type TabType = {
  icon: string;
  text: string;
};
type endViewType = "slides" | "templates";

export type tableData = {
  body: (string | number)[][];
  headers: string[];
};

type chart = {
  id: string
  title: string
  image: string
}

export type chartType = {
  barchart: chart[],
  piechart: chart[],
  otherchart: chart[]
}

export type ChartActiveTab = 'settings' | 'chart data' | 'import data' 

type selectedChartType = null | 'barchart' | 'piechart' | 'otherchart'

const CreateReport: React.FC = (): JSX.Element => {
  const location = useLocation();
  const user = JSON.parse(window.sessionStorage.getItem("user") || "{}");
  const orgId = user?.org?.id;
  const searchParams = new URLSearchParams(location.search);
  const reportId = searchParams.get("reportId");
  const action = searchParams.get("action");
  const [activeWidget, setActiveWidget] = useState<'' | 'data' | 'charts'>('')
  const [tableData, setTableData] = useState<tableData>(initDataTable);
  const [stackedBarChartTableData, setStackedBarChartTableData] =
    useState<tableData>(initStackedBarChartDataTable);
  const [pieChartTableData, setPieChartTableData] = useState<tableData>(
    initPieChartDataTable
  );
  // same as pie chart table data structure
  const [otherChartTableData, setOtherChartTableData] = useState<tableData>(
    initPieChartDataTable
  );
  const [progressChartTableData, setProgressChartTableData] =
    useState<tableData>(initProgressChartDataTable);
  const [activeToolbarTab, setActiveToolbarTab] = useState<TabType>(navTabs[0]);
  const [title, setTitle] = useState<string>("Untitled Report");
  const [endView, setEndView] = useState<endViewType>("slides");
  const [pages, setPages] = useState<any[]>(
    (reportId && action === 'create') ? [{ id: v4(), backgroundColor: "#ffffff" }] : []
  );
   const chartTableTabs: ChartActiveTab[] = ['chart data', 'import data', 'settings']
   const [showDhoughntuCenterText, setShowDhoughntuCenterText] = useState<boolean>(false)
   const [centerText, setCenterText] = useState<string>('');
   const [activeTab, setActiveTab] = useState<ChartActiveTab>('chart data')
   const [barChartTitle, setBarChartTitle] = useState<string>('Sample Bar Chart')
   const [pieChartTitle, setPieChartTitle] = useState<string>('Sample Pie Chart')
   const [stackedBarChartTitle, setStackedBarChartTitle] = useState<string>('Sample Stacked Bar Chart')
   const [showBarChartTitle, setShowBarChartTitle] = useState<boolean>(false)
   const [showPieChartTitle, setShowPieChartTitle] = useState<boolean>(false)
   const [showStackedBarChartTitle, setShowStackedBarChartTitle] = useState<boolean>(false)
   const [showOtherChartTitle, setShowOtherChartTitle] = useState<boolean>(false)
   const [showBarChartLegend, setShowBarChartLegend] = useState<boolean>(true)
   const [showPieChartLegend, setShowPieChartLegend] = useState<boolean>(true)
   const [showStackedBarChartLegend, setShowStackedBarChartLegend] = useState<boolean>(true)
   const [legendPosition, setLegendPosition] = useState<'top' | 'bottom'>('top')
   const [pieChartLegendPosition, setPieChartLegendPosition] = useState<'top' | 'bottom'>('top')
   const [stackedLegendPosition, setStackedLegendPosition] = useState<'top' | 'bottom'>('top')
   const [barChartFontStyle, setBarchartFontStyle] = useState<FontStyleType>('normal')
   const [pieChartFontStyle, setPieChartFontStyle] = useState<FontStyleType>('normal')
   const [otherChartFontStyle, setOtherChartFontStyle] = useState<FontStyleType>('normal')
   const [stackedBarChartFontStyle, setStackedBarChartFontStyle] = useState<FontStyleType>('normal')
   const [barChartFontFamily, setBarchartFontFamily] = useState<FontFamilyType>('Public Sans')
   const [pieChartFontFamily, setPieChartFontFamily] = useState<FontFamilyType>('Public Sans')
   const [otherChartFontFamily, setOtherChartFontFamily] = useState<FontFamilyType>('Public Sans')
   const [stackedBarChartFontFamily, setStackedBarChartFontFamily] = useState<FontFamilyType>('Public Sans')
   const [barChartColorType, setBarChartColorType] = useState<BarChartColorType>('single')
   const [barChartColor, setBarChartColor] = useState<string | string[]>('#0A3059')
   const [stackedBarChartColorType, setStackedBarChartColorType] = useState<BarChartColorType>('single')
   const [stackedBarChartColor, setStackedBarChartColor] = useState<string | string[]>('#0A3059')
   const [pieChartColorType, setPieChartColorType] = useState<BarChartColorType>('single')
   const [pieChartColor, setPieChartColor] = useState<string | string[]>('#0A3059')
   const [xAxisLabel, setXAxisLabel] = useState<string>('Horizonatal Label')
   const [yAxisLabel, setYAxisLabel] = useState<string>('Vertical Label')
   const [showXAxisLabel, setShowXAxisLabel] = useState<boolean>(true)
   const [showYAxisLabel, setShowYAxisLabel] = useState<boolean>(true)
   const [otherChartTitle, setOtherChartTitle] = useState<string>('Sample Other Chart')
   const [otherChartColor, setOtherChartColor] = useState<string | string[]>('#0A3059')
   const [otherChartBackgroundColor, setOtherChartBackgroundColor] = useState<string | string[]>('#0A3059')
   const [activeChart, setActiveChart] = useState<activeChartType>('barchart')
   const [otherChartLineColor, setOtherChartLineColor] = useState<string>('#0A3059')
   const [otherChartDotColor, setOtherChartDotColor] = useState<string>('#0A3059')
   const [pyramidChartTitle, setPyramidChartTitle] = useState<string>('Sample Pyramid Chart')
   const [pyramidChartColor, setPyramidChartColor] = useState<string[]>(['#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C'])
   const [progressChartTitle, setProgressChartTitle] = useState<string>('Sample Progress Chart');
   const [progressChartColor, setProgressChartColor] = useState<string>('#0A3059');
   const [showProgressChartTitle, setShowProgressChartTitle] = useState<boolean>(true);
   const [progressChartFontStyle, setProgressChartFontStyle] = useState<FontStyleType>('normal');
   const [progressChartFontFamily, setProgressChartFontFamily] = useState<FontFamilyType>('Public Sans');
  const [open, setOpen] = useState<boolean>(false);
  const [activeBarchart, setActiveBarchart] =
    useState<BarChartTitles>("Column Bar Chart");
  const [activePieChart, setActivePieChart] =
    useState<pieChartType>("Pie Chart");
  const [openDeleteConfirmModal, setOpenDeleteConfirmModal] =
    useState<boolean>(false);
  const [activePageId, setActivePageId] = useState<string | null>(null);
  const [activeOtherChart, setActiveOtherChart] =
    useState<OtherChartType>("Dot Chart");
  const [dataCards, setDataCards] = useState<DataCardType[]>(cards);
  const [openPreviewReportModal, setOpenPreviewReportModal] =
    useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [pageStructure, setPageStructure] = useState<any[]>([]);

  const changeActiveTab = (tab: TabType) => {
    setActiveToolbarTab(tab);
  };

  const {
    undo,
    redo,
    canUndo,
    canRedo,
    elements,
    setElements,
    activeElementId,
    setActiveElementId,
  } = useReportEditor();

  const {
    data: reportData,
    isLoading,
    refetch,
  }: any = useQuery(
    ["single-report", reportId],
    async () => {
      const response = await api.getReport({
        orgId,
        reportId,
      });
      return response?.data; // Ensure the API response matches the `QueryResponse` type
    },
    {
      enabled: !!reportId,
      //staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      // cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
      onSuccess: (reportData) => {
        if (action === "edit") {
          if (reportData?.pages?.length) {
            const pages_ = reportData?.pages;
            setPages?.(
              pages_?.map((page: any) => {
                return {
                  id: page?.pageId,
                  backgroundColor: JSON.parse(page?.page)?.backgroundColor,
                };
              })
            );
            setActivePageId?.(pages_[0]?.pageId);
            const allElements = reportData.pages.flatMap((page: any) => {
              const parsedPage = JSON.parse(page.page);
              return parsedPage.elements;
            });
            setPageStructure([...allElements]);
            setElements([...allElements]);
            toast.success("Report loaded successfully");
            toast.remove();
          } else {
            setPages?.([]);
            setElements?.([]);
            setPageStructure([]);
            toast.success("There is no any page in this report");
            toast.remove();
          }
        }
      },
    }
  );

  const addNewPage = () => {
    if (pages) setPages?.([...pages, { id: v4(), backgroundColor: "#ffffff" }]);
  };
  const { setNodeRef } = useDroppable({
    id: "page-drop-area",
  });

  const duplicatePage = (pageId: string) => {
    const elementsToCopy = elements?.filter((_element: any) => {
      return _element?.activePageId === pageId;
    });
    const newPageId = v4();
    const pageIndex = pages?.findIndex((page: any) => page.id === pageId);
    const newPageElements = elementsToCopy?.map((element: any) => {
      return {
        ...element,
        activePageId: newPageId,
        elementId: v4(),
      };
    });
    const newPage = {
      id: newPageId,
      backgroundColor: pages?.[pageIndex]?.backgroundColor,
    };
    let updatedPages = [...pages];
    updatedPages?.splice(pageIndex + 1, 0, newPage);
    setPages?.(updatedPages);
    setElements?.([...elements, ...newPageElements]);
    setPageStructure?.([...pageStructure, ...newPageElements]);
  };

  const elementSetter = (id: string, elements: any, setPageStructure: any) => {
    const filteredElement = elements;
    setPageStructure(filteredElement);
  };

  if (isLoading && reportId) return <Loader />;

  return (
    <div className='h-screen -mt-5 overflow-y-hidden'>
    <DndContext>
    <section ref={setNodeRef} className='sticky top-[60px]'>
      <Suspense
       fallback={
       <div className='flex justify-center items-center h-screen'>
         <Loader />
      </div>
       }
      >
         <ShareReportModal open={open} setOpen={setOpen} />
      </Suspense>
      {openDeleteConfirmModal && 
      <ConfirmModal
         title='Confirm Pages Deletion'
         style={null}
         description='Are you sure you want to delete all pages?'
         onYes={() => {
          setPages?.([])
          setOpenDeleteConfirmModal(false)
         }}
         onClose={() => setOpenDeleteConfirmModal(false)}
      />}
        {
          isPreview ?
          <PreviewHeader
            setPreview={setIsPreview}
            data={reportData}
          /> :
          <Header 
            title={title} 
            setOpen={setOpen} 
            setTitle={setTitle}
            setIsPreview={setIsPreview}
            setOpenPreviewReportModal={setOpenPreviewReportModal}
            pages={pages}
            pageStructure={pageStructure}
            data={reportData}
            reportId={reportId}
            action={action}
        />}
        <section className='flex justify-between pt-1'>
            { !isPreview && 
            <section className={`w-[11%] h-[calc(100vh-100px)] overflow-y-auto bg-white`}>
             <nav className='h-[40px] px-2 content-center grid grid-cols-2 gap-4 border-b border-slate-300'> 
                <span className={` text-xs pl-2 hover:cursor-pointer ${ endView === 'slides' ?
                      'text-primary-blue font-semibold border-b-2 border-primary-blue' :
                       'text-slate-900'
                      }`}
                 onClick={ () => setEndView('slides') }
                > 
                 Pages 
                </span>
                <span className={` text-xs hover:cursor-pointer ${ endView === 'templates' ?
                      'text-primary-blue font-semibold border-b-2 border-primary-blue' :
                       'text-slate-900'
                      }`}
                 onClick={ () => setEndView('templates') }
                >
                  Templates
                </span>
              </nav>
              <section className='p-2'>
                 {  
                    endView === 'slides' ? 
                     <Slides 
                        setPages={setPages} pages={pages} 
                        //newPage={Page}
                        activeElementId={activeElementId}
                        setActiveElementId={setActiveElementId}
                        activePageId={activePageId}
                        setActivePageId={setActivePageId}
                        elementSetter={elementSetter}
                        pageStructure={pageStructure}
                        setPageStructure={setPageStructure}
                      />
                      :
                     <Templates refetch={refetch} />
                 }
              </section>
            </section>}
            <section className={` ${ isPreview ? 'w-full mt-5' : 'w-[69%]'}`}>
                <div className="relative">
                  <div className="">
                    <div className="h-[600px] overflow-y-auto">
                    <section className={isPreview ? 'p-0' : 'pb-[200px]'}>
                      { !isPreview &&
                      <div className='sticky top-0 z-10 bg-white'>
                      <nav className='h-[40px] bg-white px-2 flex items-center gap-4 border-b border-slate-300'> 
                    <span 
                      className={`bi text-lg mr-4 hover:cursor-pointer ${!canUndo ? 'opacity-50' : ''}`}
                      onClick={undo}
                      style={{ cursor: canUndo ? 'pointer' : 'not-allowed' }}
                    >
                      <img src={undoIcon} alt='undo' />
                    </span>
                    <span 
                      className={`bi text-lg mr-4 hover:cursor-pointer ${!canRedo ? 'opacity-50' : ''}`}
                      onClick={redo}
                      style={{ cursor: canRedo ? 'pointer' : 'not-allowed' }}
                    >
                      <img src={redoIcon} alt='redo' />
                    </span>
                    <span 
                      className='bi bi-trash text-lg mr-4 hover:cursor-pointer' 
                      onClick={() => setOpenDeleteConfirmModal(true)}
                    />
                    <button
                      className='block p-2 hover:bg-gray-100 rounded-lg'
                      onClick={addNewPage}
                      >
                      <i className='bi bi-file-earmark-plus text-gray-600'></i>
                    </button>
                     </nav>
                      </div>}
                  <div id='report-canvas-container' className={ isPreview ? 'p-0' : 'report-border-0 space-y-1'}> {/* added space-y-1 for minimal spacing */}
                    {console.log(centerText)
                    }
                    { 
                      pages.map( (page, index) => {
                        return <>
                            <Page
                                  key={page.id} 
                                  pageIndex={index + 1}
                                  setPages={setPages}
                                  newPage={page}
                                  centerText={centerText}
                                  pages={pages}
                                  pageId={page.id}
                                  duplicatePage={duplicatePage}
                                  scalable
                                  activeWidget={activeWidget}
                                  setActiveWidget={setActiveWidget}
                                  style={{ backgroundColor: page.backgroundColor }}
                                  isPreview={isPreview}
                                  activeElementId={activeElementId}
                                  setActiveElementId={setActiveElementId}
                                  activePageId={activePageId}
                                  setActivePageId={setActivePageId}
                                  activeToolbarTab={activeToolbarTab} 
                                  setActiveToolbarTab={setActiveToolbarTab}
                                  elementSetter={elementSetter}
                                  pageStructure={pageStructure}
                                  setPageStructure={setPageStructure}
                                  elementer={ pageStructure?.filter((x:any)=>x.activePageId === page.id)} 
                              />
                              {
                                isPreview ? <> 
                                    <div className='block h-[7px] bg-slate-100'></div>
                                </> : null
                              }
                        </>
                      })
                    }
                  </div>
                </section>
                    </div>
                  </div>
                </div>
              </section>
            { !isPreview &&
              <section className='w-[20%] h-[calc(100vh-100px)] overflow-y-auto bg-white'>
                <div className='sticky top-0 z-10 bg-white'>
                <nav className='h-[40px] content-center grid grid-cols-6 border-b border-slate-300'> 
                  {
                    navTabs.map( tab => {
                     return <span  
                        className={`text-slate-950 hover:bg-slate-300 ${
                            activeToolbarTab.text === tab.text ? 'bg-white' : 'bg-slate-200'
                          }  hover:cursor-pointer text-[11px] h-full flex flex-col items-center`}
                          onClick={() => changeActiveTab(tab)}
                          key={v4()}
                        >
                          <i
                            className={`bi ${tab.icon} text-lg text-slate-700`}
                          />
                          {tab.text}
                        </span>
                    })}
                  </nav>
                </div>
                <section className="p-3 mt-">
                  {activeToolbarTab.text === navTabs[0].text && (
                    <Text
                      activePageId={activePageId}
                      elementSetter={elementSetter}
                      pageStructure={pageStructure}
                      setPageStructure={setPageStructure}
                      activeElementId={activeElementId}
                    />
                  )}
                  {activeToolbarTab.text === navTabs[1].text && (
                    <Graphics
                      activePageId={activePageId}
                      elementSetter={elementSetter}
                      pageStructure={pageStructure}
                      setPageStructure={setPageStructure}
                    />
                  )}
                  {activeToolbarTab.text === navTabs[2].text && (
                    <Data
                      showDhoughntuCenterText={showDhoughntuCenterText}
                      setShowDhoughntuCenterText={setShowDhoughntuCenterText}
                      centerText ={centerText}
                      setCenterText={setCenterText}
                     tableData={tableData}
                     activeWidget={activeWidget}
                     setActiveWidget={setActiveWidget}
                     setTableData={setTableData}
                     activeBarchart={activeBarchart}
                     setActiveBarchart={setActiveBarchart}
                     stackedBarChartTableData={stackedBarChartTableData}
                     setStackedBarChartTableData={setStackedBarChartTableData}
                     progressChartTableData={progressChartTableData}
                     setProgressChartTableData={setProgressChartTableData}
                     activePieChart={activePieChart}
                     setActivePieChart={setActivePieChart}
                     pieChartTableData={pieChartTableData}
                     setPieChartTableData={setPieChartTableData}
                     activeOtherChart={activeOtherChart}
                     setActiveOtherChart={setActiveOtherChart}
                     otherChartTableData={otherChartTableData}
                     setOtherChartTableData={setOtherChartTableData}
                     dataCards={dataCards}
                     setDataCards={setDataCards}
                     activePageId={activePageId!}
                     elementSetter={elementSetter}
                     pageStructure={pageStructure}
                     setPageStructure={setPageStructure}
                     activeElementId={activeElementId}
                     activeChart={activeChart}
                     activeTab={activeTab}
                     barChartColor={barChartColor}
                     barChartColorType={barChartColorType}
                     barChartFontFamily={barChartFontFamily}
                     barChartFontStyle={barChartFontStyle}
                     barChartTitle={barChartTitle}
                     legendPosition={legendPosition}
                     otherChartBackgroundColor={otherChartBackgroundColor}
                     otherChartColor={otherChartColor}
                     otherChartDotColor={otherChartDotColor}
                     otherChartFontFamily={otherChartFontFamily}
                     otherChartFontStyle={otherChartFontStyle}
                     otherChartLineColor={otherChartLineColor}
                     otherChartTitle={otherChartTitle}
                     pieChartColor={pieChartColor}
                     pieChartColorType={pieChartColorType}
                     pieChartFontFamily={pieChartFontFamily}
                     pieChartFontStyle={pieChartFontStyle}
                     pieChartLegendPosition={pieChartLegendPosition}
                     pieChartTitle={pieChartTitle}
                     progressChartColor={progressChartColor}
                     progressChartFontFamily={progressChartFontFamily}
                     progressChartFontStyle={progressChartFontStyle}
                     pyramidChartColor={pyramidChartColor}
                     progressChartTitle={progressChartTitle}
                     pyramidChartTitle={pyramidChartTitle}
                     setActiveChart={setActiveChart}
                     setActiveTab={setActiveTab}
                     setBarChartColorType={setBarChartColorType}
                     setBarChartTitle={setBarChartTitle}
                     setBarchartFontFamily={setBarchartFontFamily}
                     setBarchartFontStyle={setBarchartFontStyle}
                     setLegendPosition={setLegendPosition}
                     setOtherChartColor={setOtherChartColor}
                     setOtherChartDotColor={setOtherChartDotColor}
                     setOtherChartBackgroundColor={setOtherChartBackgroundColor}
                     setOtherChartFontFamily={setOtherChartFontFamily}
                     setOtherChartFontStyle={setOtherChartFontStyle}
                     setOtherChartLineColor={setOtherChartLineColor}
                     setOtherChartTitle={setOtherChartTitle}
                     setPieChartColor={setPieChartColor}
                     setPieChartColorType={setPieChartColorType}
                     setPieChartFontFamily={setPieChartFontFamily}
                     setPieChartFontStyle={setPieChartFontStyle}
                     setPieChartLegendPosition={setPieChartLegendPosition}
                     setProgressChartColor={setProgressChartColor}
                     setProgressChartFontFamily={setProgressChartFontFamily}
                     setPieChartTitle={setPieChartTitle}
                     setProgressChartTitle={setProgressChartTitle}
                     setProgressChartFontStyle={setProgressChartFontStyle}
                     setPyramidChartColor={setPyramidChartColor}
                     setPyramidChartTitle={setPyramidChartTitle}
                     setShowBarChartLegend={setShowBarChartLegend}
                     setShowBarChartTitle={setShowBarChartTitle}
                     setShowOtherChartTitle={setShowOtherChartTitle}
                     setShowPieChartLegend={setShowPieChartLegend}
                     setShowPieChartTitle={setShowPieChartTitle}
                     setShowStackedBarChartLegend={setShowStackedBarChartLegend}
                     setShowStackedBarChartTitle={setShowStackedBarChartTitle}
                     setBarChartColor={setBarChartColor}
                     stackedBarChartFontStyle={stackedBarChartFontStyle}
                     setStackedBarChartFontStyle={setStackedBarChartFontStyle}
                     showBarChartLegend={showBarChartLegend}
                     showBarChartTitle={showBarChartTitle}
                     showPieChartLegend={showPieChartLegend}
                     showPieChartTitle={showPieChartTitle}
                     showStackedBarChartLegend={showStackedBarChartLegend}
                     showStackedBarChartTitle={showStackedBarChartTitle}
                     setShowProgressChartTitle={setShowProgressChartTitle}
                     showProgressChartTitle={showProgressChartTitle}
                     setStackedBarChartColor={setStackedBarChartColor}
                     setShowYAxisLabel={setShowYAxisLabel}
                     setStackedBarChartColorType={setStackedBarChartColorType}
                     setStackedBarChartTitle={setStackedBarChartTitle}
                     setStackedBarChartFontFamily={setStackedBarChartFontFamily}
                     setShowXAxisLabel={setShowXAxisLabel}
                     setStackedLegendPosition={setStackedLegendPosition}
                     setXAxisLabel={setXAxisLabel}
                     showXAxisLabel={showXAxisLabel}
                     showYAxisLabel={showYAxisLabel}
                     showOtherChartTitle={showOtherChartTitle}
                     stackedBarChartColor={stackedBarChartColor}
                     stackedBarChartColorType={stackedBarChartColorType}
                     stackedBarChartFontFamily={stackedBarChartFontFamily}
                     stackedBarChartTitle={stackedBarChartTitle}
                     stackedLegendPosition={stackedLegendPosition}
                     setYAxisLabel={setYAxisLabel}
                     xAxisLabel={xAxisLabel}
                     yAxisLabel={yAxisLabel}
                     />                   
                  )}
                  {activeToolbarTab.text === navTabs[3].text && (
                    <Photos
                      activePageId={activePageId}
                      elementSetter={elementSetter}
                      pageStructure={pageStructure}
                      setPageStructure={setPageStructure}
                    />
                  )}
                  {activeToolbarTab.text === navTabs[4].text && <Contents />}
                  {activeToolbarTab.text === navTabs[5].text && <Brand />}
                </section>
              </section>
            }
          </section>
        </section>
      </DndContext>
    </div>
  );
};

export default CreateReport;
