import React, { useState } from 'react'
import DataWidget from './DataWidget'
import ChartsWidget from './ChartsWidget'
import { DataProps } from '../types/data.types'
import CustomDropdown from '../../../../../../components/dropdown/CustomDropdown'
import useChartsData from '../utils/useChartsData'
import { initDataTable, initPieChartDataTable } from '../utils/charts.utils'
import toast from 'react-hot-toast'
import { useReportEditor } from '../../hooks/ReportEditorContext'

export type TchartType = (
  'barchart' | 'piechart' | 'doughnutchart' | 'horizontalbarchart' 
)
export type chartsTypes = TchartType[] 

const Data: React.FC<DataProps> = ({
    tableData, setTableData, activeBarchart, setActiveBarchart,
    stackedBarChartTableData, setStackedBarChartTableData,
    progressChartTableData, setProgressChartTableData, 
    activePieChart, setActivePieChart,showDhoughntuCenterText,
    centerText, setCenterText, setShowDhoughntuCenterText,
    pieChartTableData, setPieChartTableData,
    activeOtherChart, setActiveOtherChart,
    otherChartTableData, setOtherChartTableData,
    dataCards, setDataCards, activePageId,
     elementSetter, pageStructure,  yAxisLabel,
    setPageStructure, activeElementId,
    activeChart, activeTab, barChartColor, barChartColorType,barChartFontFamily,
    barChartFontStyle,otherChartBackgroundColor,barChartTitle,otherChartColor,
    legendPosition,otherChartFontFamily,otherChartFontStyle,otherChartLineColor,pieChartColor,
    otherChartDotColor,pieChartColorType,pieChartFontFamily,otherChartTitle,pieChartLegendPosition,
    pieChartFontStyle,pieChartTitle,progressChartFontFamily,progressChartFontStyle,
    progressChartColor,pyramidChartTitle,pyramidChartColor,progressChartTitle,setActiveTab,
    setActiveChart,setBarChartColor,setBarChartColorType,setBarChartTitle, setBarchartFontStyle,
    setBarchartFontFamily,setOtherChartBackgroundColor,setLegendPosition,
    setOtherChartColor, setOtherChartDotColor, setOtherChartFontFamily, setOtherChartFontStyle,
    setOtherChartLineColor, showStackedBarChartLegend, setOtherChartTitle, setPieChartColor, 
    setPieChartFontFamily, setPieChartFontStyle, setPieChartColorType, setPieChartLegendPosition, 
    setProgressChartColor, setProgressChartFontStyle,setPieChartTitle,setProgressChartTitle,
    setProgressChartFontFamily, setPyramidChartColor,setPyramidChartTitle, setShowBarChartTitle,
    setShowBarChartLegend,setShowPieChartLegend,setShowPieChartTitle,setShowStackedBarChartLegend,
    setShowOtherChartTitle,setShowProgressChartTitle,setShowStackedBarChartTitle,setShowYAxisLabel,
    setShowXAxisLabel,setStackedBarChartColorType,setStackedBarChartFontFamily,setStackedBarChartFontStyle,
    setStackedBarChartTitle,setStackedLegendPosition,setXAxisLabel,setYAxisLabel,showBarChartLegend,showBarChartTitle,
    setStackedBarChartColor,showOtherChartTitle,showPieChartLegend,showProgressChartTitle,showStackedBarChartTitle,
    showPieChartTitle, showXAxisLabel,stackedBarChartColor,stackedBarChartFontFamily,stackedBarChartColorType,
    showYAxisLabel,stackedBarChartFontStyle,stackedBarChartTitle,stackedLegendPosition,xAxisLabel,
    activeWidget, setActiveWidget,
}) : JSX.Element => {

  const dataTypes = ['cohort enrollment', 'course completion',]
  const chartTypes = ['barchart', 'piechart', 'doughnutchart', 'horizontalbarchart']

  const [dataType, setDataType] = useState<string|null>(null)
  const [chartType, setChartType] = useState<TchartType>('barchart')
  const [cohort, setCohort] = useState<string|null>(null)
  const [multiCohort, setMultiCohort] = useState<any[]>([])

  const {
    getCourseCompletionBarChartLoading, 
    getReportCourseCompletion, cohorts,
    getCohortCompletionBarChartLoading,
    getReportCohortCompletion,
    getReportCompletionData,
    getReportCompletionDataLoading,
  } = useChartsData()

  const { addElement } = useReportEditor();

  const generateChartData = async() => {

    if(activeWidget === 'charts'){
    // target the button element to click
      let buttonBar: any = document.querySelector(
      chartType === 'barchart' ? '#bar-chart-0' : '#bar-chart-2'
     ) 
     let buttonPie: any = document.querySelector(
      chartType === 'piechart' ? '#piechart-0' : '#piechart-1'
    )
// check the data type and chart type to generate the chart
      switch(dataType){
        case 'course completion':
        const cohortIds = multiCohort.map((item: any) => item.value).join(',');
        let barcharData = await getReportCourseCompletion({
          cohortIds,
         type:'chart',
         slug: 'courseCompletion',
         chart: chartType === 'doughnutchart' ? 'piechart' : 
               chartType === 'horizontalbarchart' ? 'barchart' : chartType!,
        })
        if(!barcharData?.length) return toast.error('No data found')
        if(
          (barcharData?.length && chartType === 'barchart') ||
          (barcharData?.length && chartType === 'horizontalbarchart')
        ){
          if(buttonBar) buttonBar.click()
          setTimeout( () => {
           setTableData?.({...initDataTable, body: [
             ...barcharData?.map( 
              (data: any, index: number) => [index + 1, data?.name,data?.completionData
             ])
           ]})
         }, 100 )
        }
        if(
          (barcharData?.length && chartType === 'piechart') || 
          (barcharData?.length && chartType === 'doughnutchart')
        ){ 
          if(buttonPie) buttonPie.click()
            setTimeout( () => {
              setPieChartTableData?.({...initPieChartDataTable, body: [
                ...barcharData?.map( 
                 (data: any, index: number) => [
                  index + 1, 
                  data?.name,
                  data?.data[0]?.count,
                ])
              ]})
            }, 100 )
        }
        break
        case 'cohort enrollment':
           let charData = await getReportCohortCompletion({
              cohortIds: cohort!,
              type:'chart',
              slug: 'cohortEnrollment',
              chart: chartType === 'doughnutchart' ? 'piechart' : 
              chartType === 'horizontalbarchart' ? 'barchart' : chartType!,
            })
        //if(!charData?.length) return toast.error('No data found')
        if(
          (charData?.length && chartType === 'barchart') ||
          (charData?.length && chartType === 'horizontalbarchart')
        ){
           if(buttonBar) buttonBar.click()
           if(charData?.length){
            setShowBarChartTitle(true)
            setShowBarChartLegend(true)
            setBarChartTitle(charData[0]?.name)
            setTimeout( () => {
              setTableData?.({...initDataTable, body: [
                ...charData[0]?.enrollmentData?.map( 
                 (data: any, index: number) => [
                  index + 1, data?.enrollmentDate, parseInt(data?.count)
                ])
              ]})
            }, 100 )
           }
        }
        if(
          (charData?.length && chartType === 'piechart') || 
          (charData?.length && chartType === 'doughnutchart')
        ) {
              if(buttonPie) buttonPie.click()
                setShowPieChartTitle(true)
                setShowPieChartLegend(true)
                setPieChartTitle(charData[0]?.name)
                setTimeout( () => {
                  setPieChartTableData?.({...initPieChartDataTable, body: 
                    charData[0]?.data?.map( 
                     (data: any, index: number) => [
                      index + 1, 
                      data?.category,
                      parseInt(data?.count),
                    ])
                  })
                }, 100 )
          }
        break;
      }
     }
     else{
      let dataCard = null
      let data = await getReportCompletionData({
        cohortIds: cohort!,
        slug: dataType === 'cohort enrollment' ? 'cohortEnrollment' : 'courseCompletion',
      })
        if(dataType === 'course completion'){
          if(!data?.length) return toast.error('No data found')
          dataCard = {
            title: data[0]?.name,
            value: data[0]?.completed,
            x: 50,
            y: 50,
          }
        }
        if(dataType === 'cohort enrollment'){
          if(!data?.length) return toast.error('No data found')
          dataCard = {
            title: data[0]?.name,
            value: data[0]?.numberOfEnrolled,
            x: 50,
            y: 50,
          }
        }
        if(dataCard === null) return
        elementSetter(
          activePageId,
          addElement(
            "data-card",
            activePageId,
            "",
            null,
            null,
            dataCard,
            50,
            50
          ),
          setPageStructure
        );
     }
    }

  return (
    <div className='pt-4'>  
       <header className='flex flex-col gap-5'>
        <button className={
          `bi bi-diamond ${activeWidget === 'data' ? 
          'btn bg-primary-blue' 
          : 'btn-light bg-lightGray'}`
        }
        onClick={() => setActiveWidget?.('data')}
        >
          &nbsp; Data Widget 
        </button>
        <button className={
          `bi bi-bar-chart ${activeWidget === 'charts' ? 
          'btn bg-primary-blue' 
          : 'btn-light bg-lightGray'}`
        }
        onClick={() => setActiveWidget?.('charts')}
        >
          &nbsp; Charts & Graphs
        </button>
       </header>
       <section>
        <section className='pt-3'>
        <div>
                <label className='text-xs text-gray-700'>
                  Select Data Type
                </label>
                <CustomDropdown
                    options={ dataTypes?.map( (data: any) => {
                        return { label: data, value: data  }
                      })
                    }
                    value={
                      { 
                          label: dataType, 
                          value: dataType
                      }
                    } 
                    onChange={ (value:any) => {
                      setDataType(value?.value)
                    }}
                    onBlur={null}
                    name={'category'}
                    className={'bg-primaryGray select-custom'}
                    width={'w-full'}
                    placeholder={'Select data type'}
                />
                </div>
                <div className='mt-3'>
                <label className='text-xs text-gray-700'>
                  Select Cohort  
                </label>
                {
                  (dataType !== 'cohort enrollment' && activeWidget !== 'data') ?
                  <CustomDropdown
                     multiSelect
                      options={ cohorts?.map( (data: any) => {
                          return { label: data?.cohort?.name, value: data?.cohort?.id  }
                        })
                      }
                      value={multiCohort} 
                      onChange={ (value:any) => {
                        setMultiCohort(value)
                      }}
                      onBlur={null}
                      name={'category'}
                      className={'bg-primaryGray select-custom'}
                      width={'w-full'}
                      placeholder={'Select data type'}
                  />
                  :
                  <CustomDropdown
                    options={ cohorts?.map( (data: any) => {
                        return { label: data?.cohort?.name, value: data?.cohort?.id  }
                      })
                    }
                    value={
                      { 
                          label: cohorts?.find( (data: any) => data?.cohort?.id === cohort )?.cohort?.name , 
                          value: cohort
                      }
                    } 
                    onChange={ (value:any) => {
                      setCohort(value?.value)
                    }}
                    onBlur={null}
                    name={'category'}
                    className={'bg-primaryGray select-custom'}
                    width={'w-full'}
                    placeholder={'Select data type'}
                />
                }
                </div>
                { activeWidget === 'charts' &&
                <div className='mt-3'>
                <label className='text-xs text-gray-700'>
                  Select Chart Type
                </label>
                <CustomDropdown
                    options={ chartTypes?.map( (data: any) => {
                        return { label: data, value: data  }
                      })
                    }
                    value={
                      { 
                          label: chartType, 
                          value: chartType
                      }
                    } 
                    onChange={ (value:any) => {
                      setChartType(value?.value)
                    }}
                    onBlur={null}
                    name={'category'}
                    className={'bg-primaryGray select-custom'}
                    width={'w-full'}
                    placeholder={'Select data type'}
                />
                </div>}
                <button 
                   className='btn bg-primary-blue block mt-4 w-[200px] text-center mx-auto'
                  onClick={generateChartData}
                  disabled={
                    getCourseCompletionBarChartLoading ||
                    getCohortCompletionBarChartLoading ||
                    getReportCompletionDataLoading 
                  }
                  style={{
                    opacity: (
                      getCourseCompletionBarChartLoading ||
                      getCohortCompletionBarChartLoading ||
                      getReportCompletionDataLoading
                    ) ? 0.5 : 1
                  }}
                >
                   {
                      getCourseCompletionBarChartLoading ||
                      getReportCompletionDataLoading ||
                      getCohortCompletionBarChartLoading ?
                      'Loading...' : activeWidget === 'charts' ? 'Generate Chart' : 'Add Data'
                   }
                </button>
        </section>
        {/* {
          activeWidget === 'data' &&
           <DataWidget
              dataCards={dataCards}
              setDataCards={setDataCards}
              activeWidget={activeWidget}
              setActiveWidget={setActiveWidget!}
              activeElementId={activeElementId}
              activePageId={activePageId}
              elementSetter={elementSetter}
              pageStructure={pageStructure}
              setPageStructure={setPageStructure}
            />
        } */}
        {
          activeWidget === 'charts' &&
            <section className='pt-3'>
               <ChartsWidget 
                tableData={tableData}
                setTableData={setTableData}
                activeBarchart={activeBarchart}
                setActiveBarchart={setActiveBarchart}
                stackedBarChartTableData={stackedBarChartTableData}
                setStackedBarChartTableData={setStackedBarChartTableData}
                progressChartTableData={progressChartTableData}
                setProgressChartTableData={setProgressChartTableData}
                activePieChart={activePieChart}
                setActivePieChart={setActivePieChart}
                pieChartTableData={pieChartTableData}
                setPieChartTableData={setPieChartTableData}
                activeOtherChart={activeOtherChart}
                setActiveOtherChart={setActiveOtherChart}
                otherChartTableData={otherChartTableData}
                setOtherChartTableData={setOtherChartTableData}
                dataCards={dataCards}
                setDataCards={setDataCards}
                activePageId={activePageId!}
                elementSetter={elementSetter}
                pageStructure={pageStructure}
                setPageStructure={setPageStructure}
                activeElementId={activeElementId}
                activeChart={activeChart}
                activeTab={activeTab}
                barChartColor={barChartColor}
                barChartColorType={barChartColorType}
                barChartFontFamily={barChartFontFamily}
                barChartFontStyle={barChartFontStyle}
                barChartTitle={barChartTitle}
                legendPosition={legendPosition}
                otherChartBackgroundColor={otherChartBackgroundColor}
                otherChartColor={otherChartColor}
                otherChartDotColor={otherChartDotColor}
                otherChartFontFamily={otherChartFontFamily}
                otherChartFontStyle={otherChartFontStyle}
                otherChartLineColor={otherChartLineColor}
                otherChartTitle={otherChartTitle}
                pieChartColor={pieChartColor}
                pieChartColorType={pieChartColorType}
                pieChartFontFamily={pieChartFontFamily}
                pieChartFontStyle={pieChartFontStyle}
                pieChartLegendPosition={pieChartLegendPosition}
                pieChartTitle={pieChartTitle}
                progressChartColor={progressChartColor}
                progressChartFontFamily={progressChartFontFamily}
                progressChartFontStyle={progressChartFontStyle}
                pyramidChartColor={pyramidChartColor}
                progressChartTitle={progressChartTitle}
                pyramidChartTitle={pyramidChartTitle}
                setActiveChart={setActiveChart}
                setActiveTab={setActiveTab}
                setBarChartColorType={setBarChartColorType}
                setBarChartTitle={setBarChartTitle}
                setBarchartFontFamily={setBarchartFontFamily}
                setBarchartFontStyle={setBarchartFontStyle}
                setLegendPosition={setLegendPosition}
                setOtherChartColor={setOtherChartColor}
                setOtherChartDotColor={setOtherChartDotColor}
                setOtherChartBackgroundColor={setOtherChartBackgroundColor}
                setOtherChartFontFamily={setOtherChartFontFamily}
                setOtherChartFontStyle={setOtherChartFontStyle}
                setOtherChartLineColor={setOtherChartLineColor}
                setOtherChartTitle={setOtherChartTitle}
                setPieChartColor={setPieChartColor}
                setPieChartColorType={setPieChartColorType}
                setPieChartFontFamily={setPieChartFontFamily}
                setPieChartFontStyle={setPieChartFontStyle}
                setPieChartLegendPosition={setPieChartLegendPosition}
                setProgressChartColor={setProgressChartColor}
                setProgressChartFontFamily={setProgressChartFontFamily}
                setPieChartTitle={setPieChartTitle}
                setProgressChartTitle={setProgressChartTitle}
                setProgressChartFontStyle={setProgressChartFontStyle}
                setPyramidChartColor={setPyramidChartColor}
                setPyramidChartTitle={setPyramidChartTitle}
                setShowBarChartLegend={setShowBarChartLegend}
                setShowBarChartTitle={setShowBarChartTitle}
                setShowOtherChartTitle={setShowOtherChartTitle}
                setShowPieChartLegend={setShowPieChartLegend}
                setShowPieChartTitle={setShowPieChartTitle}
                setShowStackedBarChartLegend={setShowStackedBarChartLegend}
                setShowStackedBarChartTitle={setShowStackedBarChartTitle}
                setBarChartColor={setBarChartColor}
                stackedBarChartFontStyle={stackedBarChartFontStyle}
                setStackedBarChartFontStyle={setStackedBarChartFontStyle}
                showBarChartLegend={showBarChartLegend}
                showBarChartTitle={showBarChartTitle}
                showPieChartLegend={showPieChartLegend}
                showPieChartTitle={showPieChartTitle}
                showStackedBarChartLegend={showStackedBarChartLegend}
                showStackedBarChartTitle={showStackedBarChartTitle}
                setShowProgressChartTitle={setShowProgressChartTitle}
                showProgressChartTitle={showProgressChartTitle}
                setStackedBarChartColor={setStackedBarChartColor}
                setShowYAxisLabel={setShowYAxisLabel}
                setStackedBarChartColorType={setStackedBarChartColorType}
                setStackedBarChartTitle={setStackedBarChartTitle}
                setStackedBarChartFontFamily={setStackedBarChartFontFamily}
                setShowXAxisLabel={setShowXAxisLabel}
                setStackedLegendPosition={setStackedLegendPosition}
                setXAxisLabel={setXAxisLabel}
                showXAxisLabel={showXAxisLabel}
                showYAxisLabel={showYAxisLabel}
                showOtherChartTitle={showOtherChartTitle}
                stackedBarChartColor={stackedBarChartColor}
                stackedBarChartColorType={stackedBarChartColorType}
                stackedBarChartFontFamily={stackedBarChartFontFamily}
                stackedBarChartTitle={stackedBarChartTitle}
                stackedLegendPosition={stackedLegendPosition}
                setYAxisLabel={setYAxisLabel}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                showDhoughntuCenterText={showDhoughntuCenterText}
                setShowDhoughntuCenterText={setShowDhoughntuCenterText}
                centerText ={centerText}
                setCenterText={setCenterText}
            />
            </section>
        }
       </section>
    </div>
  )
}

export default Data