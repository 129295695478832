import React, { useEffect, useState } from 'react'
import ScreenNavigator from './components/ScreenNavigator'
import SelectTeam from './components/SelectTeam'
import { Link, useLocation } from 'react-router-dom'
import TeamSetting from './components/TeamSetting'
import { v4 } from 'uuid'

const CreateTeam = () => {

 const [isCreated, setIsCreated] = useState(false)
 const [status, setStatus] = useState(true)
 const [ activeScreen, setActiveScreen] = useState(1)
 const [selectedCohort, setSelectedCohort] = useState(null)
 const [selectedTeams, setSelectedTeams] = useState([])
 const [teams, setTeams] = useState([ { id: v4(), name: '' } ])

 const location = useLocation();

  useEffect(() => {
    document.querySelectorAll('*').forEach( element => {
       element.scroll({top: 0, left: 0, behavior: 'instant'})
    })
  }, [activeScreen]);                     

  return (
    
    <div>
        <h5 className='text-md flex justify-between'>
            Team Management
            <Link to={-1} className='text-white bg-customBlue text-sm px-8 py-2 rounded-full bi bi-arrow-left'>
                &nbsp;Back
            </Link>
        </h5>
        <header>
            <ScreenNavigator
                activeScreen={activeScreen}
                set1Validated={isCreated}
                setIsCreated={setIsCreated}
                setActiveScreen={setActiveScreen}
                title1={'Select Team'}
                title2={'Team Setting'}
            />
        </header>
         { activeScreen === 1 ? 
           <SelectTeam 
             status={status} setStatus={setStatus}
             setIsCreated={setIsCreated}
             setSelectedCohort={setSelectedCohort}
             selectedCohort={selectedCohort}
             setActiveScreen={setActiveScreen}
             teams={teams}
             setTeams={setTeams}
             selectedTeams={selectedTeams}
             setSelectedTeams={setSelectedTeams}
           /> 
        : null }
         { 
            activeScreen === 2 ? 
              <TeamSetting selectedTeams={selectedTeams} selectedCohort={selectedCohort} /> 
            : null 
         }
    </div>
  )
}

export default CreateTeam