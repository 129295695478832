import React from 'react'
import { tableData } from '../../CreateReport'

type BarchartInputTableProps = {
    tableData: tableData    
    setTableData: React.Dispatch<React.SetStateAction<tableData>>
}

const BarchartInputTable: React.FC<BarchartInputTableProps> = ({tableData, setTableData}) : JSX.Element => {
    const handleInputChange = (rowIndex: number, colIndex: number, value: string) => {
        const newBody = tableData.body.map((row, rIndex) => {
            if (rIndex === rowIndex) {
                const newRow = [...row];
                newRow[colIndex] = value;
                return newRow;
            }
            return row;
        });

        setTableData({
            ...tableData,
            body: newBody
        });
    };

    const handleDeleteColumn = (colIndex: number) => {
        if (colIndex === 0) return;

        const newHeaders = tableData.headers.filter((_, i) => i !== colIndex);
        const newBody = tableData.body.map(row => row.filter((_, i) => i !== colIndex));

        setTableData({
            headers: newHeaders,
            body: newBody
        });
    };

    return (
      <>
        <div className='bg-white border-t border-slate-200 rounded-md shadow-sm'>
            <div className="flex flex-col overflow-x-auto">
            <div className="sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 sm:px-4 lg:px-8">
                <div className="overflow-x-auto">
                    <table className="min-w-full text-start text-[11px] font-light text-surface dark:text-white border border-slate-200">
                    <thead className="border-b bg-slate-50 border-neutral-200 font-medium dark:border-white/10">
                        <tr>
                            {tableData.headers.map((header, colIndex) => (
                                <th key={`header-${colIndex}`} className="whitespace-nowrap px-4 py-2 font-medium text-[11px] border border-slate-200 group relative">
                                    <span className="block w-full relative z-10">{header}</span>
                                    {colIndex > 2 && (
                                        <>
                                            <i className="bi bi-trash absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hidden group-hover:block cursor-pointer text-white z-20"
                                                onClick={() => handleDeleteColumn(colIndex)}
                                            />
                                            <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-60 transition-opacity duration-200" />
                                        </>
                                    )}
                                </th>
                            ))}
                         </tr>
                    </thead>
                    <tbody>
                        {tableData.body.map((row, rowIndex) => (
                            <tr key={`row-${rowIndex}`}>
                                {row.map((cell, colIndex) => (
                                    <td key={`cell-${rowIndex}-${colIndex}`} 
                                        className="whitespace-nowrap p-0 font-medium text-[11px] border border-slate-200 group relative"
                                    >
                                        {(colIndex === 0) ? (
                                            <>
                                                <span className="block w-full text-center relative z-10">{cell}</span>
                                                {
                                                 rowIndex > 0 ?
                                                 <i className="bi bi-trash absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 hidden group-hover:block cursor-pointer text-white z-20"
                                                    onClick={() => setTableData({
                                                        ...tableData,
                                                        body: tableData.body.filter((_, i) => i !== rowIndex)
                                                    })}
                                                /> : null}
                                                {rowIndex > 0 ?
                                                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-60 transition-opacity duration-200" />
                                                : null}
                                            </>
                                        ) : (
                                            <input 
                                                type="text" 
                                                value={cell} 
                                                onChange={(e) => handleInputChange(rowIndex, colIndex, e.target.value)}
                                                className="w-full h-full px-4 py-2 font-light focus:outline-none text-[11px]" 
                                            />
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        { (
            <section className='mt-2 flex justify-end'>
                <button 
                    className='btn-light bi bi-plus-circle pt-2 text-[10px] pb-2 bg-slate-200'
                    onClick={() => {
                    // Create empty strings based on number of series (headers - 2)
                    const numSeries = tableData.headers.length - 2;
                    const emptyValues = Array(numSeries).fill('');
                    
                    setTableData({
                        ...tableData,
                        body: [...tableData.body, [
                            tableData.body.length + 1, 'Label', ...emptyValues
                        ]]
                    })
                    }}
                >
                    &nbsp;Add Row
                </button>
                <button 
                    className='btn-light hidden bi bi-plus-circle pt-2 text-[10px] ml-3 pb-2 bg-slate-200'
                    onClick={() => {
                    setTableData({
                        ...tableData,
                        headers: [...tableData.headers, `Series ${(tableData.headers.length - 2 ) + 1}`],
                        body: tableData.body.map(row => [...row, ''])
                    })
                    }}
                >
                    &nbsp;Add Series
                </button>
            </section>)
            }
        </>
    )
}

export default BarchartInputTable