import React from 'react'
import BadgeIcon from '../../../../../../assets/Badge.png';
import BadgeIconOrange from '../../../../../../assets/Bagde (5).png';
import BadgeIconGreen from '../../../../../../assets/Badge (1).png';
import OverviewCard from '../../../../../../components/Cards/OverviewCards';
import { useQuery } from 'react-query';
import { cohorts } from '../../../../../../api/cohort';
// import { cohorts } from '../../../../../../api/cohort';

const Summary: React.FC = () => {
 
    const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id
    const { data: cohortSummary, isLoading: coursesSummaryLoading } = useQuery(
    ["get cohort summary"],
    () => {
        return cohorts.getCohortSummary(orgId);
    },
    {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data,
    }
    );

  return (
    <div className='grid grid-cols-1 pt-2 pb-4 gap-7 md:grid-cols-4'>
        <OverviewCard 
           title='Total Cohort'
           total={cohortSummary?.numberOfCohorts}
           iconPath={BadgeIcon}
        />
        <OverviewCard 
           title='Total Courses'
           total={cohortSummary?.numberOfCourses}
           iconPath={BadgeIconOrange}
        />
        <OverviewCard 
           title='Total Learners'
           total={cohortSummary?.numberOfLearners}
           iconPath={BadgeIconGreen}
        />
        <OverviewCard 
           title='Total Learning Path'
           total={cohortSummary?.numberOfLearningPaths }
           iconPath={BadgeIcon}
        />
    </div>
  )
}

export default Summary