import React, { useState } from 'react'


interface CollapsibleSectionProps {
  title: string;
  content: React.ReactNode;
}
const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="bg-gray-100 rounded-md shadow-sm my-4 p-4">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-semibold text-lg">{title}</h3>
        <button
          className={`transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        >
         <i className="bi bi-chevron-down"></i>
        </button>
      </div>
      {isOpen && <div className="mt-3">{content}</div>}
    </div>
  );
};
const Contents = () => {

  const handleDragStart = (e: React.DragEvent, styleNumber: any) => {
    const contentData = {
      type: 'content-style',
      data: {  // Wrap in data object
        styleNumber: styleNumber,
        items: styleNumber === 1 ? [
          { title: '1.0 Content Title Jamiu', level: 0, pageNumber: 1 }, 
          { title: '1.1.1 Content Title', level: 1, pageNumber: 1 },
          { title: '1.2 Content Title', level: 0, pageNumber: 1 },
          { title: '1.3 Content Title', level: 0, pageNumber: 1 }
        ] : styleNumber === 2 ? [
          { title: '01 Content Title', pageNumber: 1 },
          { title: '02 Content Title', pageNumber: 1 },
          { title: '03 Content Title', pageNumber: 1 },
          { title: '04 Content Title', pageNumber: 1 }
        ] : [
          { title: '1.0 Content Title', level: 0, pageNumber: 1 },
          { title: '1.1.1 Content Title', level: 1, pageNumber: 1 },
          { title: '1.2 Content Title', level: 0, pageNumber: 1 },
          { title: '1.3 Content Title', level: 0, pageNumber: 1 }
        ]
      }
    };

    console.log('Dragging content:', contentData); // Debug log
    e.dataTransfer.setData('text/plain', JSON.stringify(contentData));
  };

  return (
    <div className="max-w-md mx-auto p-6">
    {/* Style 1 */}
    <CollapsibleSection
      title="Style 1"
      content={
        <div className="pl-4"
        draggable
        onDragStart={(e) => handleDragStart(e, 1)}>
          <h4 className="font-semibold mb-2">Content Title</h4>
          <ul className="space-y-2">
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.0 Content Title</span>
            </li>
            <li className="flex items-center pl-4">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.1.1 Content Title</span>
            </li>
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.2 Content Title</span>
            </li>
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.3 Content Title</span>
            </li>
          </ul>
        </div>
      }
    />

    {/* Style 2 */}
    <CollapsibleSection
      title="Style 2"
      content={
        <div className="pl-4"
        draggable
        onDragStart={(e) => handleDragStart(e, 2)}>
          <h4 className="font-semibold mb-2">Content Title</h4>
          <ul className="space-y-2">
            <li className="border-b pb-2">01 Content Title</li>
            <li className="border-b pb-2">02 Content Title</li>
            <li className="border-b pb-2">03 Content Title</li>
            <li className="border-b pb-2">04 Content Title</li>
          </ul>
        </div>
      }
    />

    {/* Style 3 */}
    <CollapsibleSection
      title="Style 3"
      content={
        <div className="pl-4"
        draggable
        onDragStart={(e) => handleDragStart(e, 3)}>
          <h4 className="font-semibold mb-2">Content Title</h4>
          <ul className="space-y-2">
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.0 Content Title</span>
            </li>
            <li className="flex items-center pl-4">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.1.1 Content Title</span>
            </li>
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.2 Content Title</span>
            </li>
            <li className="flex items-center">
              <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
              <span className="ml-2">1.3 Content Title</span>
            </li>
          </ul>
        </div>
      }
    />
  </div>
  )
}

export default Contents