import React from "react";

const shapesList = [
  {
    id: 1,
    type: "rect",
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
  },
  { id: 3, type: "ellipse", clipPath: "ellipse(50% 50%)" },
  { id: 4, type: "triangle", clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)" },
  {
    id: 5,
    type: "diamond",
    clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
  },
  {
    id: 23,
    type: "heart",
    clipPath:
      "polygon(30% 0%, 50% 15%, 70% 0%, 90% 10%, 100% 35%, 80% 70%, 50% 100%, 20% 70%, 0% 35%, 10% 10%)",
  },
  {
    id: 7,
    type: "trapezoid",
    clipPath: "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)",
  },
  {
    id: 8,
    type: "pentagon",
    clipPath: "polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)",
  },
  {
    id: 9,
    type: "hexagon",
    clipPath: "polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)",
  },
  {
    id: 10,
    type: "octagon",
    clipPath:
      "polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)",
  },
  {
    id: 11,
    type: "star5",
    clipPath:
      "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
  },
  {
    id: 14,
    type: "upArrow",
    clipPath:
      "polygon(50% 0%, 100% 50%, 75% 50%, 75% 100%, 25% 100%, 25% 50%, 0% 50%)",
  },
  {
    id: 14,
    type: "downArrow",
    clipPath:
      "polygon(19.1% 66.3%, 32.2% 64.8%, 32.1% 7.5%, 44.9% 7%, 44.3% 63.5%, 57.7% 66.8%, 38.4% 100%)",
  },
  {
    id: 19,
    type: "chevron",
    clipPath: "polygon(0% 0%, 50% 0%, 100% 50%, 50% 100%, 0% 100%, 50% 50%)",
  },
  {
    id: 20,
    type: "frame",
    clipPath:
      "polygon(10% 10%, 90% 10%, 90% 90%, 10% 90%, 10% 10%, 20% 20%, 20% 80%, 80% 80%, 80% 20%, 20% 20%)",
  },
  { id: 21, type: "cloud", clipPath: "ellipse(50% 50%)" },
  {
    id: 26,
    type: "plus",
    clipPath:
      "polygon(35% 0%,65% 0%,65% 35%,100% 35%,100% 60%,65% 60%,65% 100%,35% 100%,35% 60%,0% 60%,0% 35%,35% 35%)",
  },
  {
    id: 22,
    type: "arc",
    clipPath:
      "polygon(12.2% 14.3%, 32.2% 29.5%, 12.4% 34%, 12.3% 78.3%, 29.8% 93.8%, 4.2% 94.8%, 0% 77.8%, 0% 18%)",
  },
  {
    id: 16,
    type: "decagon",
    clipPath:
      "polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)",
  },
  

  {
    id: 24,
    type: "lightningBolt",
    clipPath: "polygon(25% 0%,70% 0%,40% 35%,95% 35%,20% 100%,40% 55%,0% 55%)",
  },
  {
    id: 6,
    type: "parallelogram",
    clipPath: "polygon(20% 0%, 100% 0%, 80% 100%, 0% 100%)",
  },
  
];

const DraggableShape = ({ shape, onDragStart }) => (
  <div
    draggable
    onDragStart={(e) => onDragStart(e, shape)}
    className="p-4 cursor-pointer"
    title={shape.type} 
  >
    <div
      className="w-8 h-8 bg-gray-300"
      style={{
        clipPath: shape.clipPath || "none",
        backgroundColor: "#ccc",
      }}
    />

  </div>
);

const Shapes = ({
}) => {
  const handleDragStart = (e, shape) => {
    e.dataTransfer.setData(
      "text/plain",
      JSON.stringify({
        type: "shape",
        shapeType: shape.type,
        clipPath: shape.clipPath,
      })
    );
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Shapes</h2>
      <div className="grid grid-cols-3 gap-4 mb-4">
        {shapesList.map((shape) => (
          <DraggableShape
            key={shape.id}
            shape={shape}
            onDragStart={handleDragStart}
          />
        ))}
      </div>
    </div>
  );
};

export default Shapes;
