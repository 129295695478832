import React from 'react'
import CustomDropdown from '../../../../../../components/dropdown/CustomDropdown'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from 'react-query'
import { errorResponse } from '../../../../../../components/utils/errorResponse'
import { api as learningPath } from '../../../../../../api/learningPath'  
import addGroupImage from '../../../../../../assets/Add Group.png'
import { useNavigate } from 'react-router-dom'

const AddLearningPath = ({ setCurrentStep, selectedJourney, setSelectedJourney, cohortId }: any) => {

   const navigate = useNavigate()
   const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id
   const { data, isLoading, } = useQuery(
    ["view journeys"],
    () => {
      return learningPath.getLearningPath({ perPage: 10000, currentPage:1, org_id: orgId, search: '' });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:true,
      select: (data) => data?.data,
    })

    const addCohortMutation: any = useMutation(learningPath.assignCohorts, {
      onSuccess: (data) => {
          toast.remove()
          toast.success('Learning Path Added  Successfully!')
         // setCurrentStep(6)
         setTimeout( () => {
          navigate('/admin/cohort/')
         }, 1000)
      },
      onError: error => errorResponse(error),
      onMutate: () => {
          toast.loading('Loading...')
      }
    })

 const addLearningPath = () => {
    if(selectedJourney === null) toast.error('Error! Select Learning Path To Proceed.')
    else {
      const payload = {
          org_id: orgId, journey_id: selectedJourney?.value,
          payload: {
            allCohorts: false,
            newCohortIds: [cohortId]
          }
      }
      addCohortMutation.mutate(payload)
    }
 }

  return (
    <>   
           <section className='border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8'>
             <div className='w-[40%] py-7 flex items-center justify-center'>
                 <img src={addGroupImage} className='w-[260px] h-[260px]' alt="add group" />
             </div>
               <section className='w-[52%]'>
               <div className='rounded-e-lg bg-white h-full p-5 px-8'>
                  <h5 className='text-xl font-semibold'>
                    Add Learning Path
                  </h5>
                  <p className='text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-3'>
                      Select learning path for your cohort
                  </p>
                  <label className='text-xs pb-2'> Select Learning Path </label>
                 <CustomDropdown
                    value={selectedJourney}
                    options={ isLoading ? [] :
                      data?.length ? 
                       data?.map( (journey: any) => {
                          return { label: journey?.name, value: journey?.id }
                       } )
                      : []
                    }
                    onChange={ (value: any) => setSelectedJourney(value) }
                    placeholder={'Select Learning Path'}
                    //multiSelect
                    onBlur={null}
                    className={'bg-primaryGray select-custom'}
                    name={'path'}
                    width={'w-full mt-2'}
                 />
                  <div className='flex mt-8'>
                      <button 
                        className={'w-full text-center btn bg-primary-blue'} 
                        onClick={ addLearningPath }
                      >
                       Add Learning Paths
                    </button>  
                  </div>
             </div>
            </section> 
      </section>
    </>
  )
}

export default AddLearningPath