import { chartType } from "../components/ChartsWidget";
import columnBarChartImg from '../../../../../../assets/column bar chart.png';
import columnStackedBarChartImg from '../../../../../../assets/stacked column bar chart.png';
import horizontalBarChartImg from '../../../../../../assets/horizontal bar chart.png';
import horizontalStackedBarChartImg from '../../../../../../assets/horizontal stacked.png';
import pieChartImg from '../../../../../../assets/pie chart.png';
import donutChartImg from '../../../../../../assets/donut.png';
import donutHalfChartImg from '../../../../../../assets/half donut.png';
import lineChartImg from '../../../../../../assets/line.png';
import progressChartImg from '../../../../../../assets/progress bar.png';
import dotChartImg from '../../../../../../assets/dots.png';
import areaChartImg from '../../../../../../assets/area-chart.png';
import pyramidChartImg from '../../../../../../assets/pyramid.png';
import { tableData, TabType } from "../CreateReport";

export const charts: chartType = {
  barchart: [
    {
      id: '1',
      title: 'Column Bar Chart',
      image: columnBarChartImg,
    },
    {
      id: '2',
      title: 'Stacked Column Bar Chart',
      image: columnStackedBarChartImg,
    },
    {
      id: '3',
      title: 'Horizontal Bar Chart',
      image: horizontalBarChartImg,
    },
    {
      id: '4',
      title: 'Stacked Horizontal Bar Chart',
      image: horizontalStackedBarChartImg,
    },
    {
      id: '5',
      title: 'Progress Chart',
      image: progressChartImg,
    },
  ],
  piechart: [
    {
      id: '1',
      title: 'Pie Chart',
      image: pieChartImg,
    },
    {
      id: '2',
      title: 'Donut Chart',
      image: donutChartImg,
    },
    // {
    //   id: '3',
    //   title: 'Half Donut Chart',
    //   image: donutHalfChartImg,
    // },
  ],
  otherchart: [
    {
      id: '1',
      title: 'Dot Chart',
      image: dotChartImg,
    },
    {
      id: '2',
      title: 'Area Chart',
      image: areaChartImg,
    },
    {
      id: '3',
      title: 'Pyramid Chart',
      image: pyramidChartImg,
    },
    {
      id: '4',
      title: 'Line Chart',
      image: lineChartImg,
    },
  ],
};

export const navTabs: TabType[] = [
  { icon: 'bi-fonts', text: 'Text' },
  { icon: 'bi-back', text: 'Graphics' },
  { icon: 'bi-noise-reduction', text: 'Data' },
  { icon: 'bi-image', text: 'Photos' },
  { icon: 'bi-border-width', text: 'Contents' },
  { icon: 'bi-fullscreen', text: 'Brand' },
];

export const initDataTable: tableData = {
  body: [
    [1, 'Label 1', ''],
    [2, 'Label 2', ''],
    [3, 'Label 3', ''],
  ],
  headers: ['S/N', 'Labels', 'Values'],
};

export const initStackedBarChartDataTable: tableData = {
  body: [
    [1, 'Label 1', '', '', ''],
    [2, 'Label 2', '', '', ''],
    [3, 'Label 3', '', '', ''],
  ],
  headers: ['S/N', 'Labels', 'Series 1', 'Series 2', 'Series 3'],
};

export const initProgressChartDataTable: tableData = {
  body: [
    [1, 'Label 1', ''],
  ],
  headers: ['S/N', 'Label', 'Value'],
};

export const initPieChartDataTable: tableData = {
  body: [
    [1, 'Label 1', '',],
    [2, 'Label 2', '',],
    [3, 'Label 3', '',],
  ],
  headers: ['S/N', 'Labels', 'Values'],
};



