import React from 'react'

import Switch from 'react-switch';
import { useQuery } from 'react-query';
import { api } from '../../../../../../../api/courses';
import { api as journeyApi } from '../../../../../../../api/learningPath';
import CompanyRoleButton from '../../../../../../Auth/components/CompanyRoleButton';
import CustomDropdown from '../../../../../../../components/dropdown/CustomDropdown';

const FeedbackSetting = ({ 
    isCourse, setIsCourse, afterCourses, setAfterCourses,
    isMandatory, setIsMandatory, afterAssessment, setAfterAssessment,
    journeyId, setJourneyId, courseId, setCourseId,
 }) => {

    const user = JSON.parse(sessionStorage.getItem("user"));
    const { data: courses, isLoading, } = useQuery(
        ["get courses", { perPage: 10000, currentPage: 1 }],
        ({ queryKey }) => {
          return api.getCourses(queryKey[1]);
        },
        {
          cacheTime: 100,
          staleTime: 100,
          refetchOnWindowFocus: true,
          select: (data) => data?.data,
        }
      );

    const {
        data: journeys,
        isLoading: isJourneyLoading,
    } = useQuery(
        ["get journeys", { search: '', perPage: 10000, currentPage: 1 }],
        ({ queryKey }) => {
        return journeyApi.getLearningPath({ ...queryKey[1], org_id: user?.org?.id });
        },
        {
        cacheTime: 100,
        staleTime: 100,
        refetchOnWindowFocus: true,
        select: (data) => data?.data,
        }
    );

  return (
    <div className='p-5 w-2/3 mx-auto rounded-lg bg-white mt-12 shadow-xl'>
        <section className='rounded-lg bg-white min-[500px]:w-[70%]'>
             <h3 className='flex text-sm items-center'> 
               Add Feedback to: &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                   text={'Course'} 
                   active={isCourse}
                   onClick={ () => setIsCourse(true) }
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                    text={'Learning Path'}
                    active={!isCourse}
                    onClick={ () => setIsCourse(false) }
                />
             </h3>
        </section>
        <section className='mt-5'>
             {isCourse ? 
              <CustomDropdown 
                 options={ (isLoading || !courses?.length) ? [] : 
                    courses?.map( course => {
                        return { label: course?.name, value: course?.id }
                    } )
                 }
                 value={courseId}
                 width={'min-[500px]:w-[500px]'}
                 placeholder={'Select Course'}
                 onChange={ input => {
                   setCourseId(input)
                 }}
            />
            :
            <CustomDropdown 
                 options={(isJourneyLoading || !journeys?.length) ? [] : 
                    journeys?.map( journey => {
                        return { label: journey?.name, value: journey?.id }
                    } )
                 }
                 value={journeyId}
                 onChange={ (input) => setJourneyId(input) }
                 width={'min-[500px]:w-[500px]'}
                 placeholder={'Select Learning Path'}
            />
          }
        </section>
        { !isCourse &&
         <h3 className='flex text-sm mt-5 items-center'>
               Show when all courses are complete &nbsp;&nbsp;&nbsp;&nbsp;
                <Switch
                    height={20}
                    width={40}
                    handleDiameter={18}
                    checked={afterCourses}
                    onChange={() => {
                        setAfterCourses(!afterCourses)
                      }
                    }
                />
             </h3>}
        {
         isCourse &&
         <section className='rounded-lg bg-white mt-5'>
             <h3 className='flex text-sm items-center'>
               Show Feedback: &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                   text={'Before Assessment'} 
                   active={!afterAssessment}
                   onClick={ () => setAfterAssessment(false) }
                   width={'w-[160px]'}
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                    text={'After Assessment'}
                    active={afterAssessment}
                    onClick={ () => setAfterAssessment(true) }
                    width={'w-[160px]'}
                />
             </h3>
        </section>}
        <h3 className='flex text-sm mt-5 items-center'>
                Is feedback mandatory &nbsp;&nbsp;&nbsp;&nbsp;
                <Switch
                    height={20}
                    width={40}
                    handleDiameter={18}
                    checked={isMandatory}
                    onChange={() => {
                         setIsMandatory(!isMandatory)
                      }
                    }
                />
             </h3>
    </div>
  )
}

export default FeedbackSetting