import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, Plugin } from 'chart.js/auto';
import { text } from '@fortawesome/fontawesome-svg-core';
import { v4 } from 'uuid';

interface ChartProps {
  config: any;
  width: number;
  height: number;
  isDoughnut: boolean;
  text: string
}

const ChartComponent: React.FC<ChartProps> = ({ config, width, height, isDoughnut, text }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<ChartJS | null>(null);

  // Custom plugin to add text to the center of a Doughnut chart
  const centerTextPlugin = (text: string): Plugin<'doughnut'> => {
    return {
      id: v4(),
    beforeDraw: (chart) => {
      const { ctx, chartArea } = chart;
      if (!ctx || !chartArea) return;
      const fontSize = (chartArea.height / 10).toFixed(2);

      ctx.save();
      ctx.font = `${fontSize}px sans-serif`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      ctx.fillStyle = '#000'; // Set text color

      const x = chartArea.left + (chartArea.width / 2);
      const y = chartArea.top + (chartArea.height / 2);
      ctx.fillText(text, x, y);
      ctx.restore();
    },
  };
 }

  useEffect(() => {
    if (!chartRef.current) return;

    // Destroy existing chart instance before creating a new one
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');
    if (ctx) {
      chartInstance.current = new ChartJS(ctx, {
        ...config,
        options: {
          ...config.options,
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          text,
        },
        plugins: isDoughnut ? [centerTextPlugin(text)] : config?.plugins || [] , // Add custom plugin here
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [config, text]);

  return (
    <div style={{ width: `${width}px`, height: `${height}px`, position: 'relative' }}>
      <canvas 
        ref={chartRef}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default ChartComponent;
