import React, { useState } from 'react';
import Switch from 'react-switch';
import CompanyRoleButton from '../../../../../../../Auth/components/CompanyRoleButton';
import CustomDropdown from './../../../../../../../../components/dropdown/CustomDropdown';
import CreateTeams from './CreateTeams';
import Button from '../../../../../../../../components/Buttons/Button';
import { api } from '../../../../../../../../api/learningPath';
import { useQuery, useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { api as teamsApi } from '../../../../../../../../api/teams';

const SelectTeam = ({ 
    status, setStatus, setIsCreated, selectedCohort, setSelectedTeams, selectedTeams,
    setSelectedCohort, setActiveScreen, teams, setTeams,
  }) => {

  const [enableLeaderBoard, setEnableLeaderBoard] = useState(true)
  const [isCreateTeam, setIsCreateTeam] = useState(true)

  const orgId = JSON.parse(sessionStorage.getItem('user'))?.org?.id
  const { data: cohorts, isLoading, refetch } = useQuery(
    ["cohort", { id: orgId, perPage: 10000, currentPage: 1 }],
    ({ queryKey }) => {
      return api.getCohort(queryKey[1]);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      select: (data) => data?.data,
    }
  );

  const addTeamsMutation = useMutation(teamsApi.createOrganisationTeams, {
    onSuccess: (data) => {
        toast.remove()
        toast.success('Teams Created successfully!')
        setSelectedTeams(data?.data)
        setIsCreated(true)
        setActiveScreen(2)
    },
    onError: error => {
        toast.remove()
        if(error.response.status === 500) toast.error(error.response.statusText)
        else if(error.response.status === 400) toast.error(error?.response?.data?.message)
        else{
           if(error?.response?.data?.errors?.length > 1){
            error?.response?.data?.errors?.map( error => {
              toast?.error(error?.message)
            })
           }
           else toast.error(error?.response?.data?.errors[0]?.message)
        }
    },
    onMutate: () => {
        toast.loading('Loading...')
    }
  })

  const createTeam = () => {
    const hasEmptyName = teams.some( team =>  team.name === '');
    if(hasEmptyName){
      toast.error('Error! Complete Adding Name To Your Teams')
    }
    else if(!selectedCohort){
       toast.error('Error! Select Cohort To Procced')
    }
    else addTeamsMutation.mutate({ orgId, payload: {
       cohortId: selectedCohort?.value,
       teams: teams?.map( team => {
         return { name: team.name, leaderboardEnabled: enableLeaderBoard }
       })
    }})
  }

  return (
    <div className='pl-5'>  
        <h2 className='mt-5'>Step 1:</h2>
        <section className='p-5 py-8 rounded-lg bg-white mt-5 min-[500px]:w-[70%]'>
             <h3 className='flex text-sm items-center'>
                Team Status &nbsp;&nbsp;&nbsp;&nbsp;
                <Switch
                    //value={enableAffiliate}
                    height={20}
                    width={40}
                    handleDiameter={18}
                    checked={status}
                    onChange={() =>
                      setStatus(!status)
                    }
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {status ? 'Active' : 'Inactive'}
             </h3>
        </section>
        <section className='p-5 mt-5 rounded-lg bg-white min-[500px]:w-[70%]'>
             <h3 className='flex text-sm items-center'>
                Team Leader Board &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                   text={'Enable'} 
                   active={enableLeaderBoard}
                   onClick={ () => setEnableLeaderBoard(true) }
                /> 
                 &nbsp;&nbsp;&nbsp;&nbsp;
                <CompanyRoleButton 
                    text={'Disable'}
                    active={!enableLeaderBoard}
                    onClick={ () => setEnableLeaderBoard(false) }
                />
             </h3>
        </section>
        <section className='p-5 mt-5 rounded-lg bg-white min-[500px]:w-[70%]'>
             <h3 className='flex text-sm items-center'>
                Select Cohort &nbsp;&nbsp;&nbsp;&nbsp;
                <CustomDropdown
                   placeholder={'Select Cohort'}
                   options={
                    isLoading ? [] :
                    cohorts?.map( cohort => {
                        return {
                            label: cohort?.cohort?.name,
                            value: cohort?.cohort?.id
                        }
                    } )
                   }
                   value={selectedCohort}
                   onChange={ value => {
                     setSelectedCohort(value)
                   }}
                   width={'w-[300px] ml-5'}
                 />
             </h3>
        </section>
        { /* isCreateTeam ?
            <section className='p-5 mb-16 mt-5 rounded-lg bg-white min-[500px]:w-[70%]'>
             <h3 className='flex text-sm items-center'>
                Available Categories &nbsp;&nbsp;&nbsp;&nbsp;
                 <CustomDropdown
                   placeholder={'Select Category'}
                   options={
                     []
                   }
                   width={'w-[300px] ml-5'}
                 />
             </h3>
          </section>
          : */
          <CreateTeams teams={teams} setTeams={setTeams} /> 
        }
        <div className='-mt-12 flex justify-end md:w-[70%]'>
            <Button 
                width={'w-[200px]'} bgColor={'bg-customBlue'}
                text={'Create Teams'}
                onClick={createTeam}
                disabled={addTeamsMutation.isLoading}
           />
        </div>
    </div>
  )
}

export default SelectTeam