import React from "react";
import { useBrandContext } from "../../hooks/useBrandContext";

const Brand = () => {
  const {
    showLogo,
    setShowLogo,
    logoPosition,
    setLogoPosition,
    headerVisible,
    setHeaderVisible,
    headerPosition,
    setHeaderPosition,
    footerVisible,
    setFooterVisible,
    footerPosition,
    setFooterPosition,
    pageNumberPosition,
    setPageNumberPosition,
    showPageNumber,
    setShowPageNumber,
    handleLogoChange,
    isLogoOpen,
    setLogoIsOpen,
    isHeaderOpen,
    setHeaderIsOpen,
    isFooterOpen,
    setFooterIsOpen,
    isPageOpen,
    setPageIsOpen,
    toggleDropdown,
    toggleHeaderDropdown,
    toggleFooterDropdown,
    togglePageDropdown,
    positions,
    headerText,
    setHeaderText,
    footerText,
    setFooterText,
  } = useBrandContext();

  return (
    <div className="">
      {/* <BrandLogoSettings /> */}
      {/* Logo Settings */}
      <div className="mb-4 border-b p-4 py-1">
        <div
          onClick={toggleDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-sm font-medium text-[#1D1F2C] mb-4">
            Brand Logo
          </h3>
          <i className="bi bi-chevron-down font-bold"></i>
        </div>
        {isLogoOpen && (
          <div className="mt-4">
            {/* Show Logo Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Brand Logo
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showLogo}
                  onChange={() => setShowLogo(!showLogo)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    showLogo ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={logoPosition}
                  onChange={(e) => setLogoPosition(e.target.value)}
                >
                  {positions?.map((pos: any) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Logo Upload */}
            <div>
              <label className="text-gray-600 font-medium mb-2 block">
                Upload Logo
              </label>
           
              <div className="items-center justify-center max-w-xl mx-auto">
                <label
                  className="flex justify-center w-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                  id="drop"
                  // for="file-input"
                >
                  <span className="flex flex-col items-center space-x-2 pt-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="text-[#FF9100] font-medium text-xs">Drag & drop your logo here</p>
                    <p className="text-[#8E8E8E] text-[8px] w-32 font-normal text-center">
                      Maximum 5MB in size. JPG, PNG, or GIF formats.
                    </p>
                    <label
                      // for="file-input"
                      className="my-5 cursor-pointer text-white bg-customBlue hover:bg-darkBlue font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                    >
                      Browse Files
                    </label>
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleLogoChange}
                    id="file-input"
                    className="mb-4 hidden"
                  />
                </label>
              </div>
              {/* {logoFile && (
        <img
          src={logoFile}
          alt="Uploaded Logo"
          className="mt-4 w-16 h-16 object-cover rounded-md border border-gray-300"
        />
      )} */}
            </div>
          </div>
        )}
      </div>

      {/* Header Settings */}
      <div className="mb-4 border-b p-4 py-1">
        <div
          onClick={toggleHeaderDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-sm font-medium text-[#1D1F2C] mb-2">Header</h3>
          <i className="bi bi-chevron-down"></i>
        </div>
        {isHeaderOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Title
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={headerVisible}
                  onChange={() => setHeaderVisible(!headerVisible)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    headerVisible ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={headerPosition}
                  onChange={(e) => setHeaderPosition(e.target.value)}
                >
                  {positions?.map((pos: any) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-4">
              {/* <label className="text-gray-600 font-medium mb-2 block">
                Header Text
              </label> */}
              <input
                type="text"
                value={headerText}
                onChange={(e) => setHeaderText(e.target.value)}
                placeholder="Add Description"
                className="w-full p-3 text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
            </div>
          </div>
        )}
      </div>

      {/* Footer Settings */}
      <div className="mb-4 border-b p-4 py-1">
        <div
          onClick={toggleFooterDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-sm font-medium text-[#1D1F2C] mb-2">Footer</h3>
          <i className="bi bi-chevron-down"></i>
        </div>
        {isFooterOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Title
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={footerVisible}
                  onChange={() => setFooterVisible(!footerVisible)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    footerVisible ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={footerPosition}
                  onChange={(e) => setFooterPosition(e.target.value)}
                >
                  {positions?.map((pos: any) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-4">
              {/* <label className="text-gray-600 font-medium mb-2 block">
                Footer Text
              </label> */}
              <input
                type="text"
                value={footerText}
                onChange={(e) => setFooterText(e.target.value)}
                placeholder="Add Description"
                className="w-full p-3 text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
            </div>
          </div>
        )}
      </div>

      {/* Page Number Settings */}
      <div className="mb-4 border-b p-4 py-1">
        <div
          onClick={togglePageDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-sm font-medium text-[#1D1F2C] mb-2">
            Page Number
          </h3>
          <i className="bi bi-chevron-down"></i>
        </div>
        {isPageOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Page number
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showPageNumber}
                  onChange={() => setShowPageNumber(!showPageNumber)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    showPageNumber ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={pageNumberPosition}
                  onChange={(e) => setPageNumberPosition(e.target.value)}
                >
                  {positions?.map((pos: any) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Brand;
