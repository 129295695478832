import React, { useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Switch from 'react-switch'
import { BarChartColorType, FontFamilyType, FontStyleType } from '../ChartsWidget';
import { tableData } from '../../CreateReport';

type StackedBarChartSettingsProps = {
  stackedBarChartTitle: string;
  setStackedBarChartTitle: React.Dispatch<React.SetStateAction<string>>;
  setShowStackedBarChartTitle: React.Dispatch<React.SetStateAction<boolean>>;
  showStackedBarChartTitle: boolean;
  setStackedBarChartTableData: React.Dispatch<React.SetStateAction<any>>;
  showStackedBarChartLegend: boolean;
  setShowStackedBarChartLegend: React.Dispatch<React.SetStateAction<boolean>>;
  stackedLegendPosition: 'top' | 'bottom';
  setStackedLegendPosition: React.Dispatch<React.SetStateAction<'top' | 'bottom'>>;
  stackedBarChartFontStyle: FontStyleType;
  setStackedBarChartFontStyle: React.Dispatch<React.SetStateAction<FontStyleType>>;
  stackedBarChartFontFamily: FontFamilyType;
  setStackedBarChartFontFamily: React.Dispatch<React.SetStateAction<FontFamilyType>>;
  stackedBarChartColorType: BarChartColorType;
  setStackedBarChartColorType: React.Dispatch<React.SetStateAction<BarChartColorType>>;
  stackedBarChartColor: string | string[];
  setStackedBarChartColor: React.Dispatch<React.SetStateAction<string | string[]>>;
  stackedBarChartTableData: tableData;
}

const StackedBarChartSettings: React.FC<StackedBarChartSettingsProps> = ({ 
    stackedBarChartTitle, setStackedBarChartTitle, setShowStackedBarChartTitle,
     showStackedBarChartTitle, showStackedBarChartLegend, setShowStackedBarChartLegend,
     stackedLegendPosition, setStackedLegendPosition, stackedBarChartFontStyle, setStackedBarChartFontStyle,
     stackedBarChartFontFamily, setStackedBarChartFontFamily, stackedBarChartColorType, setStackedBarChartColorType,
     stackedBarChartColor, setStackedBarChartColor, stackedBarChartTableData, setStackedBarChartTableData
 }) : JSX.Element => {
  
 const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleAccordionChange = (uuids: string[]) => {
    setExpandedItems(uuids);
  };

  return (
    <div>
        <Accordion 
          allowMultipleExpanded={false}
          allowZeroExpanded={true}
          preExpanded={[]}
          onChange={handleAccordionChange}
          className='border-0'
        >
            <AccordionItem className='border-0' uuid="item-1">
                <AccordionItemHeading className='bg-white border-t mt-3 p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Chart Title
                        {expandedItems.includes("item-1") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                    <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Chart Title
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showStackedBarChartTitle}
                          onChange={() => setShowStackedBarChartTitle(!showStackedBarChartTitle)}
                        /> 
                    </header>
                     <input
                        value={stackedBarChartTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStackedBarChartTitle(e.target.value)}
                        placeholder="Enter chart title"
                        className="input-style bg-primaryGray w-full text-[12px]"
                     />
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-2">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Legend
                        {expandedItems.includes("item-2") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                     <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Legend
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showStackedBarChartLegend}
                          onChange={() => setShowStackedBarChartLegend(!showStackedBarChartLegend)}
                        /> 
                    </header>
                    <section className='flex mt-3 justify-between items-center '>
                        <p className='text-xs font-medium'>
                            Legend Position
                        </p>
                        <select 
                            value={stackedLegendPosition}
                            onChange={(e) => setStackedLegendPosition(e.target.value as 'top' | 'bottom')}
                            className='w-[100px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                        >
                            <option value="top">Top</option>
                            <option value="bottom">Bottom</option>
                        </select>
                    </section>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-3">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Fonts
                        {expandedItems.includes("item-3") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                     <section className='flex mt-3 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Style
                        </p>
                        <select 
                            value={stackedBarChartFontStyle}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setStackedBarChartFontStyle(e.target.value as FontStyleType)
                            }}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                        >
                            <option value="normal">Normal</option>
                            <option value="italic">Italic</option>
                            <option value="oblique">Oblique</option>
                        </select>
                     </section>
                     <section className='flex mt-4 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Family
                        </p>
                        <select 
                            value={stackedBarChartFontFamily}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setStackedBarChartFontFamily(e.target.value as FontFamilyType)
                            }}
                        >
                            <option value="Public Sans">Public Sans</option>
                            <option value="Noto Sans">Noto Sans</option>
                            <option value="sans-serif">Sans-serif</option>
                            <option value="serif">Serif</option>
                            <option value="monospace">Monospace</option>    
                        </select>
                     </section>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-4">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Bar Settings
                        {expandedItems.includes("item-4") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                    {console.log(stackedBarChartTableData)
                    }
                    <section className='flex flex-col gap-2'>
                        {stackedBarChartTableData.headers.slice(2).map((header, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <span 
                                contentEditable={true} 
                                onBlur={(e) => {
                                    const newHeaders = [...stackedBarChartTableData.headers];
                                    newHeaders[index + 2] = e.currentTarget.textContent as string;
                                    setStackedBarChartTableData({
                                        ...stackedBarChartTableData,
                                        headers: newHeaders
                                    });
                                }} 
                                className="text-xs mr-3 text-slate-600"
                                >
                                     {header}
                                </span>
                                <input 
                                    type="color" 
                                    value={
                                        Array.isArray(stackedBarChartColor) ? 
                                        stackedBarChartColor[index] : 
                                        ['#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C'][index]
                                    }
                                    onChange={(e) => {
                                        const newColors = Array.isArray(stackedBarChartColor) ? 
                                            [...stackedBarChartColor] : 
                                            ['#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C'];
                                        newColors[index] = e.target.value;
                                        setStackedBarChartColor(newColors);
                                    }}
                                    className='w-[60px] h-[30px] border border-slate-300 rounded-lg' 
                                />
                            </div>
                        ))}
                    </section>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    </div>
  )
}

export default StackedBarChartSettings