import createApiClient from "./api";

const client = createApiClient();
const formDataClient = createApiClient(true);

export const api = {

  getModules: ({ search, perPage, currentPage }) => client
  .get(`admin/modules?perPage=${perPage}${
            search ? `&search=${search}` : ""
          }&page=${currentPage}`)
  .then(({ data }) => data),

  getCoursesSummary: () => client.get(`/admin/courses/get/summary`)
                       .then(({ data }) => data),

  createModule: (payload) => client.post(`admin/modules`, payload).then(({ data }) => data ),

  getCourses: ({ search, perPage, currentPage, status, category}) => 
    client.get(`admin/courses?perPage=${perPage}${ search ? `&search=${search}` : ''}&page=${
        currentPage
    }${ status ? `&status=${status}` : ''}${ category ? `&category=${category}` : ''}`)
    .then(({ data }) => data), 

  getCourseDetails: ({id}) => client.get(`admin/courses/${id}`).then(({ data }) => data),

  createCourse: (payload) => client.post(`admin/courses`, payload).then(({ data }) => data),

  updateCourse: (payload, id) => client.patch(`admin/courses/${id}`, payload).then(({ data }) => data),

  getCategories: ({perPage}) => client.get(`admin/categories?perPage=${perPage}`).then(({ data }) => data),

  createResource: (payload) => formDataClient.post('upload-file', payload),

  previewModule: ({ id, language }) => client.post(`admin/modules/${id}/preview?language=${language}`)
  .then(({ data }) => data),
   
  getModuleResult: ({id, previewId}) => client.get(`/admin/modules/${id}/preview/${previewId}`)
  .then(({ data }) => data),
  
  uploadModule: (payload) => formDataClient.post('upload-module-zip', payload),

  assignModuleToCourse: (payload) => 
    client.post(`admin/courses/${payload?.id}/assign-modules`, payload?.data)
    .then(({ data }) => data),

  addResourceToModule: ({payload, courseId, moduleId}) => 
    client.post(`admin/resources`, payload)
    .then(({ data }) => data),

  updateModule: (payload,id) => client.patch(`admin/modules/${id}`, payload).then(({ data }) => data),

  getCreditCodes: ({perPage})  => client.get(`/admin/credit-codes?perPage=${perPage}`)
  .then(({ data }) => data),

  getIndividualCourse: (id) => client.get(`/admin/courses/${id}`)
    .then(({ data }) => data),
  getCourseRatings: ({ search, perPage, currentPage, courseId }) => client
    .get(`admin/courses/${courseId}/ratings?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`)
    .then(({ data }) => data),
};
