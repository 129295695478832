import React, { useEffect, useState } from 'react'
import BadgeIcon from '../../../../../../../assets/Badge.png'
import BadgeIconOrange from '../../../../../../../assets/Bagde (5).png'
import BadgeIconGreen from '../../../../../../../assets/Badge (1).png'
import Button from '../../../../../../../components/Buttons/Button'
import OverviewCard from '../../../../report-and-analysis/components/OverviewCard'
import { Tab, initTE, } from "tw-elements";
import Search from '../../../../../../../components/Inputs/Search'
import DiscountTable from './components/DiscountTable'
import ClientDiscountTable from './components/ClientDiscountTable'
import { useQuery } from 'react-query'
import { api } from '../../../../../../../api/billing'
import Pagination from '../../../../../../../components/pagination/Pagination'
import CreateClientDiscount from './components/CreateClientTier'
import BundlesTable from './components/BundlesTable'
import CreateBundle from './components/CreateBundle'
import BundleTab from './components/BundleTab'

const Discount = () => {

  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orgPerPage, setOrgPerPage] = useState(5);
  const [orgSearch, setOrgSearch] = useState("");
  const [orgCurrentPage, setOrgCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false)
  const [activeDiscount, setActiveDiscount] = useState(null);
  const [openBundleModal, setOpenBundleModal] = useState(false)

  const {
    data: discounts,
    isLoading,
    refetch,
  } = useQuery(
    ["billing discounts"],
    ({ queryKey }) => {
      return api.getBillingDiscounts({ ...queryKey[1], perPage, currentPage, search });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:false,
      select: (data) => data,
    }
  );

  const {
    data: orgDiscounts,
    isLoading: orgDiscountLoading,
    refetch: refetchOrg,
  } = useQuery(
    ["organisation discounts"],
    ({ queryKey }) => {
      return api.getOrganisationDiscount({ 
          ...queryKey[1], perPage: orgPerPage, currentPage: orgCurrentPage, search: orgSearch 
        });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount: false,
      select: (data) => data,
    }
  );

  useEffect( () => {
    refetch()
  }, [ currentPage, perPage, search ] )

  useEffect( () => {
    refetchOrg()
  }, [ orgCurrentPage, orgPerPage, orgSearch ] )

 React.useEffect( () => initTE({ Tab }) , [] )

  return (
    <div className=' p-4'>    
    <header className='text-lg font-semibold flex justify-between items-center'>
        Volume Discount
        <div className='flex items-center'>
            <Button 
               text={'Volume Discount Tier'} 
               width={'w-[160px] mr-3 shadow-lg'}
               bgColor={'bg-darkBlue'}
               onClick={ () => setOpenModal(true) }
             />
            <CreateClientDiscount 
               openModal={openModal} setOpenModal={setOpenModal}
               refetch={refetch}
               activeDiscount={activeDiscount}
               setActiveDiscount={setActiveDiscount}
             />
        </div>
    </header>
    <header className='hidden pt-5 grid-cols-1 md:grid-cols-4 gap-5'>
           <OverviewCard 
             title={'Bronze (10%)'}
             total={200}
             iconPath={BadgeIconGreen}
             py={'py-8'}
             headerTextSize='text-2xl'
           />
           <OverviewCard
             title={'Silver (15%)'}  
             total={50}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Gold (18%)'}
             total={80}
             iconPath={BadgeIcon}
             headerTextSize='text-2xl'
             py='py-8'
           />
           <OverviewCard
             title={'Platinum (25%)'}  
             total={2000}
             iconPath={BadgeIconOrange}
             headerTextSize='text-2xl'
             py='py-8'
           />
      </header>
      <ul
        className="mb-5 mt-2 flex list-none flex-row flex-wrap border-b border-b-2 border-gray-200 pl-0"
        role="tablist"
        data-te-nav-ref
       >
                  <li role="presentation">
                    <a
                      href="#tabs-home"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px] font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-home"
                      data-te-nav-active
                      role="tab"
                      aria-controls="tabs-home"
                      aria-selected="true"
                      > Volume Discount </a>
                  </li>
                  <li role="presentation">
                    <a
                      href="#tabs-profile"
                      className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 py-1 text-[10px]  font-medium uppercase leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
                      data-te-toggle="pill"
                      data-te-target="#tabs-profile"
                      role="tab"
                      aria-controls="tabs-profile"
                      aria-selected="false"
                      >Bundle</a>
                  </li>
      </ul>
              <div className="mb-6">
                <div
                  className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-home"
                  role="tabpanel"
                  aria-labelledby="tabs-home-tab"
                  data-te-tab-active
                >
                  <section className='bg-white px-3 pb-3 rounded-lg'>
                    <header className='flex justify-between items-center'>
                        <h2 className='text-sm'>
                           Volume Discount
                        </h2>
                         <div className='mt-2'>
                            <Search 
                                className={'mb-0 w-[300px] border border-gray-200 text-xs pl-2'}
                                placeholder={'Search Volume Discount'} 
                                onChange={setSearch}
                            />
                         </div>
                    </header>
                    <DiscountTable
                      isLoading={isLoading}
                      data={discounts}
                      perPage={perPage}
                      setOpenEditTierModal={setOpenModal}
                      setActiveDiscount={setActiveDiscount}
                      refetch={refetch}
                    />
                  <Pagination 
                    data={discounts?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                  />
                </section>
                <section className='bg-white px-3 mt-5 pb-3 rounded-lg'>
                    <header className='flex justify-between items-center'>
                        <h2 className='text-sm'>
                          Clients Volume Discount
                        </h2>
                         <div className='mt-2'>
                            <Search 
                                className={'mb-0 w-[300px] border border-gray-200 text-xs pl-2'}
                                placeholder={'Search Client Volume Discount'} 
                                onChange={setSearch}
                            />
                         </div>
                    </header>
                    <ClientDiscountTable
                      isLoading={orgDiscountLoading}
                      data={orgDiscounts}
                      perPage={orgPerPage}
                    />
                    <Pagination 
                      data={orgDiscounts?.meta}
                      perPage={orgPerPage}
                      pageNo={orgCurrentPage}
                      setPageNo={setOrgCurrentPage}
                      setPerPage={setOrgPerPage}
                   />
                </section>
                </div>
                <div
                  className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                  id="tabs-profile"
                  role="tabpanel"
                  aria-labelledby="tabs-profile-tab">
                      <BundleTab
                         openBundleModal={openBundleModal}
                         setOpenBundleModal={setOpenBundleModal}
                      />
                  </div>
            </div>
    </div>
  ) 
}

export default Discount