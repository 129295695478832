import React from "react";
import { useBrandContext } from "../../hooks/useBrandContext";

const CanvasEditor = (
  {
    // logo,
    // showLogo,
    // logoPosition,
    // headerVisible,
    // headerPosition,
    // footerVisible,
    // footerPosition,
    // pageNumberPosition,
  }
) => {
  const {
    logo,
    showLogo,
    logoPosition,
    headerVisible,
    headerPosition,
    footerVisible,
    footerPosition,
    pageNumberPosition,
    positionClassMap,
  } = useBrandContext();

  // const positionClassMap = {
  //   "top-left": "top-0 left-0",
  //   "top": "top-0 left-1/2 transform -translate-x-1/2",
  //   "top-right": "top-0 right-0",
  //   "center": "top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2",
  //   "bottom-left": "bottom-0 left-0",
  //   "bottom": "bottom-0 left-1/2 transform -translate-x-1/2",
  //   "bottom-right": "bottom-0 right-0",
  // };

  return (
    <div className="flex-1 bg-white relative overflow-hidden">
      {headerVisible && (
        <div
          className={`absolute ${positionClassMap[headerPosition]} p-4 bg-gray-200`}
        >
          Header
        </div>
      )}
      {showLogo && (
        <img
          src={logo}
          alt="Logo"
          className={`absolute ${positionClassMap[logoPosition]} w-12 h-12`}
        />
      )}
      {footerVisible && (
        <div
          className={`absolute ${positionClassMap[footerPosition]} p-4 bg-gray-200`}
        >
          Footer
        </div>
      )}
      <div
        className={`absolute ${positionClassMap[pageNumberPosition]} p-2 text-sm`}
      >
        Page 1
      </div>
    </div>
  );
};

export default CanvasEditor;
