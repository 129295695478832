import React from 'react'
import { tableData } from '../../CreateReport';
import * as XLSX from 'xlsx';
import { useState } from 'react';
import { ChartActiveTab } from '../ChartsWidget';

type ImportDataProps = {
  tableData: tableData;
  setTableData: React.Dispatch<React.SetStateAction<tableData>>;
  setOpenTable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveTab: React.Dispatch<React.SetStateAction<ChartActiveTab>>;
  activeTab: ChartActiveTab;
}

const ImportData: React.FC<ImportDataProps> = ({ 
    tableData, setTableData, setOpenTable, setActiveTab, activeTab
 }) : JSX.Element => {

 const [isValidFile, setIsValidFile] = useState<boolean>(true)

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
        // Check if headers match expected structure
        const expectedHeaders = ['Labels', 'Values'];
        const headers = jsonData[0] as string[];
        const isValidHeaders = headers.every((header, index) => header === expectedHeaders[index]);
        
        if(!jsonData?.length) return setIsValidFile(false)
        if (!isValidHeaders) {
          setIsValidFile(false);
          return;
        }
        // Validate and transform data rows
        const transformedData = jsonData.slice(1).map((row: any, index: number) => {
          return [ index + 1, row[0] || '', row[1] || ''  ];
        });
        setIsValidFile(true)

        setTableData({
          headers: ['S/N', 'Labels', 'Values'],
          body: transformedData
        });
        setIsValidFile(true);
        setOpenTable(true); 
        setActiveTab('chart data')
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <section className='p-3'>  
        <input
            name="filePath" 
            accept=".csv,.xlsx,.xls" 
            type="file" 
            id='file-input' 
            className='hidden'
            onChange={handleFileUpload}
        />
        <h2 className='text-xs mb-3 flex justify-between items-center border-t border-slate-200 pt-3'>
            Import CSV/XLSX Data
            <a 
            href="/assets/barchart-data.xlsx" 
            className='bi bi-download text-slate-900 text-lg'
            download={'sample chart data.xlsx'}
          />
        </h2>
      <div className=''>
        <label 
            htmlFor="file-input" 
            className='flex h-[200px] border-2 border-dotted border-slate-200 items-center flex-col justify-center'
            >
            <i className="bi bi-cloud-arrow-up text-[30px]"></i>
            <p className='text-sm mb-3 text-primary'>
                Click to browse files
            </p>
            <span className='btn bg-primary-blue'>
                Browse Files
            </span>
        </label>
        {!isValidFile && 
          <p className='text-red-500 text-xs mt-3'>Invalid file format</p>
        }
      </div>
    </section>
  )
}

export default ImportData