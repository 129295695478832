import html2pdf from 'html2pdf.js'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

type PreviewHeaderType = {
 setPreview: React.Dispatch<React.SetStateAction<boolean>>
 data: any
}
const PreviewHeader: React.FC<PreviewHeaderType> = ({setPreview, data}) => {

  const [isDownload, setIsDownload] = useState<boolean>(false)
  const handleDownload = async () => {
    setIsDownload(true);
    const loadingToast = toast.loading('Generating PDF...');
    
    try {
        const element = document.getElementById('report-canvas-container');
        const opt = {
            margin: [0, 0, 0, 0],
            filename: 'report.pdf',
            image: { 
                type: 'jpeg',
                quality: 1
            },
            html2canvas: { 
                scale: 2,
                useCORS: true,
                allowTaint: true,
                scrollY: 0,
                scrollX: 0,
                onrendered: function(canvas: HTMLCanvasElement) {
                    document.body.appendChild(canvas);
                }
            },
            jsPDF: { 
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait'
            }
        };

        await html2pdf().from(element).set(opt).save();
        toast.success('Downloaded successfully', {
            id: loadingToast
        });
    } catch (error) {
        toast.error('Failed to generate PDF', {
            id: loadingToast
        });
        console.error('PDF generation error:', error);
    } finally {
        setIsDownload(false);
    }
        /*const opt = {
            margin: [0, 0, 0, 0], // [top, left, bottom, right]
            filename: 'report.pdf',
            image: { 
                type: 'jpeg', 
                quality: 1
            },
            // html2canvas: { 
            //     scale: 2,
            //     useCORS: true,
            //     logging: false,
            //     dpi: 360,
            //     letterRendering: true,
            //     windowWidth: 500,
            //     windowHeight: element?.scrollHeight
            // },
            // jsPDF: { 
            //     unit: 'mm',
            //     format: 'a4',
            //     orientation: 'portrait',
            //     compress: true,
            //     precision: 16
            // },
            // pagebreak: { mode: 'avoid-all' }
        };

        await html2pdf().from(element).set(opt).save();
        toast.success('Downloaded successfully', {
            id: loadingToast
        });
    } catch (error) {
        toast.error('Failed to generate PDF', {
            id: loadingToast
        });
        console.error('PDF generation error:', error);
    } finally {
        setIsDownload(false);
    }*/
};

  return (
    <header className='flex justify-between mt-5 bg-customBlue p-3'>
       <div className="flex items-center">
        <button className="bg-transparent text-sm text-white">
          Menu <i className="bi bi-chevron-down ml-2 text-white"></i>
        </button>
        <div className="w-[1px] h-[40px] bg-white ml-5"></div>
        <h3
        //  contentEditable
         // onInput={changeTitle}
          className="text-white textsm font-semibold ml-5"
        >
          {data?.name}
        </h3>
      </div>
      <div className='flex items-center'>
      <button onClick={handleDownload} className='btn pt-2 pb-2 bi bi-download mr-3 text-xs bg-[#CC7300] pl-8 pr-8'>
            &nbsp; Download Report
       </button>
       <button 
          onClick={() => setPreview(false)} 
          className='btn-light pt-2 pb-2 ml-3 bi bi-arrow-left text-xs bg-white pl-8 pr-8'
        >
           &nbsp;Back
        </button>  
      </div>
    </header>
  )
}

export default PreviewHeader