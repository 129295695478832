import React, { useState, useRef } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";

import { IconContext } from "react-icons";

import { Rnd } from "react-rnd";
import { SketchPicker } from "react-color";
import iconList from "../../../../../data/icons";

const ItemType = {
  ICON: "icon",
  UPLOADED_ICON: "uploaded_icon",
};

const DraggableUploadedIcon = ({ iconUrl }) => {
  const [, drag] = useDrag(() => ({
    type: ItemType.UPLOADED_ICON,
    item: { iconUrl },
  }));

  return (
    <div
      ref={drag}
      className="p-2 border rounded cursor-pointer hover:bg-gray-200"
    >
      <img
        src={iconUrl}
        alt="Uploaded Icon"
        className="w-8 h-8 object-contain"
      />
    </div>
  );
};

// Define DraggableIcon
// const DraggableIcon = ({ icon, type }) => {
//   const [, drag] = useDrag(() => ({
//     type: type || ItemType.ICON,
//     item: { icon, type },
//   }));

//   return (
//     <div
//       ref={drag}
//       className="p-2 border rounded cursor-pointer hover:bg-gray-200"
//     >
//       <IconContext.Provider value={{ size: "24px" }}>
//         {React.createElement(icon)}
//       </IconContext.Provider>
//     </div>
//   );
// };

const DraggableIcon = ({ icon }) => {
  const [, drag] = useDrag(() => ({
    type: ItemType.ICON,
    item: { icon },
  }));

  return (
    <div
      ref={drag}
      className="p-2 border rounded cursor-pointer hover:bg-gray-200"
    >
      <IconContext.Provider value={{ size: "24px" }}>
        {React.createElement(icon)}
      </IconContext.Provider>
    </div>
  );
};

const IconEditor = () => {
  const [activeTab, setActiveTab] = useState("Icons");
  const [uploadedIcons, setUploadedIcons] = useState([]);
  const [elements, setElements] = useState([]);
  const [activeElementIndex, setActiveElementIndex] = useState(null);
  const cardRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [visibleIcons, setVisibleIcons] = useState(iconList.slice(0, 50));
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [showIconList, setShowIconList] = useState(true);

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter icons based on the search query
    const filteredIcons = iconList.filter((icon) =>
      icon.name.toLowerCase().includes(query.toLowerCase())
    );
    setVisibleIcons(filteredIcons);
  };

  const [, drop] = useDrop(() => ({
    accept: ItemType.ICON,

    drop: (item, monitor) => {
      const offset = monitor.getSourceClientOffset();
      if (offset) {
        // Append new element to the array
        setElements((prevElements) => [
          ...prevElements,
          {
            component: item.icon,
            x: offset.x - 50,
            y: offset.y - 50,
            width: 50,
            height: 50,
            color: "#000",
            fontSize: 24,
            rotation: 0, // Rotation value for element
            opacity: 1,
          },

        ]);
      }
    },
  }));

  // const updateElementPosition = (index, x, y) => {
  //   updateElementProperty(index, "position", { x, y });
  // };

  // const [, drop] = useDrop(() => ({
  //   accept: [ItemType.ICON],
  //   accept: [ItemType.ICON, ItemType.UPLOADED_ICON],
  //   drop: (item, monitor) => {
  //     const offset = monitor.getSourceClientOffset();
  //     if (offset) {
  //       const newElement = {
  //         x: offset.x - 50,
  //         y: offset.y - 50,
  //         width: 50,
  //         height: 50,
  //         color: "#000",
  //         fontSize: 24,
  //         rotation: 0,
  //         opacity: 1,
  //       };

  //       if (item.type === ItemType.ICON) {
  //         newElement.component = item.icon;
  //       } else if (item.type === ItemType.UPLOADED_ICON) {
  //         newElement.iconUrl = item.iconUrl;
  //       }

  //       setElements((prevElements) => [...prevElements, newElement]);
  //     }
  //   },
  // }));

  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedIcons((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const updateElementPosition = (index, x, y) => {
    const updatedElements = [...elements];
    updatedElements[index].x = x;
    updatedElements[index].y = y;
    setElements(updatedElements);
  };

  const updateElementProperty = (index, key, value) => {
    if (index === null || !elements[index]) return;
    const updatedElements = [...elements];
    updatedElements[index][key] = value;
    setElements(updatedElements);
  };
  // const updateElementProperty = (index, key, value) => {
  //   if (index === null || !elements[index]) return; // Prevent accessing undefined element
  //   const updatedElements = [...elements];
  //   if (key === "position") {
  //     updatedElements[index].x = value.x;
  //     updatedElements[index].y = value.y;
  //   } else {
  //     updatedElements[index][key] = value; // Dynamically update any other property
  //   }
  //   setElements(updatedElements);
  // };

  const duplicateElement = (index) => {
    const duplicatedElement = { ...elements[index] };
    setElements([...elements, duplicatedElement]);
  };

  const deleteElement = (index) => {
    if (index !== null) {
      setElements((prevElements) => {
        const updatedElements = prevElements.filter((_, i) => i !== index);
        if (updatedElements.length === 0) {
          setShowIconList(true); // Show icon list when no elements are left
        }
        return updatedElements;
      });
      setActiveElementIndex(null);
    }
  };

  const handleDeselect = () => {
    setActiveElementIndex(null); // Deselect the active element
    setShowIconList(true); // Show the icon list again
  };

  const rotateElement = (index, angle) => {
    if (index !== null && elements[index]) {
      const updatedElements = [...elements];
      updatedElements[index].rotation =
        (updatedElements[index].rotation || 0) + angle;
      setElements(updatedElements);
    }
  };

  console.log(selectedIcon, "icon");

  // const handleElementClick = (index) => {
  //   setActiveElementIndex(index);
  // };

  const handleElementClick = (index) => {
    setActiveElementIndex(index);
    setShowIconList(false);
  };

  // const handleDeselect = () => {
  //   setActiveElementIndex(null);
  // };

  return (
    <div className="flex h-screen">
      {/* Sidebar with draggable icons */}
      <div className="w-1/4 p-4 bg-gray-100 border-r">
        <h3 className="mb-4 text-lg font-bold">Icons</h3>
        <div className="flex w-full text-sm font-medium mb-4">
          <button
            onClick={() => setActiveTab("Icons")}
            className={`px-10 py-2 rounded-t  ${
              activeTab === "Icons"
                ? "bg-customBlue text-white"
                : "bg-white shadow  text-[#8E8E8E]"
            }`}
          >
            Icons
          </button>
          <button
            onClick={() => setActiveTab("Uploaded Icons")}
            className={`px-4 py-2 rounded-t  ${
              activeTab === "Uploaded Icons"
                ? "bg-customBlue text-white"
                : "bg-white shadow  text-[#8E8E8E]"
            }`}
          >
            Uploaded Icons
          </button>
        </div>
        <div>
          {activeTab === "Icons" && (
            <div>
              <input
                type="text"
                placeholder="Search icons..."
                value={searchQuery}
                onChange={handleSearch}
                className="w-full p-2 mb-4 border rounded"
              />
              {showIconList && (
                <div className="grid grid-cols-3 gap-4">
                  {visibleIcons.map((icon, index) => (
                    <DraggableIcon
                      key={index}
                      icon={icon.component}
                      // onClick={() => setSelectedIcon(icon.component)} // Set selected icon
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          {activeTab === "Uploaded Icons" && (
            <div>
              <div class="items-center justify-center max-w-xl mx-auto">
                <label
                  class="flex justify-center w-full px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
                  id="drop"
                  for="file-input"
                >
                  <span class="flex flex-col items-center space-x-2 pt-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-6 h-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <span class="font-medium text-[#FF9100] text-xs pt-5">
                      Drag & drop new images here
                    </span>
                    <label
                      for="file-input"
                      className="my-5 cursor-pointer text-white bg-customBlue hover:bg-darkBlue font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                    >
                      Browse Files
                    </label>
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleUpload}
                    id="file-input"
                    className="mb-4 hidden"
                  />
                </label>
              </div>

              <div className="grid grid-cols-3 gap-4">
                {uploadedIcons.map((iconUrl, index) => (
                  <DraggableUploadedIcon key={index} iconUrl={iconUrl} />
                ))}
              </div>
            </div>
          )}
        </div>

        {activeElementIndex !== null && elements[activeElementIndex] && (
          <div className="mt-4 bg-white p-4 shadow-lg rounded-lg">
            <div className="mb-2">
              <label>Font Size:</label>
              <input
                type="number"
                value={elements[activeElementIndex]?.fontSize || 24}
                onChange={(e) =>
                  updateElementProperty(
                    activeElementIndex,
                    "fontSize",
                    parseInt(e.target.value, 10)
                  )
                }
                className="w-full p-2 border rounded"
              />
            </div>
            <div className="mt-4">
              <label className="block text-base font-medium text-gray-700 mb-2">
                Icon Opacity
              </label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={elements[activeElementIndex]?.opacity}
                onChange={(e) =>
                  updateElementProperty(
                    activeElementIndex,
                    "opacity",
                    parseFloat(e.target.value)
                  )
                }
                className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
              />
            </div>
            <div className="mb-2">
              <label>Color:</label>
              <SketchPicker
                color={elements[activeElementIndex]?.color || "#000"}
                onChangeComplete={(color) =>
                  updateElementProperty(activeElementIndex, "color", color.hex)
                }
              />
            </div>
            <button
              onClick={handleDeselect} // Deselect icon
              className="mt-4 p-2 text-red-500"
            >
              Deselect Icon
            </button>
            <div className="flex justify-between"></div>
          </div>
        )}
      </div>

      {/* Canvas */}
      <div className="flex-1 relative bg-gray-50" ref={drop}>
        {elements.map((element, index) => (
          <Rnd
            key={index}
            size={{ width: element.width, height: element.height }}
            position={{ x: element.x, y: element.y }}
            onDragStop={(e, d) => updateElementPosition(index, d.x, d.y)}
            // onClick={() => setActiveElementIndex(index)}
            onClick={() => handleElementClick(index)}
            style={{
              border: activeElementIndex === index ? "1px solid blue" : "none",
              padding: "5px",
              transform: `rotate(${element.rotation}deg)`, // Rotation applied here
            }}
          >
            <div
              className="cursor-pointer"
              style={{
                fontSize: `${element.fontSize}px`,
                color: element.color,
                opacity: element?.opacity,
              }}
            >
              <element.component />
            </div>

            {/* {element.component ? (
              <div
                className="cursor-pointer"
                style={{
                  fontSize: `${element.fontSize}px`,
                  color: element.color,
                  opacity: element.opacity,
                }}
              >
                <element.component />
              </div>
            ) : (
              <img
                src={element.iconUrl}
                alt="Uploaded Icon"
                style={{
                  width: "100%",
                  height: "100%",
                  opacity: element.opacity,
                }}
              />
            )} */}
            {/* Action Buttons */}
            {activeElementIndex === index && (
              <div
                ref={cardRef}
                className="absolute right-0 top-10 flex bg-white border rounded-lg shadow-2xl p-2"
              >
                {/* Duplicate Button */}
                <button
                  onClick={() => duplicateElement(index)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Duplicate
                </button>
                {/* Delete Button */}
                <button
                  onClick={() => deleteElement(index)}
                  className="m-1 p-1 text-sm rounded relative group text-red-500"
                >
                  Delete
                </button>
                {/* Rotate Buttons */}
                {/* <button
                  onClick={() => rotateElement(index, 15)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Rotate 15°
                </button>
                <button
                  onClick={() => rotateElement(index, -15)}
                  className="m-1 p-1 text-sm rounded relative group"
                >
                  Rotate -15°
                </button> */}
              </div>
            )}
          </Rnd>
        ))}
      </div>
    </div>
  );
};

export default IconEditor;
