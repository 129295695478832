import React, { useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import Switch from 'react-switch'
import { BarChartColorType, FontFamilyType, FontStyleType } from '../ChartsWidget';
import { tableData } from '../../CreateReport';
import { BarChartTitles, pieChartType } from '../../types/charts.types';

type ChartSettingsProps = {
  barChartTitle: string;
  setBarChartTitle: React.Dispatch<React.SetStateAction<string>>;
  setShowBarChartTitle: React.Dispatch<React.SetStateAction<boolean>>;
  showBarChartTitle: boolean;
  showBarChartLegend: boolean;
  setShowBarChartLegend: React.Dispatch<React.SetStateAction<boolean>>;
  legendPosition: 'top' | 'bottom';
  setLegendPosition: React.Dispatch<React.SetStateAction<'top' | 'bottom'>>;
  barChartFontStyle: FontStyleType;
  setBarchartFontStyle: React.Dispatch<React.SetStateAction<FontStyleType>>;
  barChartFontFamily: FontFamilyType;
  setBarchartFontFamily: React.Dispatch<React.SetStateAction<FontFamilyType>>;
  barChartColorType: BarChartColorType;
  setBarChartColorType: React.Dispatch<React.SetStateAction<BarChartColorType>>;
  barChartColor: string | string[];
  setBarChartColor: React.Dispatch<React.SetStateAction<string | string[]>>;
  tableData: tableData;
  activeChart?: BarChartTitles | pieChartType | 'bar chart types';
  showDhoughntuCenterText?: boolean
  setShowDhoughntuCenterText?: React.Dispatch<React.SetStateAction<boolean>>;
  centerText?: string
  setCenterText?: React.Dispatch<React.SetStateAction<string>>
  activeElementId?: string,
  elements?: any[]
  updateChartElement?: any
}

const ChartSettings: React.FC<ChartSettingsProps> = ({ 
    barChartTitle, setBarChartTitle, setShowBarChartTitle, setCenterText, 
     showBarChartTitle, showBarChartLegend, setShowBarChartLegend, setShowDhoughntuCenterText,
     legendPosition, setLegendPosition, barChartFontStyle, setBarchartFontStyle,
     barChartFontFamily, setBarchartFontFamily, barChartColorType, setBarChartColorType,
     barChartColor, setBarChartColor, tableData, activeChart = 'bar chart types',
     centerText, showDhoughntuCenterText, activeElementId, elements, updateChartElement,
 }) : JSX.Element => {
  
 const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleAccordionChange = (uuids: string[]) => {
    setExpandedItems(uuids);
  };

  const findActiveElment = elements?.find( (element_: any) => {
    return element_?.elementId === activeElementId
    })

  return (
    <div>
        <Accordion 
          allowMultipleExpanded={false}
          allowZeroExpanded={true}
          preExpanded={[]}
          onChange={handleAccordionChange}
          className='border-0'
        >
            <AccordionItem className='border-0' uuid="item-1">
                <AccordionItemHeading className='bg-white border-t mt-3 p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Chart Title
                        {expandedItems.includes("item-1") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                    <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Chart Title
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showBarChartTitle}
                          onChange={() => setShowBarChartTitle(!showBarChartTitle)}
                        /> 
                    </header>
                     <input
                        value={barChartTitle}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBarChartTitle(e.target.value)}
                        placeholder="Enter chart title"
                        className="input-style bg-primaryGray w-full text-[12px]"
                     />
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-2">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Legend
                        {expandedItems.includes("item-2") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                     <header className='flex py-2 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Show Legend
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={showBarChartLegend}
                          onChange={() => setShowBarChartLegend(!showBarChartLegend)}
                        /> 
                    </header>
                    <section className='flex mt-3 justify-between items-center '>
                        <p className='text-xs font-medium'>
                            Legend Position
                        </p>
                        <select 
                            value={legendPosition}
                            onChange={(e) => setLegendPosition(e.target.value as 'top' | 'bottom')}
                            className='w-[100px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                        >
                            <option value="top">Top</option>
                            <option value="bottom">Bottom</option>
                        </select>
                    </section>
                    <section className='mt-3'>
                      {
                         activeChart === 'Donut Chart' ? (
                             <>
                                 <header className='flex py-2 justify-between items-center'>
                                    <p className='text-xs font-medium'>
                                        Show Center Text
                                    </p>
                                    <Switch
                                        height={20}
                                        width={40}
                                        handleDiameter={18}
                                        checked={showDhoughntuCenterText!}
                                        onChange={() => setShowDhoughntuCenterText?.(!showDhoughntuCenterText)}
                                    /> 
                                </header>
                                <input
                                    value={findActiveElment !== -1 ? findActiveElment?.content : ''}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) => {
                                            if(findActiveElment !== -1){
                                                setCenterText?.(e.target.value)
                                                updateChartElement('content', e.target.value)
                                            }
                                          }
                                        }
                                    placeholder="Enter center text"
                                    className="input-style bg-primaryGray w-full text-[12px]"
                                />
                             </>
                         ) : null
                      }
                    </section>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-3">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Fonts
                        {expandedItems.includes("item-3") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                     <section className='flex mt-3 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Style
                        </p>
                        <select 
                            value={barChartFontStyle}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setBarchartFontStyle(e.target.value as FontStyleType)
                            }}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                        >
                            <option value="normal">Normal</option>
                            <option value="italic">Italic</option>
                            <option value="oblique">Oblique</option>
                        </select>
                     </section>
                     <section className='flex mt-4 justify-between items-center'>
                        <p className='text-xs font-medium'>
                            Font Family
                        </p>
                        <select 
                            value={barChartFontFamily}
                            className='w-[120px] py-2 px-1 border border-slate-300 rounded-lg text-[12px]'
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setBarchartFontFamily(e.target.value as FontFamilyType)
                            }}
                        >
                            <option value="Public Sans">Public Sans</option>
                            <option value="Noto Sans">Noto Sans</option>
                            <option value="sans-serif">Sans-serif</option>
                            <option value="serif">Serif</option>
                            <option value="monospace">Monospace</option>    
                        </select>
                     </section>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className='border-0' uuid="item-4">
                <AccordionItemHeading className='bg-white p-3 border-b border-slate-300'>
                    <AccordionItemButton className='text-xs font-medium flex justify-between items-center'>
                        Bar Settings
                        {expandedItems.includes("item-4") ? (
                          <FaChevronUp className="text-gray-500" />
                        ) : (
                          <FaChevronDown className="text-gray-500" />
                        )}
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className='p-3'>
                   { 
                    activeChart === 'bar chart types' && (
                    <section className='flex mt-3 justify-between items-center'>
                        <p className='text-xs font-medium'>
                           Apply same color for all 
                        </p>
                        <Switch
                          height={20}
                          width={40}
                          handleDiameter={18}
                          checked={barChartColorType === 'single'}
                          onChange={() => setBarChartColorType(
                             barChartColorType === 'single' ? 'distinct' : 'single'
                            )}
                        /> 
                    </section>
                    )}
                    {barChartColorType === 'single' && activeChart === 'bar chart types' ? (
                        <section className='flex mt-3 justify-between items-center'>
                            <p className='text-xs font-medium'>
                                Color
                            </p>
                            <input 
                               type="color" value={barChartColor}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBarChartColor(e.target.value)} 
                               className='w-[60px] h-[30px] border border-slate-300 rounded-lg'
                           />
                        </section>
                    )
                  : (
                        <section className='flex mt-3  items-center'>
                            <div className='flex flex-col gap-2'>
                                {tableData.body.map((row: any, index: number) => (
                                    <div key={index} className="flex items-center justify-between">
                                        <span className="text-xs mr-3 text-slate-600">{row[1]}</span>
                                        <input 
                                            type="color" 
                                            value={(barChartColor as string[])[index] || '#000000'}
                                            onChange={(e) => {
                                                const newColors = [...barChartColor as string[]];
                                                newColors[index] = e.target.value;
                                                setBarChartColor(newColors);
                                            }}
                                            className='w-[60px] h-[30px] border border-slate-300 rounded-lg' 
                                        />
                                    </div>
                                ))}
                            </div>
                        </section>
                    )}
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
    </div>
  )
}

export default ChartSettings