import { useMemo } from "react";

// Custom hook to apply Tailwind styles to react-select components
const useSelectStyles = () => {
  return useMemo(() => ({
    control: (provided,state) => ({
      ...provided,
      display: "flex",
      flex: "1",
      // zIndex: 100, // Corrected zIndex value as a number
      fontSize: "0.875rem", // Tailwind text-sm
      appearance: "none",
      backgroundColor: "#E8EDF4", // Tailwind bg-primaryGray
      borderColor: state.isFocused ? "#3b82f6" : "#d1d5db", // Tailwind for focus:ring-primaryBlue and border-gray-300
      borderRadius: "0.125rem", // Tailwind rounded-sm
      padding: "0.25rem 1rem", // Tailwind py-3 px-4
      color: "#374151", // Tailwind text-gray-700
      boxShadow: state.isFocused ? "0 0 0 2px #3b82f6" : "", // Tailwind focus:ring-primaryBlue
      "&:hover": {
        borderColor: "#3b82f6", // Tailwind focus:border-primaryBlue
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af", // Tailwind placeholder-gray-400
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "0.125rem", // Tailwind rounded-sm
      // maxHeight: "150px", // Restrict max height
      overflowY: "auto  !important", // Enable scrolling
      zIndex: 999, // Ensure menu is above other elements
    }),
    // menuList: (provided) => ({
    //   ...provided,
    //   maxHeight: "110px", // Limit height for list scrolling
    //   overflowY: "auto", // Ensure scrolling inside menuList
    // }),
    
  }), []);
};

export default useSelectStyles;
