import React, { useEffect, useMemo } from "react";
import { Ripple, initTE } from "tw-elements";
import debounce from "lodash.debounce";

interface SearchProps {
  iconColor?: string; // Optional color for the icon
  value?: string; // The current value of the input
  placeholder?: string; // Placeholder text for the input
  bgColor?: string; // Optional background color
  onClick?: () => void; // Callback for button click (if any)
  onChange?: (value: string) => void; // Callback for input value change
  showButton?: boolean; // Whether to show a button (default: true)
  debounceDelay?: number; // Delay for the debounce (default: 300ms)
  className?: string; // Additional classes for the input
  width?: string; // Width of the input container
  mb?: string; // Margin bottom (default: 'mb-3')
  [key: string]: any; // To allow other props to be passed in
}

const TypeSearch: React.FC<SearchProps> = ({
  iconColor,
  value,
  placeholder = "🔍e.g learner-email@gmail.com or name..",
  bgColor,
  onClick,
  onChange,
  showButton = true,
  debounceDelay = 300,
  className = "",
  width = "",
  mb = "mb-3",
  ...rest
}) => {
  useEffect(() => {
    initTE({ Ripple });
  }, []);

  // Memoized debounce function
  const debouncedOnChange = useMemo(
    () =>
      debounce((newValue: string) => {
        if (onChange) {
          onChange(newValue);
        }
      }, debounceDelay),
    [onChange, debounceDelay]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedOnChange(event.target.value); // Pass the input value to the debounced function
  };

  return (
    <div className={`${mb} ${width}`}>
      <div className="relative flex w-full flex-wrap items-stretch">
        <input
          type="search"
          placeholder={`🔍 ${placeholder}`}
          aria-label="Search"
          className={`input-style bg-primaryGray ${className}`}
          aria-describedby="button-addon1"
          onChange={handleChange}
          value={value}
          {...rest}
        />
      </div>
    </div>
  );
};

export default TypeSearch;
