import React, { useEffect, useState } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import toast from "react-hot-toast";
import { api } from "../../../../../../api/report";
import { useNavigate } from "react-router";
import pptxgen from "pptxgenjs";
import Pptx from "pptxgenjs";

import { dataToPptx as pptxData } from "./dataToPppttest";

type ReportPayload = {
  name: string;
  description: string;
  type: string;
  size: string;
  orientation: string;
  width: number | null;
  height: number | null;
  pixels: number | null;
  status: string;
  pages: { sort: number; page: string }[];
};

export type ReportData = {
  name: string;
  description: string;
  type: string;
  size: string;
  orientation: string;
  width: number;
  height: number;
  pixels: number;
  status: string;
  orgId: string;
  id: string;
};

type HeaderType = {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPreviewReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  pages: any[];
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
  pageStructure: any[];
  data: ReportData;
  reportId: string | null;
  action: string | null;
};

const Header: React.FC<HeaderType> = ({
  title,
  setIsPreview,
  setTitle,
  action,
  setOpen,
  setOpenPreviewReportModal,
  pages,
  pageStructure,
  data,
  reportId,
}): JSX.Element => {
  const changeTitle = (event: React.ChangeEvent<HTMLHeadingElement>): void => {
    setTitle(event.target.innerText);
  };
  const navigate = useNavigate();
  const user =
    sessionStorage.getItem("user")?.trim() === ""
      ? null
      : JSON.parse(sessionStorage.getItem("user") ?? "{}");
  const [elements, setElements] = useState<any[]>([]);

  useEffect(() => {
    const savedData = sessionStorage.getItem("reportData");
    const elementsData = sessionStorage.getItem("elements");
    if (savedData) {
      const payload: ReportData = JSON.parse(savedData);
      // setData(payload);
      if (elementsData !== null) {
        setElements(JSON.parse(elementsData));
      } else {
        setElements([]);
      }
    }
  }, []);

  // console.log(pages, "pagesHeader");
  // console.log(pageStructure, "pagesHeaderStructure");

  const enrichedPages = pages.map((page) => {
    const pageContent = pageStructure.filter(
      (element) => element.activePageId === page.id
    );
    return {
      ...page,
      pageContent,
    };
  });

  // console.log(enrichedPages, "enrichedPages");

  console.log(enrichedPages);

  const handleSubmit = async () => {
    if (!data) {
      toast.error("No data available");
      return;
    }

    const payload: ReportPayload = {
      name: data.name,
      description: data.description,
      type: data.type,
      size: data.size,
      orientation: data.orientation,
      width: data.width || null,
      height: data.height || null,
      pixels: data.pixels || null,
      status: data.status || "draft",
      pages: enrichedPages.map((page, index) => ({
        sort: index + 1,
        pageId: page.id,
        page: JSON.stringify({
          elements: page.pageContent,
          backgroundColor: pages?.find((p) => p.id === page.id)
            ?.backgroundColor,
        }),
      })),
    };

    try {
      toast.loading("Loading...");
      const response = await api.updateReport({
        orgId: data?.orgId,
        reportId: data?.id,
        payload,
      });
      toast.remove();
      toast.success(response.message);
      navigate("/admin/report");
      sessionStorage.removeItem("elements");
      sessionStorage.removeItem("backgroundImage");
      sessionStorage.removeItem("reportData");
      sessionStorage.removeItem("reportElements");
      sessionStorage.removeItem("brandLogo");
      sessionStorage.removeItem("brandData");
    } catch (error) {
      toast.remove();
      toast.error(`Error ${error}`);
    }
  };

  const pres = new Pptx();
  type ShapeTypeNames = keyof typeof pres.ShapeType;

  interface Shape {
    type: ShapeTypeNames;
    // other properties...
  }
  interface Element {
    shape: Shape;
    // other properties...
  }

  const downloadpptx = () => {
    const dataToPptx = enrichedPages.map((page, index) => ({
      elements: page.pageContent,
      backgroundColor: pages?.find((p) => p.id === page.id)?.backgroundColor,
    }));

    let pptx = new pptxgen();

    //pptx.defineLayout({ name: "A4", width: 1024, height: 768 }); // Set layout to A4 size
    // pptx.defineLayout({ name: "A4", width: 8.27, height: 11.695 });
    //pptx.layout = 'A4'
    dataToPptx.forEach((page) => {
      let slide = pptx.addSlide();
      slide.background = { color: page.backgroundColor };

      page.elements.forEach((element: any) => {
        console.log(
          element.type === "chart" &&
            element.chartConfig &&
            element?.chartType === "Column Bar Chart"
        );
        if (
          element.type === "heading" ||
          element.type === "subheading" ||
          element.type === "body"
        ) {
          slide.addText(element.content, {
            x: element.x / 84, // Convert pixels to inches
            y: element.y / 92,
            w:
              element.width === "auto"
                ? undefined
                : element.type === "heading" || element.type === "subheading"
                ? element.width / 92
                : element.width / 86,
            h: element.height === "auto" ? undefined : element.height / 96,
            fontSize:
              element.type === "heading" || element.type === "subheading"
                ? element.fontSize - 10
                : element.fontSize - 3,
            color: element.color,
            align: element.textAlign,
            //shape: "rect",
            line: { size: element.borderWidth, color: element.borderColor },
            fill: { color: element.backgroundColor },
            bold: element.fontWeight === "bold",
            fontFace: element.fontFamily,
          });
        } else if (element.type === "image") {
          const imageProps = {
            x: element.x / 84, // Convert pixels to inches
            y: element.y / 92,
            w: element.width === "auto" ? undefined : element.width / 87,
            h: element.height === "auto" ? undefined : element.height / 92,
            path: element.imageUrl,
            type: "cover",
          };
          slide.addImage(imageProps);
        } else if (
          element.type === "chart" &&
          element.chartConfig &&
          (element?.chartType === "Horizontal Bar Chart" ||
            element?.chartType === "Column Bar Chart")
        ) {
          const chartData = element.chartConfig.data.datasets.map(
            (dataset: any) => ({
              name: dataset.label,
              labels: element.chartConfig.data.labels,
              values: dataset.data,
              chartColors: dataset.backgroundColor,
            })
          );

          slide.addChart(pptx.ChartType.bar, chartData, {
            x: element.x / 84,
            y: element.y / 96,
            w: element.width / 96,
            h: element.height / 96,
            showTitle: element.chartConfig.options.plugins.title.display,
            title: element.chartConfig.options.plugins.title.text,
            legendFontSize: 11,
            titleFontSize: 14,
            barGapWidthPct:
              element?.chartType === "Horizontal Bar Chart" ? 20 : 50,
            legendPos:
              element.chartConfig.options.plugins.legend.position === "top"
                ? "t"
                : "r",
            showLegend: element.chartConfig.options.plugins.legend.display,
            barDir:
              element?.chartType === "Horizontal Bar Chart" ? "bar" : "col",
            chartColors:
              chartData?.length &&
              chartData[0]?.chartColors.map((color: any) => {
                return color?.slice(1, color.length);
              }),
            valAxisMinVal: 0,
          });
        } else if (
          element.type === "chart" &&
          element.chartConfig &&
          (element?.chartType === "Stacked Column Bar Chart" ||
            element?.chartType === "Stacked Horizontal Bar Chart" ||
            element?.chartType === "Progress Chart")
        ) {
          // Extract chart data
          const chartConfig = element.chartConfig;
          const chartType =
            chartConfig.type === "bar" ? "barStacked" : "columnStacked";
          const datasets = chartConfig.data.datasets;
          const labels = chartConfig.data.labels;

          // Prepare chart data for pptxgen
          const chartData = datasets.map((dataset: any) => ({
            name: dataset.label,
            labels: labels,
            values: dataset.data,
          }));

          // Chart opt ions
          const chartOptions: any = {
            x: element.x / 84, // Convert from pixels to inches
            y: element.y / 96,
            w: element.width / 96,
            h: element.height / 96,
            barDir:
              element?.chartType === "Stacked Horizontal Bar Chart" ||
              element?.chartType === "Progress Chart"
                ? "bar"
                : "col",
            bar3DShape: "box",
            barGapWidthPct: 50,
            showTitle: element.chartConfig.options.plugins.title.display,
            legendFontSize: 11,
            titleFontSize: 14,
            chartColors: element.chartConfig.data.datasets.map(
              (dataset: any) => dataset.backgroundColor
            ),
            showLegend: chartConfig.options.plugins.legend.display,
            legendPos:
              chartConfig.options.plugins.legend.position === "top" ? "t" : "r",
            showValue: false,
            barGrouping: "stacked", // Enable stacking
            title: chartConfig.options.plugins.title.display
              ? chartConfig.options.plugins.title.text
              : "",
          };

          // Add stacked chart to slide
          slide.addChart(pptx.ChartType.bar, chartData, chartOptions);
        } else if (
          element.type === "chart" &&
          element.chartConfig &&
          (element?.chartType === "Pie Chart" ||
            element?.chartType === "Donut Chart")
        ) {
          const chartData = element.chartConfig.data.datasets.map(
            (dataset: any) => ({
              name: dataset.label,
              labels: element.chartConfig.data.labels,
              values: dataset.data,
              chartColors: dataset.backgroundColor,
            })
          );

          const chartType =
            element?.chartType === "Donut Chart"
              ? pptx.ChartType.doughnut
              : pptx.ChartType.pie;
          slide.addChart(chartType, chartData, {
            x: element.x / 86,
            y: element.y / 96,
            legendPos: "t",
            showLegend: element.chartConfig.options.plugins.legend.display,
            holeSize: element?.chartType === "Donut Chart" ? 60 : 0,
            showTitle: element.chartConfig.options.plugins.title.display,
            title: element.chartConfig.options.plugins.title.text,
            legendFontSize: 11,
            titleFontSize: 14,
            w: element.width / 80,
            h: element.height / 80,
            chartColors:
              chartData?.length &&
              chartData[0]?.chartColors.map((color: any) => {
                return color?.slice(1, color.length);
              }),
          });
          // add center text to doughnut chart
          if (element?.chartType === "Donut Chart") {
            slide.addText(element?.content, {
              x: element.x / 86,
              y: element.y / 96,
              w: element.width / 80,
              h: element.height / 80,
              fontSize: 14,
              align: "center",
            });
          }
        } else if (element.type === "shape") {
          console.log(element, "from ppt");
          console.log(element.shape);

          slide.addShape(
            pres.ShapeType[element.shape.type as keyof typeof pres.ShapeType],
            {fill: { color: element.color  },  x: element.x / 84, // Convert from pixels to inches
            y: element.y / 96,
            w: element.width / 96,
            h: element.height / 96,  }
          );
        }
      });
    });

    pptx.writeFile({ fileName: `${data?.name}.pptx` });
    console.log(dataToPptx, "page data");
  };

  return (
    <div className="bg-primary-blue text-white mt-4 py-2 flex justify-between items-center px-3">
      <div className="flex items-center">
        <button className="bg-transparent text-sm text-white">
          Menu <i className="bi bi-chevron-down ml-2 text-white"></i>
        </button>
        <div className="w-[1px] h-[40px] bg-white ml-5"></div>
        <h3
          //contentEditable
          //onInput={changeTitle}
          className="text-white textsm font-semibold ml-5"
        >
          {data?.name}
        </h3>
      </div>
      <div>
        <button
          className="text-white border text-xs mr-3 rounded-lg py-2 bi bi-cursor px-5 border-white"
          onClick={() => {
            downloadpptx();
          }}
        >
          &nbsp; Download pptx
        </button>
        <button
          className="text-white border text-xs rounded-lg py-2 bi bi-cursor px-5 border-white"
          onClick={() => {
            setIsPreview(false);
          }}
        >
          &nbsp;Share Report
        </button>
        <button
          className="btn-light pt-2 pb-2 pl-8 pr-8 ml-3 bg-[#E8EDF4]"
          onClick={() => {
            setIsPreview(true);
            //setOpenPreviewReportModal(true);
          }}
        >
          Preview
        </button>
        <button
          onClick={handleSubmit}
          className="btn pt-2 pb-2 pl-8 pr-8 ml-3 bg-[#CC7300]"
        >
          {reportId && action === "edit" ? "Edit Report" : "Create Report"}
        </button>
      </div>
    </div>
  );
};

export default Header;
