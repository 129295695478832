import { useState } from "react";
import * as Yup from "yup";
import { OptionType } from "../hooks/types";
import { api } from "../../../../../api/report";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

export const documentTypes: OptionType[] = [
  { value: "pptx", label: "PowerPoint Presentation (.pptx)" },
  { value: "pdf", label: "PDF Document (.pdf)" },
  { value: "docx", label: "Microsoft Word (.docx)" },
  { value: "xlsx", label: "Microsoft Excel (.xlsx)" },
  { value: "csv", label: "CSV File (.csv)" },
  { value: "txt", label: "Plain Text (.txt)" },
];

export const documentSizes: OptionType[] = [
  { value: "A4", label: "A4 Portrait (8.27 x 11.69 Inches)" },
  { value: "A3", label: "A3 Portrait (11.69 x 16.54 Inches)" },
  { value: "letter", label: "Letter (8.5 x 11 Inches)" },
  { value: "legal", label: "Legal (8.5 x 14 Inches)" },
  { value: "tabloid", label: "Tabloid (11 x 17 Inches)" },
  { value: "custom", label: "Custom Size" },
];

export const unitOptions: OptionType[] = [
  { value: "pixels", label: "Pixels" },
  { value: "inches", label: "Inches" },
  { value: "centimeters", label: "Centimeters" },
  { value: "millimeters", label: "Millimeters" },
];

export const useReportModal = (
  selectedReport: any,
  setSelectedReport: any,
  openNewReportModal: any,
  setOpenNewReportModal: any
) => {
  const navigate = useNavigate();
  const [selectedDocumentType, setSelectedDocumentType] =
    useState<OptionType | null>(documentTypes[0]);
  const [selectedDocumentSize, setSelectedDocumentSize] =
    useState<OptionType | null>(documentSizes[0]);
  const [customWidth, setCustomWidth] = useState<string>("");
  const [customHeight, setCustomHeight] = useState<string>("");
  const [unit, setUnit] = useState<OptionType | null>(unitOptions[0]);
  // const [openNewReportModal, setOpenNewReportModal] = useState<boolean>(false);

  const user = JSON.parse(window.sessionStorage.getItem("user") || "{}");
  const orgId = user?.org?.id;

  const isCustomSize = selectedDocumentSize?.value === "custom";
  const isEditMode = !!selectedReport;

  const initialValues = {
    name: selectedReport?.name || "",
    description: selectedReport?.description || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name field is required"),
    description: Yup.string().required("Description field is required"),
  });

  const handleSubmit = async (
    values: { name: string; description: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(true);
    let payload = {
      name: values.name,
      description: values.description,
      type: selectedDocumentType?.value,
      size: selectedDocumentSize?.value,
      orientation: "Portrait",
      width: customWidth || null,
      height: customHeight || null,
      pixels: unit?.value || null,
      status: "draft",
    };
    try {
      let response;
      if (isEditMode) {
        // API logic for editing a report
        response = await api.updateReport({
          orgId,
          reportId: selectedReport,
          payload
        });
        toast.success(response?.message);
        setSelectedReport(null);
      } else {
        // API logic for creating a report
        response = await api.createReport({
          orgId,
          payload
        });
        // Save the response data if needed
        if (response) {
          sessionStorage.setItem("reportData", JSON.stringify(response?.data));
        }
        toast.success(response?.message);
      }
      navigate(`/admin/report/create?action=create&reportId=${response?.data?.id}&type=${
        response?.data?.type
      }` );
      setOpenNewReportModal(false);
      // Close modal or show toast after success
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Something went wrong");
    } finally {
      setSubmitting(false);
    }
  };

  return {
    selectedDocumentType,
    setSelectedDocumentType,
    selectedDocumentSize,
    setSelectedDocumentSize,
    customWidth,
    setCustomWidth,
    customHeight,
    setCustomHeight,
    unit,
    setUnit,
    isCustomSize,
    isEditMode,
    initialValues,
    validationSchema,
    handleSubmit,
    // openNewReportModal,
    // setOpenNewReportModal
  };
};
