import { DataCardType } from "../types/data.types";

export const dataCards: DataCardType[] = [
    {
        title: 'Total Learners',
        value: '',
        key: 'numberOfLearners',
        category: 'learners'
    },
    {
        title: 'Total Cohort', 
        value: '',
        key: 'numberOfCohorts',
        category: 'learners'
    },
    {
        title: 'Total Learning Paths',
        value: '',
        key: 'numberOfLearningPaths',
        category: 'learners'
    },
    {
        title: 'Total Courses',
        value: '100',
        key: 'totalCourses',
        category: 'courses'
    },
    {
        title: 'Active Courses',
        value: '',
        key: 'totalActiveCourses',
        category: 'courses'
    },
    {
        title: 'Courses Completed',
        value: '100',
        key: 'totalCompleted',
        category: 'courses'
    },
    {
        title: 'Total Courses Enrollment',
        value: '100',
        key: 'totalEnrollment',
        category: 'courses'
    },
    {
        title: 'Total Action Plans',
        value: '',
        key: 'actionPlanCount',
        category: 'action-plans'
    },
    {
        title: 'Total Action Plan Completed',
        value: '',
        key: 'actionPlanCompleted',
        category: 'action-plans'
    },
    {
        title: 'Total Action Plan Not Started',
        value: '',
        key: 'notStartedCount',
        category: 'action-plans'
    },
    {
        title: 'Total Action Plan In Progress',
        value: '',
        key: 'submitInProgressCount',
        category: 'action-plans'
    },
    {
        title: 'Active Learning Paths',
        value: '',
        key: 'activeLearningPaths',
        category: 'learningPaths'
    },
    {
        title: 'Inactive Learning Paths',
        value: '',
        key: 'inactiveLearningPaths',
        category: 'learningPaths'
    },
    {
        title: 'Opening Credit',
        value: '',
        key: 'openingCreditBalance',
        category: 'billing'
    },
    {
        title: 'Closing Credit',
        value: '',
        key: 'closingCreditBalance',
        category: 'billing'
    },
    {
        title: 'Total Payments',
        value: '',
        key: 'numberOfPayments',
        category: 'billing'
    },
    {
        title: 'Total Credit Used',
        value: '',
        key: 'usedCredit',
        category: 'billing'
    }
]
