import createApiClient from "./api";

const client = createApiClient();

export const api = {
  createContent: ({ orgId, payload }) =>
    client
      .post(`admin/org/${orgId}/contents`, payload)
      .then(({ data }) => data),

  updateContent: ({ orgId, contentId, payload }) =>
    client
      .patch(`/admin/org/${orgId}/contents/${contentId}`, payload)
      .then(({ data }) => data),
  deleteContent: ({ orgId, contentId }) =>
    client
      .delete(`/admin/org/${orgId}/contents/${contentId}`)
      .then(({ data }) => data),

  getAllContents: ({ orgId, search, perPage, currentPage,sortBy, filterBy, type }) =>
    client
      .get(
        `/admin/org/${orgId}/contents?search=${search}&perPage=${perPage}&page=${
          currentPage}&sortBy=${sortBy}&filterBy=${filterBy}${
           type ? `&type=${type}` : ''
          }`
      )
      .then(({ data }) => data),

  getAllTags: ({ orgId, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/org/${orgId}/tags?search=${search}&perPage=${perPage}&page=${currentPage}`
      )
      .then(({ data }) => data),

      createTag: ({ orgId, payload }) =>
        client
          .post(`admin/org/${orgId}/tags`, payload)
          .then(({ data }) => data),

     
    
      updateTag: ({ orgId, contentId, payload }) =>
        client
          .patch(`/admin/org/${orgId}/tags/${contentId}`, payload)
          .then(({ data }) => data),

      deleteTag: ({ orgId, contentId }) =>
        client
          .delete(`/admin/org/${orgId}/tags/${contentId}`)
          .then(({ data }) => data),



          applyMultipleAction: ({ orgId, payload }) =>
            client
              .post(`/admin/org/${orgId}/contents/multiple-action`, payload)
              .then(({ data }) => data),
  
};
