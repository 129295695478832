import React, { useState } from "react";

const SidebarControls = ({
  logo,
  setLogo,
  showLogo,
  setShowLogo,
  logoPosition,
  setLogoPosition,
  headerVisible,
  setHeaderVisible,
  headerPosition,
  setHeaderPosition,
  footerVisible,
  setFooterVisible,
  footerPosition,
  setFooterPosition,
  pageNumberPosition,
  setPageNumberPosition,
  showPageNumber,
  setShowPageNumber
}) => {
  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) setLogo(URL.createObjectURL(file));
  };

  const [isLogoOpen, setLogoIsOpen] = useState(false);
  const [isHeaderOpen, setHeaderIsOpen] = useState(false);
  const [isFooterOpen, setFooterIsOpen] = useState(false);
  const [isPageOpen, setPageIsOpen] = useState(false);

  const positions = [
    "top-left",
    "top",
    "top-right",
    "center",
    "bottom-left",
    "bottom",
    "bottom-right",
  ];
  const toggleDropdown = () => {
    setLogoIsOpen(!isLogoOpen);
  };
  const toggleHeaderDropdown = () => {
    setHeaderIsOpen(!isHeaderOpen);
  };
  const toggleFooterDropdown = () => {
    setFooterIsOpen(!isFooterOpen);
  };

  const togglePageDropdown = () => {
    setPageIsOpen(!isPageOpen);
  };

  return (
    <div className="w-1/4 p-4 bg-white">
      {/* <BrandLogoSettings /> */}
      {/* Logo Settings */}
      <div className="mb-4 border-b">
        <div
          onClick={toggleDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-base font-medium text-[#1D1F2C] mb-4">Brand Logo</h3>
          <i class="bi bi-chevron-down font-bold"></i>
        </div>
        {isLogoOpen && (
          <div className="mt-4">
            {/* Show Logo Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Brand Logo
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showLogo}
                  onChange={() => setShowLogo(!showLogo)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    showLogo ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={logoPosition}
                  onChange={(e) => setLogoPosition(e.target.value)}
                >
                  {positions.map((pos) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Logo Upload */}
            <div>
              <label className="text-gray-600 font-medium mb-2 block">
                Upload Logo
              </label>
              <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-6 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition">
                <input
                  type="file"
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                  onChange={handleLogoChange}
                />
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-10 w-10 text-blue-500 mx-auto mb-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 16v4h16v-4M16 8l-4-4m0 0L8 8m4-4v12"
                    />
                  </svg>
                  <p className="text-gray-500">Drag & drop your logo here</p>
                  <p className="text-gray-400 text-sm">
                    Maximum 5MB in size. JPG, PNG, or GIF formats.
                  </p>
                </div>
              </div>
              {/* {logoFile && (
          <img
            src={logoFile}
            alt="Uploaded Logo"
            className="mt-4 w-16 h-16 object-cover rounded-md border border-gray-300"
          />
        )} */}
            </div>
          </div>
        )}
      </div>

      {/* Header Settings */}
      <div className="mb-4 border-b">
        <div
          onClick={toggleHeaderDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-base font-medium text-[#1D1F2C] mb-2">Header</h3>
          <i class="bi bi-chevron-down"></i>
        </div>
        {isHeaderOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Title
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={headerVisible}
                  onChange={() => setHeaderVisible(!headerVisible)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    headerVisible ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={headerPosition}
                  onChange={(e) => setHeaderPosition(e.target.value)}
                >
                  {positions.map((pos) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>

       {/* Footer Settings */}
      <div className="mb-4 border-b">
        <div
          onClick={toggleFooterDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-base font-medium text-[#1D1F2C] mb-2">Footer</h3>
          <i class="bi bi-chevron-down"></i>
        </div>
        {isFooterOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
                Show Title
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={footerVisible}
                  onChange={() => setFooterVisible(!footerVisible)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    footerVisible ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={footerPosition}
                  onChange={(e) => setFooterPosition(e.target.value)}
                >
                  {positions.map((pos) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>


        {/* Page Number Settings */}
      <div className="mb-4 border-b">
        <div
          onClick={togglePageDropdown}
          className="flex justify-between items-center cursor-pointer"
        >
          <h3 className="text-base font-medium text-[#1D1F2C] mb-2">Page Number</h3>
          <i class="bi bi-chevron-down"></i>
        </div>
        {isPageOpen && (
          <div className="mt-4">
            {/* Show Header Toggle */}
            <div className="flex items-center justify-between mb-4">
              <label className="text-[#1D1F2C] font-normal text-xs">
              Show Page number
              </label>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={showPageNumber}
                  onChange={() => setShowPageNumber(!showPageNumber)}
                />
                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:bg-customBlue peer-focus:ring-2 peer-focus:ring-customBlue dark:peer-focus:ring-customBlue"></div>
                <span
                  className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-transform ${
                    showPageNumber ? "translate-x-5" : "-translate-x-0"
                  }`}
                ></span>
              </label>
            </div>
            {/* Position Dropdown */}
            <div className="mb-4">
              <label className="text-gray-600 font-medium mb-2 block">
                Position
              </label>
              <div className="relative">
                <select
                  className="w-full p-3 cursor-pointer text-gray-700 border border-gray-300 rounded-lg bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                  value={footerPosition}
                  onChange={(e) => setPageNumberPosition(e.target.value)}
                >
                  {positions.map((pos) => (
                    <option key={pos} value={pos}>
                      {pos}
                      {/* {pos.replace("-", " ").toUpperCase()} */}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
     
    </div>
  );
};

export default SidebarControls;
