import React, { useEffect, useState } from 'react';
import Step from './components/Step';
import CohortInformation from './components/CohortInformation';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import { welcomePage } from './components/templates/welcomePage';
import AddTeams from './components/AddTeams';
import AddLearningPath from './components/AddLearningPath';
import AddLearnersToCohort from './components/AddLearnersToCohort';
import CohortWelcomeMessage from './components/CohortWelcomeMessage';
import { useQuery } from 'react-query';
import { cohorts } from '../../../../../api/cohort';  

const EditCohort: React.FC = () => {

 const { id } = useParams()
 const orgId = JSON.parse(sessionStorage.getItem('user')!)?.org?.id
 const navigate: NavigateFunction = useNavigate()

   const { data, isLoading, } = useQuery(
    ["view cohort by id"],
    () => {
      return cohorts.getCohortById(orgId, id!);
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      refetchOnMount:true,
      select: (data) => data?.data,
    })

 const [currentStep, setCurrentStep] = useState<number>(1)
 const [enableTeams, setEnableTeams] = useState<boolean>(false)
 const [cohortId, setCohortId] = useState<string | null>(null)
 const [teams, setTeams] = useState<{ id: string, name: string }[]>([ { id: v4 (), name: '' } ])
 const [team, setTeam] = useState(null)
 const [status, setStatus] = useState<boolean>(true)
 const [enableLeaderBoard, setEnableLeaderBoard] = useState<boolean>(true)
 const [ mapLearnerMethod, setMapLearnerMethod ] = useState<number>(1)
 const [value, setValue] = useState<number>(1)
 const [selectedTeams, setSelectedTeams] = useState<any[]>([])
 const [selectedJourney, setSelectedJourney] = useState(null)
 const [learners, setLearners] = useState<any[]>([])
 const [defaultLearners, setDefaultLearners] = useState([])
 const [checked, setChecked] = useState<boolean>(true)
 const [duplicateLearners, setDuplicateLearners] = useState([])
 const [invalidLearners, setInvalidLearners] = useState<any[]>([])
 const [welcomePageContent, setWelcomePageContent] = useState<string>(welcomePage)

 useEffect( () => {
    const elements = document.querySelectorAll('*')
    elements.forEach( element => {
       element.scrollTo({top: 0, behavior: 'instant'})
    }) 
  }, [currentStep])

 const increment = () : void => {
    setValue(value + 1 )
 }

 const decrement = () : void =>  {
    if(value > 1 ) {
        setValue(value - 1 )
    }
 }

 const back = () => {
    if( currentStep > 1 && currentStep <= 6){
     setCurrentStep(currentStep - 1)
    }
    else navigate('/admin/cohort/')
 }

  return (
    <div className='p-5'> 
        <header className='flex justify-between'>
          <nav className='mt-1 flex items-center'>
                Step {currentStep} of 4 &nbsp;&nbsp; &nbsp;&nbsp; 
                <Step color={ currentStep >= 1 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 2 ? 'bg-primary' : null } /> 
                <Step color={ currentStep >= 3 ? 'bg-primary' : null } />
                <Step color={ currentStep >= 4 ? 'bg-primary' : null } />
           </nav>
           <button onClick={back} className='btn bg-primary-blue pl-8 pr-8'>
              Back
           </button>
        </header>
         { (currentStep === 1 && !enableTeams) &&
            <CohortInformation 
            enableTeams={enableTeams} setEnableTeams={setEnableTeams}
            setCurrentStep={setCurrentStep} cohortId={cohortId}
            setCohortId={setCohortId} status={status} setStatus={setStatus}
            data={data}
        />}
        {
                (currentStep === 1 && enableTeams) &&
                 <AddTeams 
                     setCurrentStep={setCurrentStep} status={status} cohortId={cohortId}
                     setStatus={setStatus} teams={teams} setTeams={setTeams}
                     team={team} setTeam={setTeam} enableLeaderBoard={enableLeaderBoard}
                     setEnableLeaderBoard={setEnableLeaderBoard} value={value}
                     mapLearnerMethod={mapLearnerMethod} increment={increment}
                     setMapLearnerMethod={setMapLearnerMethod} decrement={decrement}
                     selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams}
                     cohort={data} setValue={setValue}
                />
         }
          {
            currentStep === 2 &&
            <CohortWelcomeMessage
               setCurrentStep={setCurrentStep} 
               cohortId={data?.id}
               content={welcomePageContent}
               setContent={setWelcomePageContent}
            />
         }
         {
            currentStep === 3 &&
            <AddLearnersToCohort
               setCurrentStep={setCurrentStep} cohortId={cohortId} learners = {learners}
               setLearners={setLearners} defaultLearners ={defaultLearners}
               setDefaultLearners={setDefaultLearners} checked={checked}
               setChecked={setChecked} duplicateLearners={duplicateLearners}
               setDuplicateLearners={setDuplicateLearners} invalidLearners={invalidLearners}
               setInvalidLearners={setInvalidLearners} cohort={data}
            />
         }
         {
            currentStep === 4 &&
            <AddLearningPath 
               setCurrentStep={setCurrentStep}
               selectedJourney={selectedJourney}
               setSelectedJourney={setSelectedJourney}
               cohortId={cohortId} cohort={data}
            />
         }
    </div>
  )
}

export default EditCohort