import React, { useState } from 'react';
import StarterPage from '../../components/StarterPage';
import { useQuery } from 'react-query';
import { api as courses } from '../../../../../../api/courses'; 
import Summary from './components/Summary';
import CoursesTable from './components/CoursesTable';
import Pagination from '../../../../../../components/pagination/Pagination';
import { Link } from 'react-router-dom';
import Filter from './components/Filter';

const ViewCourses = () => {

  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [status, setStatus] = useState<'published' | 'draft' | null>(null)
  const [ category, setCategory ] = useState<string | null>(null)
  const [touched, setTouched] = useState<boolean>(false)

  const { data: categories,} = useQuery(
    ["get categories"],
    () => {
      return courses.getCategories({ perPage: 10000 });
    },
    {
      cacheTime: 10000,
      staleTime: 10000,
      refetchOnWindowFocus: true,
      select: (data: any) => data?.data,
    }
  );

  const { data: coursesSummary, isLoading: coursesSummaryLoading } = useQuery(
    ["get courses summary"],
    () => {
      return courses.getCoursesSummary();
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data?.data,
    }
  );

  const { data: courses_, isLoading, refetch } = useQuery(
    ["get courses", search, perPage, currentPage, status, category ],
    () => {
      return courses.getCourses({ search, perPage, currentPage, status, category });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: true,
      select: (data) => data,
      onSuccess: (response: any) => {
        setTouched(true)
        if(response?.meta?.total) setIsEmpty(false)
        else setIsEmpty(true)
      }
    }
  );

  return (
    <div className='p-5'> 
         {
           (!isEmpty || touched) ?
              <section>
                     <Summary coursesSummary={coursesSummary} />
                 <header className='flex justify-between items-center mt-3 mb-5'>
                       <h5 className='text-sm font-semibold'>
                          Courses Overview 
                       </h5>
                        <div className='flex items-center'>
                          <Filter
                             status={status} setStatus={setStatus} categories={categories}
                             category={category} setCategory={setCategory}
                           />
                          <input 
                            type="search" 
                            className='input-style bg-primaryGray w-[400px] ml-4'
                            placeholder='&#128269; Search courses....'
                            onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                              setSearch(event.target.value)
                            }}
                          />
                          <Link to={'/admin/course/create'} className='btn bg-primary-blue ml-5'>
                              Create New Course 
                           </Link>
                        </div>
                    </header>
                  <section className='bg-white p-3 rounded-lg'>
                    <CoursesTable isLoading={isLoading} courses={courses_} perPage={perPage} />
                    <Pagination
                    data={courses_?.meta}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                  />
                  </section>
              </section>
            :
            <StarterPage />
         }
    </div>
  )
}

export default ViewCourses