import React, { useState, useRef } from "react";
import { useReportEditor } from "../../hooks/ReportEditorContext";

const Text = ({ 
  activePageId, elementSetter, pageStructure, 
  setPageStructure, activeElementId,
}) => {
  
  const {
    elements,
    setElements,
    // elementSetter,
    addElement,
    activeElementIndex,
    updateActiveElement,
  } = useReportEditor();

  const getActiveElement = () => {  
    return elements?.find( element => element?.elementId === activeElementId )
  }

  const updateCanvasTextElement = (key, value) => {
    elementSetter(
      activePageId, 
      updateActiveElement(
         key,value,
         activeElementId, activePageId
        ), 
      setPageStructure
   )
  }

  return (
    <div className="text-slate-900">
      {/* Toolbar */}
      <div className="p-4 border-r">
        <div className="flex flex-col justify-start">
          <div className="flex items-center gap-3 mb-4">
            <div>
              <i className="bi bi-plus-lg text-[25px]"></i>
            </div>
            <button
              className="bg-white text-[#1D1F2C] text-[25px] font-black w-fit"
              onClick={() =>{
                 elementSetter(activePageId,  addElement("heading", activePageId), setPageStructure)
                }}
            >
              Add Heading
            </button>
          </div>
          <div className="flex gap-3 mb-4">
            <div>
              <i className="bi bi-plus-lg text-[18px]"></i>
            </div>
            <button
              className="mb-4  bg-white text-[#1D1F2C] text-[18px] font-medium w-fit"
              onClick={() =>{
                //  addElement("subheading")
                 elementSetter(activePageId,  addElement("subheading", activePageId), setPageStructure)
 
                }}
            >
              Add Subheading
            </button>
          </div>
          <div className="flex gap-3">
            <div>
              <i className="bi bi-plus-lg text-[14px]"></i>
            </div>
            <button
              className="mb-4 bg-white text-[#1D1F2C] text-[14px] font-light w-fit"
              onClick={() => {
                // addElement("body")
                elementSetter(activePageId,  addElement("body", activePageId), setPageStructure)
              }}
            >
              Add Body Text
            </button>
          </div>
        </div> 

        {/* Toolbar for editing the active element */}
        <div className="mt-6">
          {activeElementIndex !== null && elements[activeElementIndex] && (
            // {activeElementIndex !== null && (
            <div>
              {/* Font Size */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Font Size (px)
                </label>
                <input
                  type="number"
                  value={getActiveElement()?.fontSize}
                  onChange={(e) => {
                    updateCanvasTextElement("fontSize", e.target.value)
                  }}
                  className="px-2 py-1 border border-gray-300 focus:outline-none rounded-md w-full"
                />
              </div>
              {/* Font Color */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Font Color
                </label>
                <input
                  type="color"
                  value={getActiveElement()?.color}
                  onChange={(e) => updateCanvasTextElement("color", e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Background Color 
                </label>
                <input
                  type="color"
                  value={getActiveElement()?.backgroundColor || "#ffffff"}
                  onChange={(e) => updateCanvasTextElement("backgroundColor", e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Border Color 
                </label>
                <input
                  type="color"
                  value={getActiveElement()?.borderColor || "transparent"}
                  onChange={(e) => updateCanvasTextElement("borderColor", e.target.value)}
                  className="px-2 py-1 border border-gray-300 rounded-md w-full"
                />
              </div>
              <div>
                <label className="block mt-4 text-sm font-medium text-gray-700 mb-2">
                  Border Width (px)
                </label>
                <input
                  type="number"
                  value={getActiveElement()?.borderWidth}
                  onChange={(e) => {
                    updateCanvasTextElement("borderWidth", e.target.value)
                  }}
                  className="px-2 py-1 border border-gray-300 focus:outline-none rounded-md w-full"
                />
              </div>
              <div>
                <label className="block mt-4 text-sm font-medium text-gray-700 mb-2">
                  Border Radius (px)
                </label>
                <input
                  type="number"
                  value={getActiveElement()?.borderRadius}
                  onChange={(e) => {
                    updateCanvasTextElement("borderRadius", e.target.value)
                  }}
                  className="px-2 py-1 border border-gray-300 focus:outline-none rounded-md w-full"
                />
              </div>
              {/* Font Weight */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Border Style
                </label>
                <select
                  value={getActiveElement()?.borderStyle}
                  onChange={(e) =>
                    updateCanvasTextElement("borderStyle", e.target.value)
                  }
                  className="px-2 py-1 border border-gray-300  rounded-md w-full"
                >
                  <option value="none">None</option>
                  <option value="dotted">Dotted</option>
                  <option value="solid">Solid</option>
                  <option value="double">Double</option>
                </select>
              </div>
              {/* Font Weight */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Font Weight
                </label>
                <select
                  value={getActiveElement()?.fontWeight}
                  onChange={(e) =>
                    updateCanvasTextElement("fontWeight", e.target.value)
                  }
                  className="px-2 py-1 border border-gray-300  rounded-md w-full"
                >
                  <option value="normal">Normal</option>
                  <option value="bold">Bold</option>
                </select>
              </div>

              {/* Font Style */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Font Style
                </label>
                <select
                  value={getActiveElement()?.fontStyle}
                  onChange={(e) =>
                    updateCanvasTextElement("fontStyle", e.target.value)
                  }
                  className="px-2 py-1 border border-gray-300 rounded-md w-full"
                >
                  <option value="normal">Normal</option>
                  <option value="italic">Italic</option>
                </select>
              </div>

              {/* Font Family */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Font Family
                </label>
                <select
                  value={getActiveElement()?.fontFamily}
                  onChange={(e) =>
                    updateCanvasTextElement("fontFamily", e.target.value)
                  }
                  className="px-2 py-1 border border-gray-300 rounded-md w-full"
                >
                  <option value="calibri">Calibri</option>
                  <option value="arial">Arial</option>
                  <option value="arial black">Arial Black</option>
                  <option value="times new roman">Times New Roman</option>
                  <option value="georgia">Georgia</option>
                </select>
              </div>

              {/* Text Alignment */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Text Alignment
                </label>
                <div className="flex space-x-2">
                  <button
                    onClick={() => updateCanvasTextElement("textAlign", "left")}
                    className={` px-3 py-1 ${getActiveElement()?.textAlign === "left" ? "bg-[#1F456C] text-white" : "bg-gray-300  rounded"}`}
                  >
                    <i class="bi bi-justify-left"></i>
                  </button>
                  <button
                    onClick={() => updateCanvasTextElement("textAlign", "center")}
                    className={` px-3 py-1 ${getActiveElement()?.textAlign === "center" ? "bg-[#1F456C] text-white" : "bg-gray-300  rounded"}`}
                  >
                    <i class="bi bi-text-center"></i>
                  </button>
                  <button
                    onClick={() => updateCanvasTextElement("textAlign", "right")}
                    className={` px-3 py-1 ${getActiveElement()?.textAlign === "right" ? "bg-[#1F456C] text-white" : "bg-gray-300  rounded"}`}
                  >
                    <i class="bi bi-justify-right"></i>
                  </button>
                  <button
                    onClick={() => updateCanvasTextElement("textAlign", "justify")}
                    className={` px-3 py-1 ${getActiveElement()?.textAlign === "justify" ? "bg-[#1F456C] text-white" : "bg-gray-300  rounded"}`}
                  >
                    <i class="bi bi-justify"></i>
                  </button>
                </div>
              </div>

              {/* Letter Spacing */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Letter Spacing
                </label>
                <input
                  type="range"
                  min="0"
                  max="10"
                  step="0.1"
                  value={getActiveElement()?.letterSpacing || 0} // Fallback to 0
                  onChange={(e) =>
                    updateCanvasTextElement(
                      "letterSpacing",
                      parseFloat(e.target.value)
                    )
                  }
                   className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>

              {/* Line Height */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Line Height
                </label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={getActiveElement()?.lineHeight || 1.5} // Fallback to 1.5
                  onChange={(e) =>
                    updateCanvasTextElement(
                      "lineHeight",
                      parseFloat(e.target.value)
                    )
                  }
                   className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>

              {/* Text Opacity */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Text Opacity
                </label>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={getActiveElement()?.opacity}
                  onChange={(e) =>
                    updateCanvasTextElement("opacity", e.target.value)
                  }
                  className="w-full appearance-none h-2 bg-[#1F456C] rounded-full"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Text;
