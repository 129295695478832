import createApiClient from "./api";

const client = createApiClient();

export const api = {
  getLearners: ({ id, search, perPage, currentPage }) =>
    client
      .get(
        `/admin/org/${id}/reports/learners?perPage=${perPage}${
          search ? `&search=${search}` : ""
        }&page=${currentPage}`
      )
      .then(({ data }) => data),

    getIndividualLearnerSummary: () => client
    .get(`/admin/org/2a5838d5-426c-482c-8f25-981ce33c6d04/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/courses/summary`).then(({ data }) => data),

    getIndividualLearnerCourses: ({ orgId, userId}) => client
    .get(`admin/org/097f1bd8-409d-46a6-8b68-281f1a68b4fa/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/courses/`).then(({ data }) => data),

    getIndividualLearnerAssessment: ({ orgId, userId}) => client
    .get(`admin/org/097f1bd8-409d-46a6-8b68-281f1a68b4fa/reports/learners/a2beb141-0e9b-455d-8171-e0bab15403d0/assessments/`).then(({ data }) => data),

  generateReport: (payload) => client.post(`admin/roles`, payload).then(({ data }) => data),
 
   getLearnersSummary: ({ id, search, perPage, currentPage }) =>
        client
          .get(
            `/admin/org/${id}/reports/learners/summary?perPage=${perPage}${
              search ? `&search=${search}` : ""
            }&page=${currentPage}`
          )
          .then(({ data }) => data),
  
   getTopPerformingCourses: ({ id, search, perPage, currentPage }) =>
            client
              .get(
                `/admin/org/${id}/reports/top-performing-course?perPage=${perPage}${
                  search ? `&search=${search}` : ""
                }&page=${currentPage}`
              )
              .then(({ data }) => data),

  getCourseSummary: ({id}) => client.get(`admin/org/${id}/reports/courses/summary`).then(({ data }) => data),
   
  getCompletionSummary: ({id}) => client.get(`admin/org/${id}/reports/course-completion/summary`).then(({ data }) => data),


  createReport: ({orgId, payload}) => client.post(`admin/org/${orgId}/report-editor`, payload).then(({ data }) => data),

  updateReport: ({orgId,reportId, payload}) => client.patch(`admin/org/${orgId}/report-editor/${reportId}`, payload).then(({ data }) => data),

  deleteReport: ({orgId,reportId}) => client.delete(`admin/org/${orgId}/report-editor/${reportId}`).then(({ data }) => data),

  getReportSummary: ({orgId}) => client.get(`admin/org/${orgId}/report-editor/get/summary`).then(({ data }) => data),
  
  getReport: ({orgId, reportId}) => client.get(`/admin/org/${orgId}/report-editor/${reportId}`).then(({ data }) => data),

  getAllReports: ({orgId, search, perPage, currentPage}) => client.get(`admin/org/${orgId}/report-editor?search=${search}&perPage=${perPage}&page=${currentPage}`).then(({ data }) => data),
  // getAllReports: ({orgId}) => client.get(`admin/org/${orgId}/report-editor`).then(({ data }) => data),

  // getReportCourseCompletion: (paload) =>
  //   client
  //     .get(
  //       `/report-data?cohortIds=30a1cc25-94cb-479d-bf17-786d954d4603,06fa0bbb-64e6-4705-95df-341e23bb4d3d&type=chart&slug=courseCompletion&chart=barchart`
  //     )
  //     .then(({ data }) => data),

    getReportCohortCompletion: ({cohortIds, type, slug, chart}) =>
      client
        .get(
          `/report-data?cohortIds=${cohortIds}&type=${type}&slug=${
            slug
          }&chart=${chart}`
        )
        .then(({ data }) => data),

      getReportCompletionData: ({cohortIds, slug,}) =>
          client
            .get(
              `/report-data?cohortIds=${cohortIds}&type=data&slug=${
                slug
              }`
            )
            .then(({ data }) => data),
    
    // (paload) =>
    //   client
    //     .get(
    //       `/report-data?cohortIds=30a1cc25-94cb-479d-bf17-786d954d4603&type=chart&slug=cohortEnrollment&chart=piechart`
    //     )
    //     .then(({ data }) => data),
      getReportCourseCompletion: ({cohortIds, type, slug, chart}) =>
        client
          .get(
            `/report-data?cohortIds=${cohortIds}&type=${type}&slug=${
              slug
            }&chart=${chart}`
          )
          .then(({ data }) => data),
}
