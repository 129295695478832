import React, { useState, useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from '../../../../../../../../components/Buttons/Button';
import CenteredModal from '../../../../../../../../components/Modal/CenteredModal';
import { useQuery, useMutation } from 'react-query';
import { api } from '../../../../../../../../api/courses';
import { api as billingApi } from '../../../../../../../../api/billing';
import CustomDropdown from '../../../../../../../../components/dropdown/CustomDropdown';
import toast from 'react-hot-toast';
import { useCustomToast } from '../../../../../../../../components/customtoast/CustomToastProvider';
import CompanyRoleButton from '../../../../../../../Auth/components/CompanyRoleButton';

const CreateCreditCode = ({openModal, setOpenModal, refetch, activeCourseCode, setActiveCourseCode }) => {

  const customToast = useCustomToast()
  const [category, setCategory ] = useState(null) 
  const [status, setStatus] = useState(true)
  const [ categories_ , setCategories ] = useState([])
  const [categoryError, setCategoryError] = useState('')
  const [courses_, setCourses] = useState([])
  const [coursesError, setCoursesError] = useState('')

  const initialValues = {
     creditCode: activeCourseCode === null ? "" : activeCourseCode?.creditCode,
     credit: activeCourseCode === null ? "" : activeCourseCode?.credit,
     description: activeCourseCode === null ? "" : activeCourseCode?.description,
     categoryId: activeCourseCode === null ? category !== null ? category?.value : "" : 
                 activeCourseCode?.category?.id
  };

  useEffect( () => {
     if(activeCourseCode !== null){
        setCategory({ 
            label: activeCourseCode?.category?.name, 
            value: activeCourseCode?.category?.id 
          })
     }
  }, [activeCourseCode] )

  const addCourseCodeMutation = useMutation( 
     activeCourseCode === null ? 
      billingApi.createCourseCreditCodes : 
      billingApi.updateCourseCreditCodes, 
    {
    onSuccess: (response) => {
        toast.remove();
        toast.success(response?.message);
        refetch()
        setOpenModal(false)
        setActiveCourseCode(null)
        setCategories([])
        setCourses([])
    },
    onMutate: () => {
        toast.loading("Loading...");
    },
    onError: ({ response }) => {
        toast.remove();
        toast.error(response.data?.message)
        setOpenModal(true)
    },
   });

  const { data: categories, isLoading } = useQuery(
    ["get categories"],
    () => {
        return api.getCategories({ perPage: 1000 });
    },
    {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data?.data,
    }
   );

   const { data: courses, isLoading: coursesIsLoading } = useQuery(
    ["get courses"],
    () => {
        return api.getCourses({perPage: 10000, currentPage: 1});
    },
    {
        cacheTime: 10000,
        staleTime: 10000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        select: (data) => data?.data,
    }
   );

  const validate = (values) => {
   
    const errors = {}

    if(!values?.creditCode){
      errors.creditCode = 'Error! credit code is required.'
    }
    else if(!values?.credit){
      errors.credit = 'Error! credit is required.'
    }
    else if(!values?.description){
        errors.description = 'Error! description field is required.'
    }
   // else if(!values?.categoryId){
     //   errors.categoryId = 'Error! category is required.'
    //}
    return errors;
  };

  const createCourseCode = (values, actions) => {
    actions.setSubmitting(false);
    if(!status){
        if(!categories_.length){
           setCategoryError('Error! please add atleast one category.')
           return;
        }
        else {
          setCategoryError('')
        }
    }
    if(status){
       if(!courses_.length){
         setCoursesError('Error! please add atleast on courses.')
         return;
       } else {
         setCoursesError('')
       }
    }
  
    const payload = !status ? 
                    { ...values, categoryIds: categories_?.map( ctr => ctr?.value ), courseIds: [] } 
                     :
                    { ...values, courseIds: courses_?.map( ctr => ctr?.value ), categoryIds: [] }

    if(activeCourseCode === null) {
      addCourseCodeMutation.mutate(payload)
    }
    else addCourseCodeMutation.mutate({ id: activeCourseCode?.id, data: payload })
  }

  return (
    <CenteredModal 
        title={
           <h5 className='text-md font-semibold bi bi-plus-circle'>
              &nbsp; {activeCourseCode === null ? 'Create' : 'Edit'} Course Credit Code
          </h5>
         }
        open={openModal}
        setOpen={setOpenModal}
        style={{padding: 0}}
        width={'w-[450px] h-[450px] overflow-y-auto'}
        headerClassName={'shadow-md flex justify-between px-3 py-4 sticky'}
        withCloseBtn={false}
      >
         <section className='text-left pb-3 px-5'>
         <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={createCourseCode}
          enableReinitialize
        >
          {({ setFieldValue }) => (
            <Form>
                <h3 className='text-md font-medium'>
                    New Course Credit Code   
                </h3>
                <p className='text-xs font-bold text-blue-700 mt-1 pb-2 border-b border-gray-300'>
                   Step 1: fill in credit code details
                </p>
                <section className='grid grid-cols-2 gap-5'>
                     <div className='mt-2'>
                        <label htmlFor="course-enrolled" className='text-xs'>
                            Credit Code <i className='text-red-500 text-xs ml-1'></i>
                        </label>
                        <Field name='creditCode' className='input-md bg-[#FBFBFB]' />
                        <p className='text-xs text-red-500 mt-2'>
                         <ErrorMessage name='creditCode' />
                       </p>
                    </div>
                    <div className='mt-2'>
                        <label htmlFor="course-enrolled" className='text-xs'>
                            Credit <i className='text-red-500 text-xs ml-1'></i>
                        </label>
                        <Field type='number' name='credit' className='input-md bg-[#FBFBFB]' />
                        <p className='text-xs text-red-500 mt-2'>
                          <ErrorMessage name='credit' />
                        </p>
                    </div>
                </section>
                <div className='mt-2'>
                        <label htmlFor="description" className='text-xs'>
                            Description <i className='text-red-500 text-xs ml-1'></i>
                        </label>
                        <Field as='textarea' name='description' className='input-md bg-[#FBFBFB]' />
                        <p className='text-xs text-red-500 mt-2'>
                          <ErrorMessage name='description' />
                        </p>
                </div>
                <p className='text-xs font-bold text-blue-700 mt-1 pb-2 border-b border-gray-300'>
                  Step 2: Apply Credit code to selection 
                </p>
                <section className='grid grid-cols-1 gap-5'>
                     <div>
                         <span className='block pt-3 text-xs'>
                             Apply To
                         </span>
                          <div className='flex pt-2 items-center'>
                              <CompanyRoleButton 
                                  text={'Courses'}
                                  height={'h-[30px]'}
                                  active={status}
                                  onClick={ () => setStatus(true) }
                              />
                              &nbsp; &nbsp;
                              <CompanyRoleButton 
                                  text={'Categories'}
                                  height={'h-[30px]'}
                                  active={status === false}
                                  onClick={ () => setStatus(false) }
                              />
                        </div>
                     </div>
                    {
                      !status ? 
                        <div className='mt-0'>
                          <label htmlFor="course-enrolled" className='text-xs'>
                              Select Category <i className='text-red-500 text-xs ml-1'></i>
                          </label>
                          <CustomDropdown
                                value={categories_}
                                name={'category'}
                                multiSelect
                                width={'w-full'}
                                options={
                                categories?.length ?  categories?.map( ctr => {
                                        return {label: ctr.name, value: ctr?.id  }
                                })
                                : []
                                }
                                onChange={
                                  values => {
                                    setCategories(values)
                                     if(values?.length) setCategoryError('')
                                  }
                                }
                                placeholder={'Select Category'}
                            />
                          <p className='text-xs text-red-500 mt-2'>
                             {categoryError}
                          </p>
                        </div>
                      : 
                      <div className='mt-0'>
                        <label htmlFor="course-enrolled" className='text-xs'>
                           Select Courses <i className='text-red-500 text-xs ml-1'></i>
                        </label>
                        <CustomDropdown
                              value={courses_}
                              name={'courses'}
                              multiSelect
                              width={'w-full'}
                              options={
                              courses?.length ?  courses?.map( ctr => {
                                return {label: ctr.name, value: ctr?.id  }
                              })
                              : []
                              }
                              onChange={
                                values => {
                                  setCourses(values)
                                  if(values?.length)setCoursesError('')
                                }
                              }
                              placeholder={'Select Courses'}
                          />
                        <p className='text-xs text-red-500 mt-2'>
                          {coursesError}
                        </p>
                    </div>
                    }
                </section>
               <Button 
                  bgColor={'bg-darkBlue'} width={'w-[300px] mx-auto mt-4'}
                  text={'Create Course Credit Code'}
                  btn_type='submit'
                />
            </Form>
         )}
      </Formik>
         </section>
      </CenteredModal>
  )
}

export default CreateCreditCode