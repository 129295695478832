import React, { useState, useEffect } from 'react'
import leftAngle from '../../../../../../assets/svgs/left-angle.svg'
import BarchartInputTable from './chartsDataTable/BarchartInputTable'
import { useReportEditor } from '../../hooks/ReportEditorContext'
import { tableData } from '../CreateReport'
import ImportData from './dataImports/ImportBarChartData'
import ChartSettings from './chartsSettings/ColumnBarChartSettings'
import { charts, initDataTable, initPieChartDataTable, initProgressChartDataTable, initStackedBarChartDataTable } from '../utils/charts.utils'
import { BarChartTitles, ChartElement, OtherChartType, pieChartType,
  chartType as activeChartType,
  ChartsWidgetProps
 } from '../types/charts.types'
import StackedBarChartDataTable from './chartsDataTable/StackedBarChartDataTable'
import ImportStackedBarChartData from './dataImports/ImportStackedBarChartData'
import StackedBarChartSettings from './chartsSettings/StackedBarChartSettings'
import ProgressChartDataTable from './chartsDataTable/ProgressChartDataTable'
import { Chart as ChartJS, ArcElement, LineElement, PointElement, CategoryScale, LinearScale, Filler } from 'chart.js';
import PieChartDataTable from './chartsDataTable/PieChartDataTable'
import ImportPieChartData from './dataImports/ImportPieChartData'
import ImportBarChartData from './dataImports/ImportBarChartData'
import ColumnBarChartSettings from './chartsSettings/ColumnBarChartSettings'
import OtherChartsSettings from './chartsSettings/OtherChartsSettings'
import useChartsData from '../utils/useChartsData'

ChartJS.register(
  ArcElement, 
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Filler
);

type chart = {
    id: string
    title: string
    image: string
}

export type chartType = {
    barchart: chart[],
    piechart: chart[],
    otherchart: chart[]
}

export type ChartActiveTab = 'settings' | 'chart data' | 'import data' 

type selectedChartType = null | 'barchart' | 'piechart' | 'otherchart'


export type FontStyleType = 'italic' | 'normal' | 'oblique'
export type FontFamilyType = 'sans-serif' | 'serif' | 'monospace' | 'Public Sans' | 'Noto Sans'
export type BarChartColorType = 'single' | 'distinct'

const ChartsWidget: React.FC<ChartsWidgetProps> = ({
  tableData, setTableData, activeBarchart, setActiveBarchart,
  stackedBarChartTableData, setStackedBarChartTableData,
  progressChartTableData, setProgressChartTableData,
  activePieChart, setActivePieChart, setCenterText, centerText,
  pieChartTableData, setPieChartTableData, setShowDhoughntuCenterText,
  activeOtherChart, setActiveOtherChart, showDhoughntuCenterText,
  otherChartTableData, setOtherChartTableData,
  dataCards, setDataCards, activePageId,
   elementSetter, pageStructure,  yAxisLabel,
  setPageStructure, activeElementId,
  activeChart, activeTab, barChartColor, barChartColorType,barChartFontFamily,
  barChartFontStyle,otherChartBackgroundColor,barChartTitle,otherChartColor,
  legendPosition,otherChartFontFamily,otherChartFontStyle,otherChartLineColor,pieChartColor,
  otherChartDotColor,pieChartColorType,pieChartFontFamily,otherChartTitle,pieChartLegendPosition,
  pieChartFontStyle,pieChartTitle,progressChartFontFamily,progressChartFontStyle,
  progressChartColor,pyramidChartTitle,pyramidChartColor,progressChartTitle,setActiveTab,
  setActiveChart,setBarChartColor,setBarChartColorType,setBarChartTitle, setBarchartFontStyle,
  setBarchartFontFamily,setOtherChartBackgroundColor,setLegendPosition,
  setOtherChartColor, setOtherChartDotColor, setOtherChartFontFamily, setOtherChartFontStyle,
  setOtherChartLineColor, showStackedBarChartLegend, setOtherChartTitle, setPieChartColor, 
  setPieChartFontFamily, setPieChartFontStyle, setPieChartColorType, setPieChartLegendPosition, 
  setProgressChartColor, setProgressChartFontStyle,setPieChartTitle,setProgressChartTitle,
  setProgressChartFontFamily, setPyramidChartColor,setPyramidChartTitle, setShowBarChartTitle,
  setShowBarChartLegend,setShowPieChartLegend,setShowPieChartTitle,setShowStackedBarChartLegend,
  setShowOtherChartTitle,setShowProgressChartTitle,setShowStackedBarChartTitle,setShowYAxisLabel,
  setShowXAxisLabel,setStackedBarChartColorType,setStackedBarChartFontFamily,setStackedBarChartFontStyle,
  setStackedBarChartTitle,setStackedLegendPosition,setXAxisLabel,setYAxisLabel,showBarChartLegend,showBarChartTitle,
  setStackedBarChartColor,showOtherChartTitle,showPieChartLegend,showProgressChartTitle,showStackedBarChartTitle,
  showPieChartTitle, showXAxisLabel,stackedBarChartColor,stackedBarChartFontFamily,stackedBarChartColorType,
  showYAxisLabel,stackedBarChartFontStyle,stackedBarChartTitle,stackedLegendPosition,xAxisLabel,
}): JSX.Element => {

 const [selectedChart, setSelectedChart] = useState<selectedChartType>(null)
 const [openTable, setOpenTable] = useState<boolean>(false)
 const { addElement, elements, setElements, updateActiveElement, } = useReportEditor();
 const chartTableTabs: ChartActiveTab[] = ['chart data', 'import data', 'settings']
 const {
  getCourseCompletionBarChartLoading
} = useChartsData()

console.log('chart widget', Math.random());

 const updateChartElement = (key: string, value: any) => {
  elementSetter(
    activePageId, 
    updateActiveElement(
       key,value,
       activeElementId, activePageId
      ), 
    setPageStructure
 )
}

// get chart data from active element
// useEffect( () => {
//   //dont get data if source is API
//   setTimeout( () => {
//     const activeElement = elements?.find( (elem: any) => elem?.elementId === activeElementId )
//     //console.log({activeElement}, 'active elemtn'); 
//     if(activeElement){
//        //alert('yes active element')
//         if(activeElement?.type === 'chart'){
//           const chartType = activeElement?.chartType
//           switch(chartType){
//              case "Column Bar Chart" :
//              case 'Horizontal Bar Chart' :
//               const chartObject = activeElement
//               setTableData({...initDataTable,
//                 body: chartObject.chartConfig.data.labels.map((label: any, index: number) => [
//                   index + 1, // Serial number
//                   label, // Chart label
//                   chartObject.chartConfig.data.datasets[0].data[index] // Corresponding chart value
//                 ])
//               })
//               setBarChartColor(chartObject.chartConfig.data.datasets[0]?.backgroundColor?.map(
//                 (color: string) => color
//               ))
//                setOpenTable(true)
//                setActiveBarchart(activeElement.chartType as BarChartTitles)
//                setActiveChart('barchart')
//                setSelectedChart('barchart')
//             break;
//             case 'Stacked Column Bar Chart':
//             case 'Stacked Horizontal Bar Chart':
//               const stackedBarChartDataTable = {
//                 headers: [0,0,...activeElement?.chartConfig.data.datasets].map?.( (header: any, index: number) => {
//                   if(index === 0){
//                     return 'S/N'
//                   }
//                   else if(index === 1){
//                     return 'Labels'
//                   }
//                   else return `Series ${index}`
//                 }),
//                 body: activeElement.chartConfig.data.labels.map((label: any, index: number) => {
//                   const row = [
//                     index + 1, 
//                     label, 
//                     ...activeElement.chartConfig.data.datasets.map(
//                       (dataset: any) => dataset.data[index] // Data for each series
//                     ),
//                   ];
//                   return row;
//                 }),
//               };
//                 setStackedBarChartTableData({...stackedBarChartDataTable});
//                 setOpenTable(true)
//                 setActiveBarchart(activeElement.chartType as BarChartTitles)
//                 //  setActiveChart('barchart')
//                 setSelectedChart('barchart')
//             break;
//             case "Pie Chart":
//             case 'Donut Chart':
//               const pieChartObject = activeElement
//               setPieChartTableData({...initPieChartDataTable,
//                 body: pieChartObject.chartConfig.data.labels.map((label: any, index: number) => [
//                   index + 1, // Serial number
//                   label, // Chart label
//                   pieChartObject.chartConfig.data.datasets[0].data[index] // Corresponding chart value
//                 ])
//               })
//               setPieChartColor(pieChartObject.chartConfig.data.datasets[0]?.backgroundColor?.map(
//                 (color: string) => color
//               ))
//                  setActivePieChart(chartType)
//                  setActiveChart('piechart')
//                  setSelectedChart('piechart');
//                  setOpenTable(true);
//             break;
//           }
//         }
//      }
//   }, 10)
// }, [activeElementId, barChartColorType])
useEffect(() => {
  // Declare a timeout variable
  let timeoutId: NodeJS.Timeout;

  if (activeElementId) {
    timeoutId = setTimeout(() => {
      const activeElement = elements?.find((elem: any) => elem?.elementId === activeElementId);
      if (activeElement) {
        if (activeElement?.type === 'chart') {
          const chartType = activeElement?.chartType;
          switch (chartType) {
            case "Column Bar Chart":
            case 'Horizontal Bar Chart':
              const chartObject = activeElement;
              setTableData({
                ...initDataTable,
                body: chartObject.chartConfig.data.labels.map((label: any, index: number) => [
                  index + 1, // Serial number
                  label, // Chart label
                  chartObject.chartConfig.data.datasets[0].data[index], // Corresponding chart value
                ]),
              });
              setBarChartColor(chartObject.chartConfig.data.datasets[0]?.backgroundColor?.map(
                (color: string) => color
              ));
              setOpenTable(true);
              setActiveBarchart(activeElement.chartType as BarChartTitles);
              setActiveChart('barchart');
              setSelectedChart('barchart');
              break;

            case 'Stacked Column Bar Chart':
            case 'Stacked Horizontal Bar Chart':
              const stackedBarChartDataTable = {
                headers: [0, 0, ...activeElement?.chartConfig.data.datasets].map?.((header: any, index: number) => {
                  if (index === 0) {
                    return 'S/N';
                  } else if (index === 1) {
                    return 'Labels';
                  } else return `Series ${index}`;
                }),
                body: activeElement.chartConfig.data.labels.map((label: any, index: number) => {
                  const row = [
                    index + 1,
                    label,
                    ...activeElement.chartConfig.data.datasets.map(
                      (dataset: any) => dataset.data[index] // Data for each series
                    ),
                  ];
                  return row;
                }),
              };
              setStackedBarChartTableData({ ...stackedBarChartDataTable });
              setOpenTable(true);
              setActiveBarchart(activeElement.chartType as BarChartTitles);
              setSelectedChart('barchart');
              break;

            case "Pie Chart":
            case 'Donut Chart':
              const pieChartObject = activeElement;
              setPieChartTableData({
                ...initPieChartDataTable,
                body: pieChartObject.chartConfig.data.labels.map((label: any, index: number) => [
                  index + 1, // Serial number
                  label, // Chart label
                  pieChartObject.chartConfig.data.datasets[0].data[index], // Corresponding chart value
                ]),
              });
              setPieChartColor(pieChartObject.chartConfig.data.datasets[0]?.backgroundColor?.map(
                (color: string) => color
              ));
              setActivePieChart(chartType);
              setActiveChart('piechart');
              setSelectedChart('piechart');
              setOpenTable(true);
              break;
          }
        }
      }
    }, 10);
  }

  // Cleanup function to clear the timeout
  return () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };
}, [activeElementId, barChartColorType]);


 useEffect(() => {
   if (barChartColorType === 'distinct') {
     const initialColors = Array(tableData.body.length).fill('#0A3059');
     setBarChartColor(initialColors);
   } else {
     if (Array.isArray(barChartColor)) {
       setBarChartColor(barChartColor[0] || '#0A3059');
     }
   }
 }, [barChartColorType, tableData.body.length]);

 const findActiveElment = elements?.find( (element_: any) => {
  return element_?.elementId === activeElementId
  })

 useEffect(() => {
  if (selectedChart === 'barchart' && elements?.length) {
     if(findActiveElment !== -1){
      let element = {...findActiveElment}
      if(element.type === 'chart' && element.chartType === activeBarchart) {
        const newConfig = { ...element.chartConfig };
        
        if (activeBarchart === 'Column Bar Chart' || activeBarchart === 'Horizontal Bar Chart') {
          const labels = tableData.body.map(row => row[1].toString());
          const data = tableData.body.map(row => Number(row[2]));
          
          let backgroundColor, borderColor;
          if (barChartColorType === 'single') {
            const color = typeof barChartColor === 'string' ? barChartColor : '#0A3059';
            backgroundColor = Array(data.length).fill(color);
            borderColor = Array(data.length).fill(color);
          } else {
            const colorArray = Array.isArray(barChartColor) ? barChartColor : Array(data.length).fill('#0A3059');
            backgroundColor = colorArray.map(color => color || '#0A3059');
            borderColor = backgroundColor;
          }

          newConfig.data = {
            labels: labels,
            datasets: [{
              ...newConfig.data.datasets[0],
              label: ' ',
              data: data,
              backgroundColor: backgroundColor,
              borderColor: borderColor
            }]
          };

          newConfig.options = {
            ...newConfig.options,
            indexAxis: activeBarchart === 'Horizontal Bar Chart' ? 'y' : 'x',
            plugins: {
              ...newConfig.options.plugins,
              legend: {
                display: showBarChartLegend,
                position: legendPosition,
                labels: {
                  font: {
                    family: barChartFontFamily,
                    style: barChartFontStyle
                  },
                  generateLabels: function(chart: any) {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.labels.map((label: string, index: number) => ({
                        text: label,
                        fillStyle: backgroundColor[index],
                        strokeStyle: borderColor[index],
                        lineWidth: 1,
                        hidden: false,
                        index: index
                      }));
                    }
                    return [];
                  }
                }
              },
              title: {
                display: showBarChartTitle,
                text: barChartTitle,
                font: {
                  family: barChartFontFamily,
                  style: barChartFontStyle
                }
              }
            }
          };

          element =  {
            ...element,
            chartConfig: newConfig
          };
        } else if (activeBarchart === 'Stacked Column Bar Chart' || activeBarchart === 'Stacked Horizontal Bar Chart' || activeBarchart === 'Progress Chart') {
          const labels = stackedBarChartTableData.body.map(row => row[1].toString());
          const seriesHeaders = stackedBarChartTableData.headers.slice(2);
          
          const datasets = seriesHeaders.map((header, index) => {
            const data = stackedBarChartTableData.body.map(row => {
              const value = row[index + 2];
              return value === '' ? 0 : Number(value);
            });
    
            const colors = [
             '#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C',
             '#2E8B57', '#FF6B6B', '#4682B4', '#DEB887', '#9370DB'
            ];
        
            return {
              label: header,
              data: data,
              backgroundColor: colors[index % colors.length],
              borderColor: colors[index % colors.length],
              borderWidth: 1
            };
          });
    
          newConfig.data = {
            labels: labels,
            datasets: datasets
          };

          newConfig.options = {
            ...newConfig.options,
            indexAxis: (activeBarchart === 'Stacked Horizontal Bar Chart' || activeBarchart === 'Progress Chart') ? 'y' : 'x',
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: showBarChartLegend,
                position: legendPosition,
                labels: {
                  usePointStyle: true,
                  font: {
                    family: barChartFontFamily,
                    style: barChartFontStyle
                  }
                }
              },
              title: {
                display: showBarChartTitle,
                text: barChartTitle,
                font: {
                  family: barChartFontFamily,
                  style: barChartFontStyle
                }
              }
            }
          };

        element = {
            ...element,
            chartConfig: newConfig
          };
        }

        element = {
          ...element,
          chartConfig: newConfig
        };
      }
    updateChartElement('chartConfig', element?.chartConfig)
     }
  }
}, [
  tableData,
  stackedBarChartTableData,
  stackedBarChartTableData.headers,
  barChartTitle,
  showBarChartTitle,
  showBarChartLegend,
  legendPosition,
  barChartFontFamily,
  barChartFontStyle,
  barChartColorType,
  barChartColor,
  activeBarchart,
  selectedChart,
  stackedBarChartColor,
  showStackedBarChartTitle,
  stackedBarChartTitle,
  showStackedBarChartLegend,
  stackedLegendPosition,
  stackedBarChartFontFamily,
  stackedBarChartFontStyle
  ]);

useEffect(() => {
  if(findActiveElment !== -1){
    let element = {...findActiveElment}
    if (selectedChart === 'piechart') {
        if (element.type === 'chart' && element.chartType === activePieChart) {
          const newConfig = { ...element.chartConfig };
          
          if (activePieChart === 'Pie Chart' || activePieChart === 'Donut Chart' || activePieChart === 'Half Donut Chart') {
            const labels = pieChartTableData.body.map(row => row[1].toString());
            const data = pieChartTableData.body.map(row => Number(row[2]));
            
            const colors = Array.isArray(pieChartColor) ? 
              pieChartColor : 
              ['#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C'].slice(0, data.length);
  
            newConfig.data = {
              labels: labels,
              datasets: [{
                data: data,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1
              }]
            };
  
            newConfig.options = {
              ...newConfig.options,
              responsive: true,
              maintainAspectRatio: false,
              cutout: (activePieChart === 'Donut Chart' || activePieChart === 'Half Donut Chart') ? '60%' : 0,
              rotation: activePieChart === 'Half Donut Chart' ? -90 : 0,
              circumference: activePieChart === 'Half Donut Chart' ? 180 : 360,
              plugins: {
                legend: {
                  display: showPieChartLegend,
                  position: pieChartLegendPosition,
                  labels: {
                    font: {
                      family: pieChartFontFamily,
                      style: pieChartFontStyle
                    }
                  }
                },
                title: {
                  display: showPieChartTitle,
                  text: pieChartTitle,
                  font: {
                    family: pieChartFontFamily,
                    style: pieChartFontStyle
                  }
                }
              }
            };
  
          element = {
              ...element,
              chartConfig: newConfig
            };
          }
       }
       updateChartElement('chartConfig', element?.chartConfig)
      //setElements(updatedElements);
    }
  }
  
}, [
  pieChartTableData,
  pieChartTitle,
  showPieChartTitle,
  showPieChartLegend,
  pieChartLegendPosition,
  pieChartFontFamily,
  pieChartFontStyle,
  pieChartColor,
  activePieChart,
  selectedChart,
]);

useEffect(() => {
  if (selectedChart === 'otherchart') {
    if(findActiveElment !== -1){
      let element = {...findActiveElment}
      if (element.type === 'chart' && 
         (element.chartType === 'Dot Chart' || 
          element.chartType === 'Area Chart' || 
          element.chartType === 'Line Chart')) {
        const newConfig = { ...element.chartConfig };
        
        const labels = otherChartTableData.body.map(row => row[1].toString());
        const data = otherChartTableData.body.map(row => Number(row[2]));

        if (element.chartType === 'Dot Chart') {
          newConfig.data = {
            labels: labels,
            datasets: [{
              label: otherChartTitle,
              data: data,
              borderColor: otherChartDotColor, // Use dot color for dots
              backgroundColor: otherChartDotColor,
              pointRadius: 8,
              pointHoverRadius: 10,
              showLine: false
            }]
          };

          newConfig.options = {
            ...newConfig.options,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              title: {
                display: true,
                text: otherChartTitle
              }
            },
            scales: {
              x: {
                title: {
                  display: showXAxisLabel,
                  text: xAxisLabel,
                  font: {
                    size: 14,
                    weight: 'bold'
                  }
                }
              },
              y: {
                title: {
                  display: showYAxisLabel,
                  text: yAxisLabel,
                  font: {
                    size: 14,
                    weight: 'bold'
                  }
                }
              }
            }
          };
        } else {
          // For Line and Area charts
          newConfig.data = {
            labels: labels,
            datasets: [{
              label: otherChartTitle,
              data: data,
              borderColor: otherChartLineColor, // Use line color for lines
              backgroundColor: element.chartType === 'Area Chart' ? 
                otherChartBackgroundColor : 
                undefined,
              fill: element.chartType === 'Area Chart',
              tension: element.chartType === 'Area Chart' ? 0.4 : 0.1,
              pointBackgroundColor: otherChartDotColor, // Use dot color for points
              pointBorderColor: otherChartDotColor
            }]
          };
        }

        newConfig.options = {
          ...newConfig.options,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: otherChartTitle
            }
          },
          scales: {
            x: {
              title: {
                display: showXAxisLabel,
                text: xAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            },
            y: {
              title: {
                display: showYAxisLabel,
                text: yAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            }
          }
        };

      element = {
          ...element,
          chartConfig: newConfig
        };
      }
      updateChartElement('chartConfig', element?.chartConfig)
    }
  }
}, [
  otherChartTableData,
  otherChartTitle,
  otherChartColor,
  otherChartBackgroundColor,
  otherChartLineColor, // Added dependency
  otherChartDotColor, // Added dependency
  xAxisLabel,
  yAxisLabel,
  showXAxisLabel,
  showYAxisLabel,
  selectedChart
]);

useEffect(() => {
  if (selectedChart === 'otherchart' && activeOtherChart === 'Pyramid Chart') {
    if(findActiveElment !== -1){
      let element = {...findActiveElment}
      if (element.type === 'chart' && element.chartType === 'Pyramid Chart') {
        const newConfig = { ...element.chartConfig };
        
        const labels = otherChartTableData.body.map(row => row[1].toString());
        const data = otherChartTableData.body.map(row => Number(row[2]));
        
        const maxValue = Math.max(...data);
        const percentages = data.map(value => (value / maxValue) * 100);

        newConfig.data = {
          labels: labels,
          datasets: [
            {
              label: 'Left',
              data: percentages.map(value => -value),
              backgroundColor: pyramidChartColor,
              borderColor: pyramidChartColor,
              borderWidth: 1,
              barPercentage: 1,
              categoryPercentage: 1,
            },
            {
              label: 'Right',
              data: percentages,
              backgroundColor: pyramidChartColor,
              borderColor: pyramidChartColor,
              borderWidth: 1,
              barPercentage: 1,
              categoryPercentage: 1,
            }
          ]
        };

        newConfig.options = {
          ...newConfig.options,
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: pyramidChartTitle,
              font: {
                family: otherChartFontFamily,
                style: otherChartFontStyle
              }
            },
            tooltip: {
              callbacks: {
                label: function(context: any) {
                  const originalValue = data[context.dataIndex];
                  return `Value: ${originalValue}`;
                }
              }
            }
          },
          scales: {
            x: {
              stacked: false,
              display: false,
              min: -100,
              max: 100,
              grid: {
                display: false
              }
            },
            y: {
              stacked: true,
              reverse: true,
              grid: {
                display: false
              }
            }
          }
        };

        element =  {
          ...element,
          chartConfig: newConfig
        };
      }
      updateChartElement('chartConfig', element?.chartConfig)
    }
  }
}, [
  otherChartTableData,
  pyramidChartTitle,
  pyramidChartColor,
  otherChartFontFamily,
  otherChartFontStyle,
  selectedChart,
  activeOtherChart
]);

useEffect(() => {
  if (selectedChart === 'barchart' && activeBarchart === 'Progress Chart') {
    if(findActiveElment !== -1){
      let element = {...findActiveElment}
      if (element.type === 'chart' && element.chartType === 'Progress Chart') {
        const newConfig = { ...element.chartConfig };
        
        if (newConfig.data?.datasets?.[1]) {
          newConfig.data.datasets[1].backgroundColor = progressChartColor;
          newConfig.data.datasets[1].borderColor = progressChartColor;
        }

        // Update title settings
        if (newConfig.options?.plugins?.title) {
          newConfig.options.plugins.title.display = showProgressChartTitle;
          newConfig.options.plugins.title.text = progressChartTitle;
          newConfig.options.plugins.title.font = {
            family: progressChartFontFamily,
            style: progressChartFontStyle
          };
        }

        element = {
          ...element,
          chartConfig: newConfig
        };
      }
      updateChartElement('chartConfig', element?.chartConfig)
    }
  }
}, [
  progressChartColor,
  progressChartTitle,
  showProgressChartTitle,
  progressChartFontFamily,
  progressChartFontStyle,
  selectedChart,
  activeBarchart
]);

useEffect(() => {
  if (selectedChart === 'barchart' && 
     (activeBarchart === 'Stacked Column Bar Chart' || activeBarchart === 'Stacked Horizontal Bar Chart')) {
      if(findActiveElment !== -1){
        let element = {...findActiveElment}
      //const updatedElements = elements.map((element: any) => {
      if (element.type === 'chart' && 
         (element.chartType === 'Stacked Column Bar Chart' || element.chartType === 'Stacked Horizontal Bar Chart')) {
        const newConfig = { ...element.chartConfig };
        
        if (newConfig.data?.datasets) {
          newConfig.data.datasets = newConfig.data.datasets.map((dataset: any, index: number) => ({
            ...dataset,
            backgroundColor: stackedBarChartColor[index] || '#0A3059',
            borderColor: stackedBarChartColor[index] || '#0A3059'
          }));
        }

        // Update title settings
        if (newConfig.options?.plugins?.title) {
          newConfig.options.plugins.title.display = showStackedBarChartTitle;
          newConfig.options.plugins.title.text = stackedBarChartTitle;
          newConfig.options.plugins.title.font = {
            family: stackedBarChartFontFamily,
            style: stackedBarChartFontStyle
          };
        }

        element = {
          ...element,
          chartConfig: newConfig
        };
      }
      updateChartElement('chartConfig', element?.chartConfig)
    }
  }
}, [
  stackedBarChartColor,
  stackedBarChartTitle,
  showStackedBarChartTitle,
  stackedBarChartFontFamily,
  stackedBarChartFontStyle,
  selectedChart,
  activeBarchart
]);

  const handleChartClick = (chartType: BarChartTitles) => {
    setActiveBarchart(chartType as BarChartTitles)
    setActiveChart('barchart')
    
    // Get stored coordinates if they exist
    const storedCoords = sessionStorage.getItem('chartDropCoordinates');
    let x = 50, y = 50;
    
    if (storedCoords) {
      const coords = JSON.parse(storedCoords);
      x = coords.x;
      y = coords.y;
      sessionStorage.removeItem('chartDropCoordinates'); // Clear stored coordinates
    }

    // Reset table data based on chart type
    if (chartType === 'Column Bar Chart' || chartType === 'Horizontal Bar Chart') {
      setTableData({...initDataTable}); // Use spread to create new reference
      setBarChartColor('#0A3059')
    } else if (chartType === 'Stacked Column Bar Chart' || chartType === 'Stacked Horizontal Bar Chart') {
      setStackedBarChartTableData({...initStackedBarChartDataTable});
    } else if (chartType === 'Progress Chart') {
      setStackedBarChartTableData({...initProgressChartDataTable});
    }

    if (chartType === 'Column Bar Chart' || chartType === 'Horizontal Bar Chart') {
      const labels = tableData.body.map(row => row[1].toString());
      const data = tableData.body.map(row => Number(row[2]));

      let backgroundColor, borderColor;
      if (barChartColorType === 'single') {
        const color = typeof barChartColor === 'string' ? barChartColor : '#0A3059';
        backgroundColor = Array(data.length).fill(color);
        borderColor = Array(data.length).fill(color);
      } else {
        const colorArray = Array.isArray(barChartColor) ? barChartColor : Array(data.length).fill('#0A3059');
        backgroundColor = colorArray.map(color => color || '#0A3059');
        borderColor = backgroundColor;
      }

      const chartConfig = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: ' ',
            data: data,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 1
          }]
        },
        options: {
          indexAxis: chartType === 'Horizontal Bar Chart' ? 'y' : 'x',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: showBarChartLegend,
              position: legendPosition,
              labels: {     
                usePointStyle: true,
                font: {
                  family: barChartFontFamily,
                  style: barChartFontStyle
                },
                generateLabels: function(chart: any) {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label: string, index: number) => ({
                      text: label,
                      fillStyle: backgroundColor[index],
                      strokeStyle: borderColor[index],
                      lineWidth: 1,
                      hidden: false,
                      index: index
                    }));
                  }
                  return [];
                }
              }
            },
            title: {
              display: showBarChartTitle,
              text: barChartTitle,
              font: {
                family: barChartFontFamily,
                style: barChartFontStyle
              }
            }
          }
        }
      };
      elementSetter(
          activePageId, addElement(
            'chart', activePageId, '', chartConfig,
             chartType, null, x, y
            ), setPageStructure
        )
       // Pass the coordinates
      setOpenTable(true);
      setSelectedChart('barchart');
    }
    else if (chartType === 'Stacked Column Bar Chart' || chartType === 'Stacked Horizontal Bar Chart' || chartType === 'Progress Chart') {
      const labels = stackedBarChartTableData.body.map(row => row[1].toString());
      const seriesHeaders = stackedBarChartTableData.headers.slice(2);
      
      const datasets = seriesHeaders.map((header, index) => {
        const data = stackedBarChartTableData.body.map(row => {
          const value = row[index + 2];
          return value === '' ? 0 : Number(value);
        });
    
        const colors = [
             '#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C',
             '#2E8B57', '#FF6B6B', '#4682B4', '#DEB887', '#9370DB'
            ];
        
        return {
          label: header,
          data: data,
          backgroundColor: colors[index % colors.length],
          borderColor: colors[index % colors.length],
          borderWidth: 1
        };
      });
    
      const chartConfig = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: datasets
        },
        options: {
          indexAxis: (chartType === 'Stacked Horizontal Bar Chart' || chartType === 'Progress Chart') ? 'y' : 'x',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true,
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: showBarChartLegend,
              position: legendPosition,
              labels: {
                usePointStyle: true,
                font: {
                  family: barChartFontFamily,
                  style: barChartFontStyle
                }
              }
            },
            title: {
              display: showBarChartTitle,
              text: barChartTitle,
              font: {
                family: barChartFontFamily,
                style: barChartFontStyle
              }
            }
          }
        }
      };
      elementSetter(
        activePageId, addElement(
          'chart', activePageId, '', chartConfig,
           chartType, null, x, y
          ), setPageStructure
      )
      setOpenTable(true);
      setSelectedChart('barchart');
    }
};

const handlePieChartClick = (chartType: pieChartType) => {
  setActivePieChart(chartType)
  setActiveChart('piechart')
  setSelectedChart('piechart');
  setOpenTable(true);
  // Reset pie chart table data
  setPieChartTableData(initPieChartDataTable);
  setTableData(initDataTable)
  // Get stored coordinates if they exist
  const storedCoords = sessionStorage.getItem('chartDropCoordinates');
  let x = 50, y = 50;
  
  if (storedCoords) {
    const coords = JSON.parse(storedCoords);
    x = coords.x;
    y = coords.y;
    sessionStorage.removeItem('chartDropCoordinates'); // Clear stored coordinates
  }
  
  const labels = pieChartTableData.body.map(row => row[1].toString());
  const data = pieChartTableData.body.map(row => Number(row[2]));
  const colors = ['#0A3059', '#BFD1E3', '#4B77BE', '#7E93AB', '#1B4F8C'].slice(0, data.length);

  const chartConfig = {
    type: chartType === 'Pie Chart' ? 'pie' : 'doughnut',
    data: {
      labels: labels,
      datasets: [{
        data: data,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      cutout: (chartType === 'Donut Chart' || chartType === 'Half Donut Chart') ? '60%' : 0,
      rotation: chartType === 'Half Donut Chart' ? -90 : 0,
      circumference: chartType === 'Half Donut Chart' ? 180 : 360,
      plugins: {
        legend: {
          display: showPieChartLegend,
          position: pieChartLegendPosition,
          labels: {
            font: {
              family: pieChartFontFamily,
              style: pieChartFontStyle
            }
          }
        },
        title: {
          display: showPieChartTitle,
          text: pieChartTitle,
          font: {
            family: pieChartFontFamily,
            style: pieChartFontStyle
          }
        }
      }
    }
  };

  elementSetter(
    activePageId, addElement(
      'chart', activePageId, '', chartConfig,
       chartType, null, x, y
      ), setPageStructure
  ) // Pass the coordinates
};

const handleOtherChartClick = (chartType: OtherChartType) => {
  setActiveOtherChart(chartType);
  setActiveChart('otherchart')
  
  // Get stored coordinates if they exist
  const storedCoords = sessionStorage.getItem('chartDropCoordinates');
  let x = 50, y = 50;
  
  if (storedCoords) {
    const coords = JSON.parse(storedCoords);
    x = coords.x;
    y = coords.y;
    sessionStorage.removeItem('chartDropCoordinates'); // Clear stored coordinates
  }
  
  // Reset other chart table data
  setOtherChartTableData({...initDataTable});

  const labels = otherChartTableData.body.map(row => row[1].toString());
  const data = otherChartTableData.body.map(row => Number(row[2]));

  let chartConfig;

  switch(chartType) {
    case 'Line Chart':
    case 'Dot Chart':
      chartConfig = {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: otherChartTitle,
            data: data,
            borderColor: otherChartColor,
            backgroundColor: otherChartColor,
            tension: 0.1,
            borderWidth: 2,
            pointRadius: chartType === 'Dot Chart' ? 8 : 3,
            pointHoverRadius: chartType === 'Dot Chart' ? 10 : 5,
            showLine: chartType !== 'Dot Chart'
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: otherChartTitle
            }
          },
          scales: {
            x: {
              title: {
                display: showXAxisLabel,
                text: xAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            },
            y: {
              title: {
                display: showYAxisLabel,
                text: yAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            }
          }
        }
      };
      break;

    case 'Area Chart':
      chartConfig = {
        type: 'line',
        data: {
          labels: labels,
          datasets: [{
            label: otherChartTitle,
            data: data,
            fill: true,
            backgroundColor: `${otherChartColor}33`,
            borderColor: otherChartColor,
            tension: 0.4
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: otherChartTitle
            }
          },
          scales: {
            x: {
              title: {
                display: showXAxisLabel,
                text: xAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            },
            y: {
              title: {
                display: showYAxisLabel,
                text: yAxisLabel,
                font: {
                  size: 14,
                  weight: 'bold'
                }
              }
            }
          }
        }
      };
      break;

    case 'Pyramid Chart':
      const maxValue = Math.max(...data);
      const percentages = data.map(value => (value / maxValue) * 100);

      chartConfig = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Left',
              data: percentages.map(value => -value),
              backgroundColor: pyramidChartColor,
              borderColor: pyramidChartColor,
              borderWidth: 1,
              barPercentage: 1,
              categoryPercentage: 1,
            },
            {
              label: 'Right',
              data: percentages,
              backgroundColor: pyramidChartColor,
              borderColor: pyramidChartColor,
              borderWidth: 1,
              barPercentage: 1,
              categoryPercentage: 1,
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: pyramidChartTitle,
              font: {
                family: otherChartFontFamily,
                style: otherChartFontStyle
              }
            },
            tooltip: {
              callbacks: {
                label: function(context: any) {
                  const originalValue = data[context.dataIndex];
                  return `Value: ${originalValue}`;
                }
              }
            }
          },
          scales: {
            x: {
              stacked: false,
              display: false,
              min: -100,
              max: 100,
              grid: {
                display: false
              }
            },
            y: {
              stacked: true,
              reverse: true,
              grid: {
                display: false
              }
            }
          }
        }
      };
      break;
  }

  if (chartConfig) {
    elementSetter(
      activePageId, addElement(
        'chart', activePageId, '', chartConfig,
         chartType, null, x, y
        ), setPageStructure
    ) // Pass the coordinates
    setSelectedChart('otherchart');
    setOpenTable(true);
  }
};

const handleBack = () => {
  // Clear all table data states
  setTableData({...initDataTable});
  setStackedBarChartTableData({...initStackedBarChartDataTable});
  setProgressChartTableData({...initProgressChartDataTable});
  setPieChartTableData({...initPieChartDataTable});
  setOtherChartTableData({...initDataTable});
  // Reset active tab
  setActiveTab('chart data');
  // Close table and reset selected chart
  setOpenTable(false);
  setSelectedChart(null);
};

  return (
    <div>
        <header className='flex border-b border-slate-300 pb-2 items-center mt-5'>
           <img 
             src={leftAngle} onClick={() => handleBack()}
              className='w-4 h-4 hover:cursor-pointer' alt="left-angle"
           />
           <span className='text-sm pl-5'>Charts & Graphs</span>
        </header>
            {
                (openTable && selectedChart === 'barchart') && (
                    <section>
                        <div className='grid grid-cols-3 mb-2 mt-3 gap-1'>
                            {
                                chartTableTabs.map((tab) => (
                                    <button key={tab} 
                                    className={`text-xs py-2 text-slate-950 px-1 capitalize font-medium ${
                                               activeTab === tab ? 'text-slate-800 bg-[#BFD1E3]' 
                                               : 'bg-white text-slate-950'}`
                                             }
                                    onClick={() => setActiveTab(tab)}
                                    >
                                        {tab}
                                    </button>
                                ))
                            }
                        </div>
                          {
                            (activeTab === 'chart data' && activeChart === 'barchart') ? 
                              <React.Fragment>
                                  { (activeBarchart === 'Column Bar Chart' || activeBarchart === 'Horizontal Bar Chart') &&
                                    <BarchartInputTable
                                      tableData={tableData}
                                      setTableData={setTableData}
                                   />
                                   }
                                   {
                                    (activeBarchart === 'Stacked Column Bar Chart' || activeBarchart === 'Stacked Horizontal Bar Chart') &&
                                    <StackedBarChartDataTable
                                      tableData={stackedBarChartTableData}
                                      setTableData={setStackedBarChartTableData}
                                   />
                                   }
                                   {
                                     activeBarchart === 'Progress Chart' && (
                                      <ProgressChartDataTable
                                        progressChartTableData={stackedBarChartTableData}
                                        setProgressChartTableData={setStackedBarChartTableData}
                                      />
                                     )
                                   }
                              </React.Fragment>
                            : null
                         }
                         {
                          (activeTab === 'import data' && activeChart === 'barchart') && 
                            <React.Fragment>
                               {
                                (activeBarchart === 'Column Bar Chart' || activeBarchart === 'Horizontal Bar Chart') && (
                                  <ImportData  
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    setOpenTable={setOpenTable}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                  />
                                )
                              }
                              {
                                (activeBarchart === 'Stacked Column Bar Chart' || activeBarchart === 'Stacked Horizontal Bar Chart' || activeBarchart === 'Progress Chart') && (
                                  <ImportStackedBarChartData
                                    stackedChartTableData={stackedBarChartTableData}
                                    setStackedChartTableData={setStackedBarChartTableData}
                                    setOpenTable={setOpenTable}
                                    setActiveTab={setActiveTab}
                                    activeTab={activeTab}
                                  />
                                )
                              }
                            </React.Fragment>
                         }
                         {
                          (activeTab === 'settings' && activeChart === 'barchart') && (
                            <React.Fragment>
                                { (activeBarchart === 'Column Bar Chart' || activeBarchart === 'Horizontal Bar Chart') &&
                                  <ChartSettings 
                                    barChartTitle={barChartTitle}
                                    setBarChartTitle={setBarChartTitle}
                                    setShowBarChartTitle={setShowBarChartTitle}
                                    showBarChartTitle={showBarChartTitle}
                                    showBarChartLegend={showBarChartLegend}
                                    setShowBarChartLegend={setShowBarChartLegend}
                                    legendPosition={legendPosition}
                                    setLegendPosition={setLegendPosition}
                                    barChartFontStyle={barChartFontStyle}
                                    setBarchartFontStyle={setBarchartFontStyle}
                                    barChartFontFamily={barChartFontFamily}
                                    setBarchartFontFamily={setBarchartFontFamily}
                                    barChartColorType={barChartColorType}
                                    setBarChartColorType={setBarChartColorType}
                                    barChartColor={barChartColor}
                                    setBarChartColor={setBarChartColor} 
                                    tableData={tableData}
                                />
                                }
                                {
                                  (activeBarchart === 'Stacked Column Bar Chart' || activeBarchart === 'Stacked Horizontal Bar Chart' || activeBarchart === 'Progress Chart') && (
                                    <StackedBarChartSettings
                                      setStackedBarChartTableData={setStackedBarChartTableData}
                                      stackedBarChartTitle={stackedBarChartTitle}
                                      setStackedBarChartTitle={setStackedBarChartTitle}
                                      setShowStackedBarChartTitle={setShowStackedBarChartTitle}
                                      showStackedBarChartTitle={showStackedBarChartTitle}
                                      showStackedBarChartLegend={showStackedBarChartLegend}
                                      setShowStackedBarChartLegend={setShowStackedBarChartLegend}
                                      stackedLegendPosition={stackedLegendPosition}
                                      setStackedLegendPosition={setStackedLegendPosition}
                                      stackedBarChartFontStyle={stackedBarChartFontStyle}
                                      setStackedBarChartFontStyle={setStackedBarChartFontStyle}
                                      stackedBarChartFontFamily={stackedBarChartFontFamily}
                                      setStackedBarChartFontFamily={setStackedBarChartFontFamily}
                                      stackedBarChartColorType={stackedBarChartColorType}
                                      setStackedBarChartColorType={setStackedBarChartColorType}
                                      stackedBarChartColor={stackedBarChartColor}
                                      setStackedBarChartColor={setStackedBarChartColor}
                                      stackedBarChartTableData={stackedBarChartTableData}
                                    />
                                  )
                                }
                            </React.Fragment>
                          ) 
                         }
                    </section>
                ) } 
        {
          (openTable && (selectedChart === 'piechart' || selectedChart === 'otherchart')) ? (
            <section>
                <div className='grid grid-cols-3 mb-2 mt-3 gap-1'>
                    {
                        chartTableTabs.map((tab) => (
                            <button key={tab} 
                            className={`text-xs py-2 text-slate-950 px-1 capitalize font-medium ${
                                       activeTab === tab ? 'text-slate-800 bg-[#BFD1E3]' 
                                       : 'bg-white text-slate-950'}`
                                     }
                            onClick={() => setActiveTab(tab)}
                            >
                                {tab}
                            </button>
                        ))
                    }
                </div>
            </section>
          ) : null
        }
        { openTable &&
        <section>
           {
              (activeTab === 'chart data' && activeChart === 'piechart') && 
                <React.Fragment>
                  {
                    (activePieChart === 'Pie Chart' || activePieChart === 'Donut Chart' || activePieChart === 'Half Donut Chart') && (
                      <PieChartDataTable
                        tableData={pieChartTableData}
                        setTableData={setPieChartTableData}
                      />
                    )
                  }
              </React.Fragment>
           }
           {
              (activeTab === 'import data' && activeChart === 'piechart') && 
                <React.Fragment>
                  {
                    (activePieChart === 'Pie Chart' || activePieChart === 'Donut Chart' || activePieChart === 'Half Donut Chart') && (
                       <ImportBarChartData
                        tableData={pieChartTableData}
                        setTableData={setPieChartTableData}
                        setOpenTable={setOpenTable}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                     />
                  )
                }
              </React.Fragment>
           }
           {
              (activeTab === 'settings' && activeChart === 'piechart') && (
                <React.Fragment>
                  { /* 
                   /* // same as barchart settings structure, such component can be reused 
                */
                  (activePieChart === 'Pie Chart' || activePieChart === 'Donut Chart' || activePieChart === 'Half Donut Chart') && (
                     <ColumnBarChartSettings
                      activeElementId={activeElementId}
                      elements={elements} 
                      updateChartElement={updateChartElement}
                     activeChart={activePieChart}
                      barChartTitle={pieChartTitle}
                      setBarChartTitle={setPieChartTitle}
                      setShowBarChartTitle={setShowPieChartTitle}
                      showBarChartTitle={showPieChartTitle}
                      showBarChartLegend={showPieChartLegend}
                      setShowBarChartLegend={setShowPieChartLegend}
                      legendPosition={pieChartLegendPosition}
                      setLegendPosition={setPieChartLegendPosition}
                      barChartFontStyle={pieChartFontStyle}
                      setBarchartFontStyle={setPieChartFontStyle}
                      barChartFontFamily={pieChartFontFamily}
                      setBarchartFontFamily={setPieChartFontFamily}
                      barChartColorType={pieChartColorType}
                      setBarChartColorType={setPieChartColorType}
                      barChartColor={pieChartColor}
                      setBarChartColor={setPieChartColor}
                      tableData={pieChartTableData}
                      showDhoughntuCenterText={showDhoughntuCenterText}
                      setShowDhoughntuCenterText={setShowDhoughntuCenterText}
                      centerText ={centerText}
                      setCenterText={setCenterText}
                     />
                  )
                }
              </React.Fragment>
             )
           }
        </section>
        }
           {
              (activeTab === 'chart data' && activeChart === 'otherchart') && 
                <React.Fragment>
                  {
                    (  activeOtherChart === 'Dot Chart' || 
                      activeOtherChart === 'Area Chart' ||
                       activeOtherChart === 'Line Chart' ||
                       activeOtherChart === 'Pyramid Chart'
                      ) && 
                       /* same as pie chart table data structure */ 
                      (
                      <PieChartDataTable
                        tableData={otherChartTableData}
                        setTableData={setOtherChartTableData}
                      />  
                    )
                  }
              </React.Fragment>
           }
           {
              (activeTab === 'import data' && activeChart === 'otherchart') && 
                <React.Fragment>
                  {
                    (
                      activeOtherChart === 'Dot Chart' || 
                      activeOtherChart === 'Area Chart' || 
                      activeOtherChart === 'Line Chart' ||
                      activeOtherChart === 'Pyramid Chart'
                      ) && (
                       <ImportBarChartData
                        tableData={otherChartTableData}
                        setTableData={setOtherChartTableData}
                        setOpenTable={setOpenTable}
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                     />
                  )
                }
              </React.Fragment>
           }
           { openTable &&
              (activeTab === 'settings' && activeChart === 'otherchart') && 
               <React.Fragment>
                  {
                    <OtherChartsSettings
                      otherChartTitle={otherChartTitle}
                      setOtherChartTitle={setOtherChartTitle}
                      setShowOtherChartTitle={setShowOtherChartTitle}
                      showOtherChartTitle={showOtherChartTitle}
                      otherChartFontStyle={otherChartFontStyle}
                      setOtherChartFontStyle={setOtherChartFontStyle}
                      otherChartFontFamily={otherChartFontFamily}
                      setOtherChartFontFamily={setOtherChartFontFamily}
                      tableData={otherChartTableData}
                      showXAxis={showXAxisLabel }
                      setShowXAxis={setShowXAxisLabel}
                      showYAxis={showYAxisLabel}
                      setShowYAxis={setShowYAxisLabel}
                      xAxisLabel={xAxisLabel}
                      setXAxisLabel={setXAxisLabel}
                      yAxisLabel={yAxisLabel}
                      setYAxisLabel={setYAxisLabel}
                      isPyramidChart={activeOtherChart === 'Pyramid Chart'}
                      activeOtherChart={activeOtherChart}
                      otherChartBackgroundColor={otherChartBackgroundColor}
                      setOtherChartBackgroundColor={setOtherChartBackgroundColor}
                      otherChartLineColor={otherChartLineColor}
                      setOtherChartLineColor={setOtherChartLineColor}
                      otherChartDotColor={otherChartDotColor}
                      setOtherChartDotColor={setOtherChartDotColor}
                      pyramidChartColor={pyramidChartColor}
                      setPyramidChartColor={setPyramidChartColor}
                /> }
               </React.Fragment>
           }
           <h6 className='text-sm text-slate-950 font-medium mt-5'>Bar Charts</h6>
            <section className='mt-3 grid grid-cols-3 gap-3'>
                   { charts.barchart.map((chart, index:number) => (
                    <div 
                      key={chart.id} 
                      className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'
                      draggable="true"
                      onDragStart={(e) => {
                        e.stopPropagation();
                        const dragData = {
                          type: 'chart',
                          chartType: chart.title,
                          category: 'barchart',
                          chartConfig: null // We'll generate this when dropped
                        };
                        e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
                        // Optional: Set a drag image
                        const img = e.currentTarget.querySelector('img');
                        if (img) {
                          e.dataTransfer.setDragImage(img, 0, 0);
                        }
                      }}
                    >
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          id={`bar-chart-${index}`}
                          draggable="false" // Prevent image from being dragged separately
                          onClick={() => {
                            if(chart.title === 'Progress Chart') {
                              setStackedBarChartTableData(progressChartTableData)
                              setTimeout(() => {
                                handleChartClick(chart.title as BarChartTitles)
                              }, 100)
                            }
                            else {
                              handleChartClick(chart.title as BarChartTitles)
                            }
                          }}
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))}
                </section>
                <h6 className='text-sm text-slate-950 font-medium mt-5'>Pie Charts</h6>
                <section className='mt-3 grid grid-cols-3 gap-3'>
            { 
                charts.piechart.map((chart, index) => (
                    <div 
                      key={chart.id} 
                      className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'
                      draggable="true"
                      onDragStart={(e) => {
                        e.stopPropagation();
                        const dragData = {
                          type: 'chart',
                          chartType: chart.title,
                          category: 'piechart',
                          chartConfig: null // We'll generate this when dropped
                        };
                        e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
                        // Optional: Set a drag image
                        const img = e.currentTarget.querySelector('img');
                        if (img) {
                          e.dataTransfer.setDragImage(img, 0, 0);
                        }
                      }}
                    >
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          id={`piechart-${index}`}
                          draggable="false" // Prevent image from being dragged separately
                          onClick={() => handlePieChartClick(chart.title as pieChartType)}
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))
            }
        </section>
        {
          <h6 className='text-sm text-slate-950 font-medium mt-5'>Other Charts</h6>
        }
        <section className='mt-3 grid grid-cols-3 gap-3'>
            { 
                charts.otherchart.map((chart) => (
                    <div 
                      key={chart.id} 
                      className='hover:cursor-pointer hover:scale-110 transition-all duration-300 w-full h-[80px] bg-white rounded-md'
                      draggable="true"
                      onDragStart={(e) => {
                        e.stopPropagation();
                        const dragData = {
                          type: 'chart',
                          chartType: chart.title,
                          category: 'otherchart',
                          chartConfig: null // We'll generate this when dropped
                        };
                        e.dataTransfer.setData('text/plain', JSON.stringify(dragData));
                        // Optional: Set a drag image
                        const img = e.currentTarget.querySelector('img');
                        if (img) {
                          e.dataTransfer.setDragImage(img, 0, 0);
                        }
                      }}
                    >
                        <img 
                          src={chart.image} 
                          alt={chart.title} 
                          draggable="false" // Prevent image from being dragged separately
                          onClick={() => handleOtherChartClick(chart.title as OtherChartType)}
                          className='w-[100px] h-[70px]'
                         />
                    </div>
                ))
            }
        </section>
    </div>
  )
}

export default ChartsWidget