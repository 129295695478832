import React, { useState, useEffect, useRef } from "react";
import { DndContext } from "@dnd-kit/core";
import { useDroppable } from "@dnd-kit/core";
import { Rnd } from "react-rnd";
import { v4 } from "uuid";
import { useReportEditor } from "../../hooks/ReportEditorContext";
import ChartComponent from "./Chart";
import { useBrandContext } from "../../hooks/useBrandContext";
import { json, useLocation } from "react-router-dom";
import { PageType } from "../types/page.types";

const Page: React.FC<PageType> = ({
  style,
  activeToolbarTab,
  setActiveToolbarTab,
  pageIndex,
  activeWidget,
  setActiveWidget,
  setPages,
  centerText,
  newPage,
  pages,
  scalable = false,
  activePageId,
  setActivePageId,
  isPreview = false,
  height,
  elementSetter,
  pageStructure,
  setPageStructure,
  elementer,
  activeElementId,
  duplicatePage,
  pageId,
  setActiveElementId,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageType = searchParams.get("type") as "pdf" | "pptx" | null;
  const [showActions, setShowActions] = useState<boolean>(false);
  const [showPageBackgroundSelector, setShowPageBackgroundSelector] =
    useState<boolean>(false);
  const [pageBackground, setPageBackground] = useState<string>("#FFFFFF");
  const actionsRef = useRef<HTMLDivElement>(null);
  const {
    elements,
    setElements,
    activeElementIndex,
    setActiveElementIndex,
    updateElementPosition,
    updateActiveElement,
    duplicateElement,
    deleteElement,
    addElement,
  } = useReportEditor();
  // console.log({testelement:elements, pageStructure, activePageId})
  const {
    logo,
    showLogo,
    logoPosition,
    headerVisible,
    headerPosition,
    footerVisible,
    footerPosition,
    showPageNumber,
    pageNumberPosition,
    positionClassMap,
    headerText,
    footerText,
  } = useBrandContext();

  const [isCardOpen, setIsCardOpen] = useState(false);

  const cardRef = useRef(null);
  const { setNodeRef } = useDroppable({
    id: "page-drop-area",
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideAllPages = !pages?.some((page) => {
        const pageElement = document.getElementById(`page-${page.id}`);
        return pageElement?.contains(event.target as Node);
      });

      if (isClickOutsideAllPages) {
        // setActivePageId?.(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [pages, setActivePageId]);

  const addNewPage = () => {
    if (pages) setPages?.([...pages, { id: v4(), backgroundColor: "#FFFFFF" }]);
  };

  const RenderBrandElements = () => {
    return (
      <div className="">
        {/* Logo */}
        {showLogo && logo && (
          <div
            className={`absolute ${positionClassMap[logoPosition]} p-4`}
            style={{ zIndex: 0 }}
          >
            <img
              src={logo}
              alt="Brand Logo"
              className="w-20 h-20 object-contain"
            />
          </div>
        )}

        {/* Header */}
        {headerVisible && (
          <div
            className={`absolute ${positionClassMap[headerPosition]} p-4`}
            style={{ zIndex: 0 }}
          >
            <div className="">
              <h1 className="text-lg font-bold">{headerText}</h1>
            </div>
          </div>
        )}

        {/* Footer */}
        {footerVisible && (
          <div
            className={`absolute ${positionClassMap[footerPosition]} p-4`}
            style={{ zIndex: 0 }}
          >
            <div className="">
              <p>{footerText}</p>
            </div>
          </div>
        )}

        {/* Page Number */}
        {showPageNumber && (
          <div
            className={`absolute ${positionClassMap[pageNumberPosition]} p-4`}
            style={{ zIndex: 0 }}
          >
            <span className="text-sm">Page {pageIndex}</span>
          </div>
        )}
      </div>
    );
  };

  const findChartImageElement = (
    chartType: string,
    category: string
  ): HTMLImageElement | null => {
    // console.log("Looking for chart:", chartType, category);

    const categoryHeader = {
      barchart: "Bar Charts",
      piechart: "Pie Charts",
      otherchart: "Other Charts",
    }[category];

    if (!categoryHeader) return null;

    const headers = document.querySelectorAll("h6");
    let targetSection: Element | null = null;

    for (const header of headers) {
      if (header.textContent?.trim() === categoryHeader) {
        targetSection = header.nextElementSibling;
        break;
      }
    }

    if (!targetSection) {
      // console.log("Target section not found");
      return null;
    }

    const images = targetSection.querySelectorAll("img");
    for (const img of images) {
      const imgElement = img as HTMLImageElement;
      if (imgElement.alt === chartType) {
        // console.log("Found matching image:", imgElement.alt);
        return imgElement;
      }
    }

    console.log("No matching image found in section");
    return null;
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const data = JSON.parse(e.dataTransfer.getData("text/plain"));
      console.log("Dropped data:", data);

      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      switch (data.type) {
        case "data-card":
          // addElement('data-card', '', null, null, {
          //   title: data.title,
          //   value: data.value,
          //   x,
          //   y
          // });
          elementSetter(
            activePageId,
            addElement(
              "data-card",
              activePageId,
              "",
              null,
              null,
              {
                title: data.title,
                value: data.value,
                x,
                y,
              },
              x,
              y
            ),
            setPageStructure
          );
          break;

        case "chart":
          sessionStorage.setItem(
            "chartDropCoordinates",
            JSON.stringify({ x, y })
          );
          const chartImage = findChartImageElement(
            data.chartType,
            data.category
          );

          if (chartImage) {
            const dataTab = document.querySelector(
              `[class*="bi-noise-reduction"]`
            )?.parentElement;
            if (dataTab) {
              dataTab.click();
              setTimeout(() => {
                chartImage.click();
              }, 100);
            }
          }
          break;

        case "image":
          //addElement('image', data.imageUrl, null, null, null, x, y);
          //   (
          //     type, activePageId, content = '', chartConfig = null,
          //      chartType = null, cardData = null, x = 50, y = 50
          //  )
          elementSetter(
            activePageId,
            addElement(
              "image",
              activePageId,
              data.imageUrl,
              null,
              null,
              null,
              x,
              y
            ),
            setPageStructure
          );
          break;
        // case "icon":
        //   //addElement('image', data.imageUrl, null, null, null, x, y);
        //   //   (
        //   //     type, activePageId, content = '', chartConfig = null,
        //   //      chartType = null, cardData = null, x = 50, y = 50
        //   //  )
        //   elementSetter(
        //     activePageId,
        //     addElement(
        //       "icon",
        //       activePageId,
        //       data.iconUrl,
        //       null,
        //       null,
        //       null,
        //       x,
        //       y
        //     ),
        //     setPageStructure
        //   );
        //   break;

        case "icon":
          // if (!data.IconComponent) {
          //   console.error("Invalid icon component:", data.IconComponent);
          //   return; // Prevent adding invalid icon
          // }
          // Ensure that `data.IconComponent` is valid before adding it
          elementSetter(
            activePageId,
            addElement(
              "icon",
              activePageId,
              data.iconUrl, // Pass the component instead of URL
              null,
              null,
              null,
              x,
              y
            ),
            setPageStructure
          );
          break;
        case "content-style":
          // Add the content style as a new element
          elementSetter(
            activePageId,
            addElement(
              "content-style",
              activePageId,
              "",
              null,
              null,
              null,
              x,
              y,
              {
                styleNumber: data.data.styleNumber,
                items: data.data.items || [],
              }
            ),
            setPageStructure
          );
          break;
        case "shape":
          elementSetter(
            activePageId,
            addElement(
              "shape",
              activePageId,
              "",
              null,
              null,
              null,
              x,
              y,
              {},
              {
                type: data.shapeType,
                clipPath: data.clipPath,
              }
            ),
            setPageStructure
          );
          break;
      }
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };

  // helper function to render TOC based on style

  return (
    <DndContext>
      <div
        style={style}
        className={`${
          isPreview
            ? pageType === "pdf"
              ? `report-editor-page pointer-events-none p-0 mx-auto w-[8.27in] h-[11.695in]`
              : `report-editor-page pointer-events-none p-0 mx-auto w-[9in] h-[5.4in]`
            : pageType === "pdf"
            ? "max-h-[13in] p-2 mx-auto bg-slate-100"
            : "max-h-[5.8in] p-2 mx-auto bg-slate-100"
        }`}
      >
        {!isPreview && (
          <h6 className="text-xs exclude-from-pdf pl-1">
            Page {pageIndex || ""}
          </h6>
        )}
        <div className="relative">
          <section
            ref={setNodeRef}
            id={`page-${newPage?.id}`}
            style={{
              backgroundColor: newPage?.backgroundColor,
            }}
            className={`${
              !isPreview
                ? pageType !== "pdf"
                  ? "w-[9in] h-[5.4in] origin-top-left mx-auto transform scale-[1]"
                  : "w-[8.27in] h-[11.69in]"
                : pageType === "pdf"
                ? `w-[8.27in] ${height || "h-[11.6in]"}`
                : `w-[9in] ${"h-[5.4in]"}`
            } report-border-0 canvas-page ${
              !isPreview && activePageId === newPage?.id
                ? "border-2 border-dotted border-primary-blue"
                : "border-none"
            } p-0 relative overflow-hidden mx-auto`}
            onMouseEnter={() => {
              if (!isPreview) {
                setShowActions(true);
                setActivePageId?.(newPage?.id);
              }
            }}
            onMouseLeave={() => {
              if (!isPreview) {
                setShowActions(false);
              }
            }}
            onClick={(e) => {
              if (!isPreview) {
                setActivePageId?.(newPage?.id);
                e.stopPropagation();
              }
            }}
            onDragOver={!isPreview ? handleDragOver : undefined}
            onDrop={!isPreview ? handleDrop : undefined}
          >
            {/* {renderBrandElements()} */}
            <RenderBrandElements />
            {
              // pageStructure?.filter((x:any)=>x.id===activePageId)?.[0]?.elements?.map((element: any, index: number) => (
              elementer?.map((element: any, index: number) => (
                <Rnd
                  key={element?.elementId}
                  size={{
                    width: element.width || 300, // Default width if not set
                    height: element.height || 200, // Default height if not set
                  }}
                  position={{ x: element.x, y: element.y }}
                  onDragStop={(e, d) => {
                    elementSetter(
                      activePageId,
                      updateElementPosition(index, d.x, d.y, element.elementId),
                      setPageStructure
                    );
                  }}
                  enableResizing={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                    topRight: true,
                    bottomRight: true,
                    bottomLeft: true,
                    topLeft: true,
                  }}
                  // resizeHandleClasses={
                  //   element?.elementId === activeElementId && !isPreview
                  //     ? {
                  //         top: "resize-handle",
                  //         right: "resize-handle",
                  //         bottom: "resize-handle",
                  //         left: "resize-handle",
                  //         topRight: "resize-handle",
                  //         bottomRight: "resize-handle",
                  //         bottomLeft: "resize-handle",
                  //         topLeft: "resize-handle",
                  //       }
                  //     : undefined
                  // }
                  className={
                    element?.elementId === activeElementId && !isPreview
                      ? "active-element-border"
                      : ""
                  }
                  onResize={(e, direction, ref, delta, position) => {
                    const updatedElements = [
                      ...elements?.map((el: any) =>
                        //element?.elementId === activeElementId &&
                        el.elementId === element?.elementId
                          ? {
                              ...el,
                              width: ref.offsetWidth,
                              height: ref.offsetHeight,
                            }
                          : el
                      ),
                    ];
                    setElements(updatedElements);
                    elementSetter(
                      activePageId,
                      updatedElements,
                      setPageStructure
                    );
                  }}
                  minWidth={20}
                  minHeight={20}
                  bounds="parent"
                  onClick={() => {
                    setActiveElementIndex(index);
                    setIsCardOpen(true);
                  }}
                  style={{
                    backgroundColor: element.backgroundColor || "#ffffff",
                    pointerEvents: isPreview ? "none" : "auto",
                  }}
                >
                  {element.type === "chart" ? (
                    <div
                      className="report-border-0"
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        pointerEvents: isPreview ? "none" : "auto",
                      }}
                      onClick={() => {
                        setActiveToolbarTab?.({
                          icon: "bi-noise-reduction",
                          text: "Data",
                        });
                        setActiveWidget?.("charts");
                        if (!isPreview) {
                          setActiveElementId(element?.elementId);
                        }
                      }}
                    >
                      <ChartComponent
                        config={element.chartConfig}
                        width={element.width}
                        height={element.height}
                        isDoughnut={
                          element.chartType === "Donut Chart" ? true : false
                        }
                        //isPreview={isPreview}
                        text={element?.content!}
                      />
                    </div>
                  ) : element.type === "image" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        //pointerEvents: 'none'
                      }}
                      className={`cursor-pointer block w-full h-full  focus:outline-none focus:border-none`}
                      onClick={() => {
                        setActiveElementId(element?.elementId);
                      }}
                    >
                      <img
                        src={element.imageUrl}
                        alt="Report element"
                        style={{
                          width: `100%`,
                          height: `100%`,
                          // left: element?.x,
                          // position:'absolute',
                          //  top: element?.y,
                          // padding: 5,
                          opacity: element.opacity,
                          objectFit: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          display: "block",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  ) : element.type === "data-card" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "1rem",
                        // pointerEvents: 'none',
                        fontSize: "30px",
                        borderRadius: "0.5rem",
                        backgroundColor: element.backgroundColor || "#ffffff",
                      }}
                      onClick={() => {
                        setActiveElementId(element?.elementId);
                      }}
                    >
                      {element.cardData && (
                        <>
                          <p
                            className="text-xl text-center font-medium"
                            style={{ color: element.color }}
                          >
                            {element.cardData.title}
                          </p>
                          <p
                            className="text-md text-center font-semibold"
                            style={{ color: element.color }}
                          >
                            {element.cardData.value}
                          </p>
                        </>
                      )}
                    </div>
                  ) : element.type === "content-style" ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "1rem",
                        border:
                          element?.elementId === activeElementId
                            ? "2px solid blue"
                            : "1px solid #e2e8f0",
                        backgroundColor: "#ffffff",
                      }}
                      onClick={() => {
                        setActiveElementId(element?.elementId);
                      }}
                    >
                      {element.cardData?.styleNumber === 1 && (
                        <ul className="space-y-2">
                          {element.cardData?.items?.map(
                            (item: any, i: number) => (
                              <li
                                key={i}
                                className="flex items-center"
                                style={{ paddingLeft: item.level * 16 }}
                              >
                                <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
                                <span className="ml-2">{item.title}</span>
                              </li>
                            )
                          )}
                        </ul>
                      )}

                      {element.cardData?.styleNumber === 2 && (
                        <ul className="space-y-2">
                          {element.cardData?.items?.map(
                            (item: any, i: number) => (
                              <li key={i} className="border-b pb-2">
                                {item.title}
                              </li>
                            )
                          )}
                        </ul>
                      )}

                      {element.cardData?.styleNumber === 3 && (
                        <ul className="space-y-2">
                          {element.cardData?.items?.map(
                            (item: any, i: number) => (
                              <li
                                key={i}
                                className="flex items-center"
                                style={{ paddingLeft: item.level * 16 }}
                              >
                                <span className="w-4 h-4 bg-gray-400 rounded-full"></span>
                                <span className="ml-2">{item.title}</span>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  ) : element.type === "icon" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        fontSize: "30px",
                        color: element.color,
                        border:
                          element.elementId === activeElementId
                            ? "1px solid blue"
                            : "none",
                      }}
                      onClick={() => {
                        setActiveElementId(element?.elementId);
                      }}
                    >
                      {element.component ? (
                        typeof element.component === "function" ? (
                          // Check if it's a function (component) and render it
                          React.createElement(element.component)
                        ) : (
                          <div style={{ color: "red" }}>
                            Invalid Icon Component
                          </div>
                        )
                      ) : (
                        <div style={{ color: "yellow" }}>
                          {/* {console.log(
                              element, "elements"
                            )} */}
                          Invalid Icon Component
                        </div>
                      )}
                    </div>
                  ) : element.type === "shape" ? (
                    <div
                      style={{
                        width: "50%",
                        height: "50%",
                        top: `${element.y}px`,
                        left: `${element.x}px`,
                        clipPath: element.shape.clipPath || "none",
                        color: element.color,
                        backgroundColor: element.color,
                        border: element.border,
                        borderRadius: element.borderRadius,
                      }}
                      className="cursor-pointer block w-full h-full focus:outline-none focus:border-none"
                      onClick={() => setActiveElementId(element?.elementId)}
                    >
                      {console.log(element, "elemr")}
                      {console.log(element.type, "elemt type")}
                      {console.log(element.shape.clipPath, "clip")}
                      <svg
                        width="100%"
                        height="100%"
                        viewBox="0 0 100 100"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <rect x="100" y="100" width="80" height="80" rx="10" />
                      </svg>
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer block w-full h-full focus:outline-none focus:border-none"
                      style={{
                        fontSize: `${element.fontSize}px`,
                        color: element.color,
                        fontWeight: element.fontWeight,
                        fontStyle: element.fontStyle,
                        textAlign: element.textAlign,
                        fontFamily: element.fontFamily,
                        letterSpacing: `${element.letterSpacing}px`,
                        lineHeight: element.lineHeight,
                        opacity: element.opacity,
                        padding: "2px",
                        backgroundColor: element?.backgroundColor,
                        borderWidth: `${element?.borderWidth}px`,
                        borderStyle: element?.borderStyle,
                        borderColor: element?.borderColor,
                        borderRadius: `${element?.borderRadius}px`,
                      }}
                      contentEditable={element.editable}
                      suppressContentEditableWarning={true}
                      onBlur={(e) => {
                        elementSetter(
                          activePageId,
                          updateActiveElement(
                            "content",
                            e.currentTarget.textContent || "",
                            element.elementId,
                            element?.activePageId
                          ),
                          setPageStructure
                        );
                      }}
                      onClick={() => setActiveElementId(element?.elementId)}
                    >
                      {element.content}
                    </div>
                  )}
                  {isCardOpen &&
                    element?.elementId === activeElementId &&
                    !isPreview && (
                      <div
                        ref={cardRef}
                        className="absolute exclude-from-pdf z-[100000] right-3 top-[-30px] flex bg-white border rounded-lg shadow-2xl"
                      >
                        <button
                          onClick={() => {
                            elementSetter(
                              activePageId,
                              duplicateElement(
                                index,
                                element?.elementId,
                                element?.activePageId
                              ),
                              setPageStructure
                            );
                          }}
                          className="m-1 exclude-from-pdf p-1 text-md rounded relative group"
                        >
                          <i className="bi bi-copy hover:bg-gray-200 p-1 rounded-sm"></i>
                          <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                            Duplicate
                          </div>
                        </button>

                        <button
                          onClick={() => {
                            elementSetter(
                              activePageId,
                              deleteElement(
                                index,
                                element?.elementId,
                                element?.activePageId
                              ),
                              setPageStructure
                            );
                          }}
                          className="m-1 exclude-from-pdf p-1 text-md rounded relative group"
                        >
                          <i className="bi bi-trash3 hover:bg-red-500 p-1 rounded-sm"></i>
                          <div className="absolute top-full mb-2 hidden group-hover:flex justify-center p-2 bg-gray-400 text-white text-xs rounded">
                            Delete
                          </div>
                        </button>
                      </div>
                    )}
                </Rnd>
              ))
            }
          </section>
          {showPageBackgroundSelector && !isPreview && (
            <div
              ref={actionsRef}
              className="absolute z-[10000] h-[50px] w-[150px] top-[40%] -right-[1%] px-0 -translate-y-1/2 shadow-lg rounded-lg p-2"
              onMouseEnter={() => setShowPageBackgroundSelector(true)}
              onMouseLeave={() => setShowPageBackgroundSelector(false)}
            >
              <input
                type="color"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPages?.(
                    pages!.map((page: any) => {
                      if (page.id === pageId) {
                        return {
                          ...page,
                          backgroundColor: e.target.value,
                        };
                      }
                      return page;
                    })
                  );
                }}
                value={newPage?.backgroundColor}
                className="cursor-pointer h-[140px] w-full"
              />
            </div>
          )}
          {showActions && !isPreview && (
            <div
              ref={actionsRef}
              className="absolute z-[10000] top-[30%] -right-[1%] px-0 -translate-y-1/2 bg-white shadow-lg rounded-lg p-2"
              onMouseEnter={() => setShowActions(true)}
              onMouseLeave={() => setShowActions(false)}
            >
              <button
                className="block p-2 hover:bg-gray-100 rounded-lg"
                onClick={addNewPage}
              >
                <i className="bi bi-file-earmark-plus text-gray-600"></i>
              </button>
              <button
                onClick={() => duplicatePage?.(pageId!)}
                className="block p-2 hover:bg-gray-100 rounded-lg"
              >
                <i className="bi bi-copy text-gray-600"></i>
              </button>
              <button
                className="block p-2 hover:bg-gray-100 rounded-lg"
                onClick={() => {
                  if (pages)
                    setPages?.(
                      [...pages].filter((page) => page.id !== newPage.id)
                    );
                }}
              >
                <i className="bi bi-trash text-red-600"></i>
              </button>
              <button
                className="block p-2 hover:bg-gray-100 rounded-lg"
                onClick={() => {
                  if (pages) {
                    const currentIndex = pages.findIndex(
                      (page) => page.id === newPage.id
                    );
                    if (currentIndex > 0) {
                      const newPages = [...pages];
                      [newPages[currentIndex], newPages[currentIndex - 1]] = [
                        newPages[currentIndex - 1],
                        newPages[currentIndex],
                      ];
                      setPages?.(newPages);
                      document
                        .getElementById(newPage.id)
                        ?.classList.add("transition-transform", "duration-300");
                      document
                        .getElementById(pages[currentIndex - 1].id)
                        ?.classList.add("transition-transform", "duration-300");
                    }
                  }
                }}
              >
                <i className="bi bi-caret-up text-gray-600"></i>
              </button>
              <button
                className="block p-2 hover:bg-gray-100 rounded-lg"
                onClick={() => {
                  if (pages) {
                    const currentIndex = pages.findIndex(
                      (page) => page.id === newPage.id
                    );
                    if (currentIndex < pages.length - 1) {
                      const newPages = [...pages];
                      [newPages[currentIndex], newPages[currentIndex + 1]] = [
                        newPages[currentIndex + 1],
                        newPages[currentIndex],
                      ];
                      setPages?.(newPages);
                      document
                        .getElementById(newPage.id)
                        ?.classList.add("transition-transform", "duration-300");
                      document
                        .getElementById(pages[currentIndex + 1].id)
                        ?.classList.add("transition-transform", "duration-300");
                    }
                  }
                }}
              >
                <i className="bi bi-caret-down text-gray-600"></i>
              </button>
              <button
                className="block p-2 hover:bg-gray-100 rounded-lg"
                onClick={() => {
                  setShowPageBackgroundSelector(!showPageBackgroundSelector);
                }}
              >
                <i className="bi bi-pencil-square text-gray-600"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    </DndContext>
  );
};

export default Page;
