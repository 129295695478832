import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import ButtonSelect from "../../../../../../../../components/Buttons/ButtunSelect";
import { api as learningPath } from "../../../../../../../../api/learningPath";
import { useMutation, useQuery } from "react-query";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import ResourceLoader from "../../../../../../../../components/loader/ResourceLoader";
import HorizontalTab from "../../../../../../../../components/Tab/HorizontalTab";
import { createResource } from "../../../../../../../../api/uploadResourse";
import { Link } from "react-router-dom";
import { api } from "../../../../../../../../api/learningPath";
import Select from "react-select";

interface Exam {
  value: string;
  label: string;
  // other properties
}
const PathInfo = ({
  values,
  setFieldValue,
  setLogoPreview,
  logoPreview,
  step1Validated,
  setIsCreated,
  setActiveScreen,
  id,
}: any) => {
  const current_path = JSON.parse(sessionStorage.getItem("active-path")!);
  const [useMultipleLanguages, setUseMultipleLanguages] = useState(
    id ? current_path?.languages?.length > 2 : false
  );
  const user = JSON.parse(sessionStorage.getItem("user")!);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [selectedExam, setSelectedExam] = useState<Exam | null>(null);
  const [isExamSelected, setExamSelected] = useState(false);
  const [isExamBefore, setExamBefore] = useState(false);

  const {
    data: exams,
    isLoading,
    refetch,
  } = useQuery(
    ["get-exams"],
    async () => {
      const response = await learningPath.getExams(user?.org?.id);
      console.log(response.data.data.data, "all tags input");
      return response.data?.data.data.map((exam: any) => ({
        value: exam.id,
        label: exam.title,
      }));
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
      enabled: isExamSelected,
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const handleChange = (selectedOption: any) => {
    setSelectedExam(selectedOption);
  };

  const uploadResourceMutation: any = useMutation(
    createResource.uploadResource,
    {
      onMutate: () => {
        setIsUploading(true);
      },
      onSuccess: (data: any) => {
        setIsUploading(false);
        toast.success("File Uploaded successfully!");
        setLogoPreview(data?.data?.data);
      },
    }
  );

  const addPathMutation: any = useMutation(
    id ? api.editLearningPath : learningPath.createLearningPath,
    {
      onSuccess: (data: any) => {
        if (!id)
          sessionStorage.setItem("active-path", JSON.stringify(data?.data));
        toast.remove();
        toast.success(data?.message);
        setIsCreated(true);
        setTimeout(() => {
          setActiveScreen(2);
        }, 100);
      },
      onError: ({ response }) => {
        toast.remove();
        toast.error(response?.data?.message);
      },
    }
  );

  const addPathInfo = () => {
    // console.log(values);

    const startDate = new Date(values.start_date).getTime();
    const endDate = new Date(values.end_date).getTime();

    if (startDate > endDate) {
      toast.error(`Start date cannot be after end date`);
      return;
    }

    const createPathBtn = document.querySelector(
      "#create-path-btn-2"
    ) as HTMLButtonElement;
    createPathBtn.click();
    const formData = new FormData();
    formData.append("name", values.c_title);
    formData.append("description", values.c_des);
    formData.append("coursePoints", values.c_point);
    formData.append("creditPerLearner", values.c_credit);
    formData.append("startDate", values.start_date);
    formData.append("endDate", values.end_date);
    formData.append("type", values.c_type);

    const payload = {
      name: values.c_title,
      description: values.c_des,
      coursePoints: values.c_point,
      creditPerLearner: values.c_credit,
      startDate: values.start_date,
      endDate: values.end_date,
      type: values.c_type,
      status: values?.status ? "published" : "draft",
      imagePath: logoPreview,
      examId: selectedExam?.value,
      examBeforeCourses: isExamBefore,
      languages: [
        {
          language: "english",
          symbol: "en",
          name: values?.c_title || null,
          description: values?.c_des || null,
          isDefault: true,
        },
      ],
    };
    if (useMultipleLanguages) {
      if (
        !values?.c_title_fr ||
        !values?.c_des_fr ||
        !values?.c_title_pr ||
        !values?.c_des_pr
      ) {
        toast.error(`Title and description is required for all languages`);
        return;
      }
      payload.languages.push(
        {
          language: "french",
          symbol: "fr",
          name: values?.c_title_fr || null,
          description: values?.c_des_fr || null,
          isDefault: false,
        },
        {
          language: "portuguese",
          symbol: "pt",
          name: values?.c_title_pr || null,
          description: values?.c_des_pr || null,
          isDefault: false,
        }
      );
    }
    toast.loading("Processing...");
    const payload_ = id
      ? { payload, org_id: user?.org?.id, id: current_path?.id }
      : { payload, org_id: user?.org?.id };
    addPathMutation.mutate(payload_);
  };

  const uploadClientLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      uploadResourceMutation.mutate(formData);
    } else setLogoPreview(null);
  };

  const cancelImagePreview = () => {
    setLogoPreview("");
    // Reset the file input
  };

  const handleStartDateChange = (date: any) => {
    setFieldValue("start_date", date.toISOString());
  };

  const handleEndDateChange = (date: any) => {
    setFieldValue("end_date", date.toISOString());
  };

  const handlePublish = () => {
    sessionStorage.removeItem("current_path");
    toast.success("Published successfully...");
    navigate("/admin/learning-path");
  };

  const tabsData = [
    {
      label: `English`,
      content: (
        <div className="mt-3">
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (English)
          </label>
          <Field name="c_title" className="input-style bg-primaryGray" />
          <ErrorMessage
            name="c_title"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (English)
          </label>
          <Field
            name="c_des"
            as="textarea"
            className="input-style bg-primaryGray"
          />
          <ErrorMessage
            name="c_des"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
    {
      label: `French`,
      content: (
        <div className="mt-3">
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (French)
          </label>
          <Field name="c_title_fr" className="input-style bg-primaryGray" />
          <ErrorMessage
            name="c_title_fr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (French)
          </label>
          <Field
            name="c_des_fr"
            as="textarea"
            className="input-style bg-primaryGray"
          />
          <ErrorMessage
            name="c_des_fr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
    {
      label: `Portuguese`,
      content: (
        <div className="mt-3">
          <label className="block font-semibold w-full text-xs mb-1">
            Path Title (Portuguese)
          </label>
          <Field name="c_title_pr" className="input-style bg-primaryGray" />
          <ErrorMessage
            name="c_title_pr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
          <label className="block font-semibold w-full text-xs mt-5 mb-1">
            Path Description (Portuguese)
          </label>
          <Field
            name="c_des_pr"
            as="textarea"
            className="input-style bg-primaryGray"
          />
          <ErrorMessage
            name="c_des_pr"
            className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
            component="p"
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <h3 className="text-lg items-center font-semibold mt-3 flex justify-between">
        Create Learning Path
        <Link
          to={"/admin/learning-path/"}
          className="btn pl-8 pr-8 bg-primary-blue"
        >
          Back
        </Link>
      </h3>
      {isUploading && <ResourceLoader />}
      <section className="border flex justify-between pr-0 px-7 border-slate-400 rounded-lg mx-3 mt-8">
        <div className="w-[40%] py-7">
          <div>
            <h5 className="text-sm">Upload Path Image</h5>
            <input
              type="file"
              className="hidden"
              id="course-image"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                uploadClientLogo(event);
                setFieldValue("image", event.target.files![0]);
              }}
              accept="image/*"
            />
            <div className="w-[90%] flex-col flex items-center justify-center mt-3 mx-auto border-2 border-dotted border-slate-300 h-[200px] rounded-lg">
              <label htmlFor="course-image" className="btn bg-primary-blue">
                Upload Image
              </label>
              <p className="text-xs text-center px-8 pt-4">
                Select clear image of your path. Please note that GIF files will
                not animate.
              </p>
            </div>
            {logoPreview && (
              <div className="border-2 w-[90%] mx-auto rounded-lg p-5 mt-3 border-dotted border-slate-300">
                <h5 className="flex justify-end ">
                  <i
                    className="bi bi-trash text-red-500 text-sm mr-1"
                    onClick={cancelImagePreview}
                  />
                </h5>
                <img
                  src={logoPreview}
                  alt="course image"
                  className="w-[200px h-[100px] block mx-auto"
                />
              </div>
            )}
          </div>
        </div>
        <div className="rounded-e-lg w-[58%] bg-white p-5 px-8">
          <h5 className="text-lg font-semibold">Learning Path Information</h5>
          <p className="text-xs font-semibold border-b border-gray-300 pb-2 mt-1 mb-5">
            Tell us more about your learning path.
          </p>
          {/* Checkbox for user to indicate if they want to add course details in other languages */}
          <div className="mb-3">
            <label className="block font-semibold w-full text-xs mb-1">
              Would you like to add learning path details in other languages?
            </label>
            <input
              type="checkbox"
              className="mr-2"
              checked={useMultipleLanguages}
              onChange={(e) => setUseMultipleLanguages(e.target.checked)}
            />
            <label className="text-sm">
              Yes, I want to add multiple languages
            </label>
          </div>

          <div className="flex">
            <section className="w-full">
              {/* Conditionally render based on user's choice */}
              {useMultipleLanguages ? (
                <HorizontalTab withPadding={false} tabsData={tabsData} />
              ) : (
                <div>
                  <div>
                    <label className="block font-semibold w-full text-xs mb-1">
                      Path Title
                    </label>
                    <Field
                      name="c_title"
                      className="input-style bg-primaryGray"
                    />
                    <ErrorMessage
                      name="c_title"
                      className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                      component="p"
                    />
                  </div>
                  <div className="mt-5">
                    <label className="block font-semibold w-full text-xs mb-1">
                      Path Description
                    </label>
                    <Field
                      name="c_des"
                      as="textarea"
                      className="input-style bg-primaryGray"
                    />
                    <ErrorMessage
                      name="c_des"
                      className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                      component="p"
                    />
                  </div>
                </div>
              )}

              <div className="my-4">
                <div className="mb-3">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Would you like to add exam to this learning path?
                  </label>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={isExamSelected}
                    onChange={(e) => setExamSelected(e.target.checked)}
                  />
                  <label className="text-sm">Yes, I want to add Exam</label>
                </div>
                {isExamSelected && (
                  <div>
                    <div className="mb-3">
                      <label className="block font-semibold w-full text-xs mb-1">
                        Would you like to add exam before courses ?
                      </label>
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={isExamBefore}
                        onChange={(e) => setExamBefore(e.target.checked)}
                      />
                      <label className="text-sm">Yes, I want to add Exam</label>
                    </div>
                    <div className="mb-6">
                      <label className="block text-sm font-medium mb-2">
                        Select Exam
                      </label>
                      <Select
                        options={exams}
                        isLoading={isLoading}
                        value={selectedExam}
                        onChange={handleChange}
                        placeholder="Select exam"
                        isSearchable
                        className="text-base z-10"
                      />
                      {selectedExam && (
                        <p className="mt-4 text-gray-700">
                          Selected Exam: <strong>{selectedExam?.label}</strong>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-2 flex hidden justify-between">
                <div className="w-[60%]">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Credit per learner
                  </label>
                  <Field
                    name="c_credit"
                    type="number"
                    className="primary-form-input w-full"
                  />
                  <ErrorMessage
                    name="c_credit"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
                <div className="w-[35%]">
                  <label className="block font-semibold w-full text-xs mb-1">
                    Course Point
                  </label>
                  <Field
                    name="c_point"
                    type="number"
                    className="primary-form-input w-full"
                  />
                  <ErrorMessage
                    name="c_point"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
              </div>
              <div className="mt-3 flex items-center justify-start gap-3 w-full">
                <div>
                  <label className="block font-semibold w-full text-xs mb-1">
                    Start Date
                  </label>
                  <ReactDatePicker
                    onChange={handleStartDateChange}
                    selected={values.start_date}
                    dateFormat="dd/MM/yyyy"
                    className="input-style bg-primaryGray"
                  />
                  <ErrorMessage
                    name="start_date"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
                <div>
                  <label className="block font-semibold w-full text-xs mb-1">
                    End Date
                  </label>
                  <ReactDatePicker
                    onChange={handleEndDateChange}
                    selected={values.end_date}
                    dateFormat="dd/MM/yyyy"
                    className="input-style bg-primaryGray"
                  />
                  <ErrorMessage
                    name="end_date"
                    className="text-xs mt-2 ml-2 text-red-500 font-semibold w-full"
                    component="p"
                  />
                </div>
                <div className="w-[35%] flex items-center pt-4">
                  <ButtonSelect
                    text={"Publish"}
                    height={"h-[30px]"}
                    active={values?.status}
                    onClick={() => setFieldValue("status", true)}
                  />
                  &nbsp; &nbsp;
                  <ButtonSelect
                    text={"Draft"}
                    height={"h-[30px]"}
                    active={!values.status}
                    onClick={() => setFieldValue("status", false)}
                  />
                </div>
              </div>
            </section>
          </div>
          <div className="mt-7 flex items-center justify-end">
            <button
              onClick={addPathInfo}
              disabled={!step1Validated}
              style={{ opacity: step1Validated ? 1 : 0.6 }}
              className="btn text-center w-[200px] bg-primary-blue"
            >
              Continue
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PathInfo;
