import React from 'react'
import { v4 } from 'uuid'
import { motion, AnimatePresence } from 'framer-motion'
import Page from './Page'
import { useReportEditor } from '../../hooks/ReportEditorContext'
import { PageType } from '../types/page.types'

// type SlidesType = {
//   pages: any[],
//   setPages: React.Dispatch<React.SetStateAction<any[]>>
// }

const Slides: React.FC<PageType> = ({
  pages, setPages, pageStructure, elementSetter, activePageId,
   setActivePageId, setPageStructure, isPreview,
}) => {

  const { undo, redo, canUndo, canRedo,
       // elements,
        //setElements,
        //  elementSetter,
        // pageStructure,
        // setPageStructure,
        activeElementId, 
        setActiveElementId,
      } = useReportEditor();

  if(isPreview) return null

  return (
    <div className="px-1">
      <AnimatePresence mode="popLayout">
        {
         pages?.length &&
         pages.map((page: any, index: number) => (
          <motion.div
            key={page.id}
            layout
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
            onClick={() => setActivePageId?.(page.id)}
            className={
              `group relative h-[100px] py-1 flex-col text-center mb-2 ${ 
                activePageId === page.id
                  ? 'border-2 border-dotted border-primary-blue'
                  : ''
              } overflow-hidden flex justify-center items-center bg-slate-100 rounded shadow-sm`
            }
          >
            <motion.span layout className='text-xs text-gray-500'>{index + 1}</motion.span>
            <div className='h-[90%] block mx-auto pointer-events-none w-[90%] overflow-hidden bg-white border border-gray-200'>
              <div className="scale-[0.15] origin-center w-[600%] h-[600%] -translate-x-[42%] -translate-y-[42%]">
                <Page 
                  key={v4()}
                  elementer={ pageStructure?.filter((x:any)=>x.activePageId===page.id)}
                  newPage={page}
                  activePageId={activePageId} 
                  isPreview={true}
                  activeElementId={activeElementId}
                  setActiveElementId={setActiveElementId}
                  setActivePageId={setActivePageId}
                  elementSetter={elementSetter}
                  pageStructure={pageStructure}
                  setPageStructure={setPageStructure}
                />
              </div>
            </div>
            <span 
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-md bi bi-trash hover:cursor-pointer text-white opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-10'
              onClick={() => {
                setPages?.(pages.filter((page_: any) => page.id !== page_.id))
              }}
            />
            <div className='absolute inset-0 bg-black opacity-0 group-hover:opacity-30 transition-opacity duration-200' />
            <div className='absolute inset-0 hover:cursor-pointer' />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}

export default Slides