import React from "react";
import { useQuery } from "react-query";

import BadgeIcon from "../../../../../assets/Badge.png";
import BadgeIconOrange from "../../../../../assets/Bagde (5).png";
import BadgeIconGreen from "../../../../../assets/Badge (1).png";

import OverviewCard from "../../../../../components/Cards/OverviewCards";
import { formatCurrency } from "../../billing/site-admin/pages/summary/Summary";
import { api } from "../../../../../api/report";

const Summary = () => {
  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;

  const { data: dashboardSummary, isLoading } = useQuery(
    ["action-plan-summary"],
    () => {
      return api.getReportSummary({ orgId });
    },
    {
      cacheTime: 100,
      staleTime: 100,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      select: (data) => data?.data,
    }
  );

  const summary = (summary) => (isLoading ? 0 : summary);


  return (
    <>
      <header className="grid pt-3 grid-cols-1 md:grid-cols-3 gap-5 w-full">
        <OverviewCard
          title={"Total"}
          total={formatCurrency(summary(dashboardSummary?.reportCount), false)}
          iconPath={BadgeIconGreen}
          py={"py-5"}
          text={
            <p className="flex justify-between text-xs">
              <span>+{summary(dashboardSummary?.todayChange)} today </span>
              <span className="text-green-500">
                +{summary(dashboardSummary?.reportPercentageChange)} today
              </span>
            </p>
          }
          headerTextSize="text-2xl"
        />
        <OverviewCard
          title={"Published"}
          total={formatCurrency(
            summary(dashboardSummary?.publishedCount),
            false
          )}
          iconPath={BadgeIconOrange}
          headerTextSize="text-2xl"
          py="py-5"
          text={
            <p className="flex justify-between text-xs">
              <span>
                +{summary(dashboardSummary?.todayPublishedChange)} today{" "}
              </span>
              <span className="text-green-500">
                +{summary(dashboardSummary?.reportPublishedPercentageChange)}{" "}
                today
              </span>
            </p>
          }
        />
        <OverviewCard
          title={"Drafts"}
          total={formatCurrency(summary(dashboardSummary?.draftCount), false)}
          iconPath={BadgeIcon}
          text={
            <p className="flex justify-between text-xs">
              <span>+{summary(dashboardSummary?.todayDraftChange)} today </span>
              <span className="text-green-500">
                +{summary(dashboardSummary?.reportDraftPercentageChange)} today
              </span>
            </p>
          }
          headerTextSize="text-2xl"
          py="py-5"
        />
      </header>
    </>
  );
};

export default Summary;
