import React, { lazy, useState } from 'react'
import templateSample from '../../../../../../assets/Content structure-bro 1.png'
import  Search from  '../../../../../../components/Inputs/Search'
import { useQuery } from 'react-query'
import { api } from '../../../../../../api/report'
import toast from 'react-hot-toast'
import ConfirmModal from '../../../../../../components/Modal/ConfirmModal'
import Loader from '../../../../../../components/loader/Loader'
import Pagination from '../../../../../../components/pagination/Pagination'
import { useNavigate } from 'react-router-dom'

type reportType = {
  name: string,
  id: string,
}
type templatesType = {
  refetch: any
}
const Templates: React.FC<templatesType> = ({refetch}) => {

  const [search, setSearch] = useState<string>("");
  const [perPage, setPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const user = JSON.parse(window.sessionStorage.getItem("user") || "{}");
  const orgId = user?.org?.id;
  const [selectedReportId, setSelectedReportId] = useState<string | null>(null);
  const navigate = useNavigate()
  const {
    data: reports,
    isLoading,
    //refetch,
  } = useQuery(
    ["all-reports", search, perPage, currentPage],
    async () => {
      const response = await api.getAllReports({
        orgId,
        search,
        perPage,
        currentPage,
      });
      return response; // Ensure the API response matches the `QueryResponse` type
    },
    {
      staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
      cacheTime: 30 * 60 * 1000,
      onError: (error: any) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <div className='p-1'>  
         {
         openConfirmationModal && 
         <ConfirmModal
         title='Confirm Action'
         style={{ width: 500}}
         description={`
          Are you sure you want to open this template. 
          Your current pages will be lost.
          `}
         onYes={() => {
          navigate(`/admin/report/create?action=edit&reportId=${selectedReportId}`)
          toast.loading('Loading Report...')
          refetch({queryKey: ["single-report", {
            orgId,
            selectedReportId,
          } ]})
          setOpenConfirmationModal(false);
         }}
         onClose={() => setOpenConfirmationModal(false)}
      />
      }
        <Search
          onChange={setSearch}
          placeholder={"Search ..."}
          className={"w-full mb-3"}
          bgColor={null}
          iconColor={null}
          onClick={null}
          width={'w-full'}
          value={null}
          mb={''}
          showButton={false}
      />
       {/* <img src={templateSample} className='w-[90%] mb-3 block mx-auto h-[130px]' alt="template name" /> */}
       {
        isLoading ?
        <Loader />
        :
        reports?.data?.length ? 
        reports?.data?.map((report: reportType) => {
          return (
            <div 
              key={report.id} 
              className='mb-3 p-1 hover:cursor-pointer border bg-slate-100 rounded'
              onClick={() => {
                setSelectedReportId(report.id)
                setOpenConfirmationModal(true)
              }}  
          >
              <img src={templateSample} className='w-[90%] mb-3 block mx-auto h-[70px]' alt="template name" />
              <div className='flex justify-center items-center'>
                <h6 className='text-xs font-semibold text-center text-gray-500'>{report.name}</h6>
              </div>
            </div>
          )
        })
        : null
       }
       <section className={`mt-1 px-3 rounded-lg bg-white`}>
            <Pagination
              data={reports?.meta}
              perPage={perPage}
              pageNo={currentPage}
              setPageNo={setCurrentPage}
              setPerPage={setPerPage}
            />
          </section>
    </div>
  )
}

export default Templates