import { useMutation, useQuery } from "react-query"
import { api } from "../../../../../../api/report"
import { chartsTypes, TchartType } from "../components/Datas"
import { api as cohort } from "../../../../../../api/learningPath"
type slugTypes = 'cohortEnrollment' | 'courseCompletion'
export type getReportCourseCompletionPayloadType = {
         cohortIds: string,
         type:'chart',
         slug: slugTypes,
         chart: TchartType
}

export type reportDataCompletionType = {
  slug: slugTypes,
  cohortIds: string
}
const useChartsData = () => {

    const orgId = JSON.parse(sessionStorage.getItem("user")!)?.org?.id;
    const {
        data: cohorts,
      } = useQuery(
        ["cohort",],
        () => {
          return cohort.getCohort({ id: orgId, search: '', perPage: 10000, currentPage: 1, });
        },
        {
          staleTime: 5 * 60 * 1000, // data is considered fresh for 5 minutes
          cacheTime: 30 * 60 * 1000, // data is cached for 30 minutes after becoming stale
          select: (data) => data?.data,
        }
      );

    const  { 
        mutateAsync: getCourseCompletionBarChart, 
        isLoading: getCourseCompletionBarChartLoading
    } = useMutation(api.getReportCourseCompletion)

    const  { 
      mutateAsync: getCohortCompletionBarChart, 
      isLoading: getCohortCompletionBarChartLoading
  } = useMutation(api.getReportCohortCompletion)

  const  { 
    mutateAsync: getReportCompletionDataMutation, 
    isLoading: getReportCompletionDataLoading,
   } = useMutation(api.getReportCompletionData)

    const getReportCourseCompletion = async (payload: getReportCourseCompletionPayloadType) : Promise<any> => {
        const response = await getCourseCompletionBarChart({
            cohortIds: payload.cohortIds,
            type:'chart',
            slug: payload.slug,
            chart:payload.chart
        })
        return response?.data
    }

    const getReportCohortCompletion = async (payload: getReportCourseCompletionPayloadType) : Promise<any> => {
      const response = await getCohortCompletionBarChart({
          cohortIds: payload.cohortIds,
          type:'chart',
          slug: payload.slug,
          chart:payload.chart
      })
      return response?.data
  }

  const getReportCompletionData = async (payload: reportDataCompletionType) : Promise<any> => {
    const response = await getReportCompletionDataMutation({
        cohortIds: payload.cohortIds,
        slug: payload.slug,
    })
    return response?.data
  }

    return {
        getReportCourseCompletion, getReportCohortCompletion,
        getCourseCompletionBarChartLoading, 
        getCohortCompletionBarChartLoading,
        cohorts, getReportCompletionData,
        getReportCompletionDataLoading
    }
}

export default useChartsData