import React, { useState } from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import { ScaleLoader } from "react-spinners";
import moment from "moment/moment";
import {  useNavigate } from "react-router-dom";
import { api } from "../../../../../api/email";
import Search from "../../../../../components/Inputs/Search";

import VerticalMenu from "../../../../../components/dropdown/VerticalMenu";
import MenuItem from "../../../../../components/dropdown/MenuItem";
import Pagination from "../../../../../components/pagination/Pagination";
import ConfirmModal from "../../zoom/components/ConfirmModal";

const AllEmailTemplate = () => {
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const orgId = JSON.parse(window.sessionStorage.getItem("user"))?.org?.id;
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const {
    data: templates,
    isLoading,
    refetch,
  } = useQuery(
    ["get-all-templates", search, perPage, currentPage],
    async () => {
      const response = await api.getAllEmailTemplates({ orgId, search, perPage, currentPage });
      return response.data;
    },
    {
      cacheTime: 100,
      staleTime: 100,
    },
    {
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
  const deleteTemplate = async () => {
    if (!selectedTemplate) return;
    try {
      const response = await api.deleteTemplate({
        orgId,
        templateId: selectedTemplate.templateId,
      });
      setDeleteModal(false);
      toast.success(response.message);
      refetch(); // Refresh the data after deletion
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="p-5 sm:p-10">
      <div className="flex justify-end mb-6">
      <button
          type="button"
          onClick={() => navigate(-1)}
          className={`flex items-center justify-center gap-1  font-semibold 
                       bg-customBlue
                     text-white py-2 px-6 rounded-lg text-sm`}
        >
          <i className="bi bi-arrow-left text-white "></i>
          Back
        </button>
      </div>
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-[#091F46]">All templates</h3>
        <div className=" flex justify-end mt-5">
          <Search
            onChange={setSearch}
            placeholder={"Search ..."}
            className={"w-fit"}
          />
        </div>
      </div>
      <div className="bg-white p-3 rounded-lg shadow">
        {isLoading ? (
          <div className="mt-10 flex justify-center items-center">
            <ScaleLoader color="#FF9100" />
          </div>
        ) : (
          <div className="mt-4 ">
            <div className="">
              <table className="table-container">
                <thead className="table-header">
                  <tr>
                    <th className="table-head text-left">ID</th>
                    <th className="table-head text-left">Name</th>
                    <th className="table-head text-left">Description</th>
                    <th className="table-head text-left">Pending</th>
                    <th className="table-head text-left">Sent</th>
                    <th className="table-head text-left">% Sent</th>
                    <th className="table-head text-left">Created By</th>
                    <th className="table-head text-left">Last Modified</th>
                    <th className="table-head text-left">Action</th>
                  </tr>
                </thead>

                <tbody className="table-body text-xs">
                  {templates?.length > 0 ? (
                    templates?.map((template, index) => (
                      <tr key={index}>
                        <td className="table-data text-left">{index + 1}</td>
                        <td className="table-data text-left">
                          {template.name}
                        </td>
                        <td className="table-data text-left">
                          {template.subject}
                        </td>
                        <td className="table-data text-left">
                          {template.pending}
                        </td>
                        <td className="table-data text-left">
                          {template.sent}
                        </td>
                        <td className="table-data text-left">
                          {template.percentSen}
                        </td>
                        <td className="table-data text-left">
                          {template.createdBy?.name}
                        </td>

                        <td className="table-data ">
                          {moment(template.updatedAt).format(
                            "YYYY/MM/DD, h:mm a"
                          )}
                        </td>

                        <td className="table-data flex justify-center px-2">
                          {/* <span className="flex justify-center"> */}
                          <VerticalMenu>
                            <div className="w-fit flex flex-col gap-2">
                              <MenuItem
                                icon={
                                  <i className="bi bi-pencil-fill cursor-pointer"></i>
                                }
                                label="Edit"
                                onClick={() => {
                                  navigate(
                                    `/admin/email/edit-template/${template.templateId}`
                                  );
                                }}
                              />
                              {/* <MenuItem
                                icon={
                                  <i className="bi bi-eye cursor-pointer"></i>
                                }
                                label="View"
                                // onClick={() =>

                                // }
                              /> */}

                              <MenuItem
                                icon={
                                  <i className="bi bi-trash3-fill  text-red-500 cursor-pointer"></i>
                                }
                                label="Delete"
                                onClick={() => {
                                  setSelectedTemplate(template);
                                  setDeleteModal(true);
                                }}
                              />
                            </div>
                          </VerticalMenu>
                          
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="table-data" colSpan="12">
                        <p className="text-center">No Data Available</p>
                      </td>
                    </tr>
                  )}
                </tbody>
                {deleteModal && (
                  <ConfirmModal
                    title="Delete Template"
                    description={`Are you sure you want to delete ‘‘${selectedTemplate?.name}”?`}
                    onYes={deleteTemplate}
                    onClose={() => setDeleteModal(false)}
                  />
                )}
              </table>
            </div>
          </div>
        )}
      </div>
      <section className={`mt-1 px-3 rouded-lg `}>
          <Pagination
            data={templates?.meta}
            perPage={perPage}
            pageNo={currentPage}
            setPageNo={setCurrentPage}
            setPerPage={setPerPage}
          />
        </section>
    </div>
  );
};

export default AllEmailTemplate;
