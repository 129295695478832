import React, { useState } from "react";
import Select from "react-select";
import { useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { api } from "../../../../../api/content";

const MultiSelectInput = ({
  selectedValues,
  onValuesChange,
  createTagUrl,
  deleteTagUrl,
  orgId,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10); // Adjust perPage as needed
  const [inputValue, setInputValue] = useState("");

  // Fetch tags dynamically based on search input
  const { data: tagsData, isLoading, refetch } = useQuery(
    ["all-tags", search, perPage, currentPage],
    async () => {
      const response = await api.getAllTags({
        orgId,
        search,
        perPage,
        currentPage,
      });
      return response.data?.map((tag) => ({
        label: tag.title,
        value: tag.id,
      }));
    },
    {
      
      onError: (error) => {
        toast.error(error?.response?.data?.message || "Failed to fetch tags");
      },
    }
  );

  const handleInputChange = (input) => {
    setSearch(input); // Trigger search via the `search` query
    setInputValue(input);
  };

  const createTag = async (name) => {
    const payload = {
      title: name,
    }
    
    try {
      const response = await api.createTag({ orgId,  payload });
    //   console.log(response.message, "response c-tag");
      const newTag = { label: response.data.title, value: response.data.id };
      toast.success(response.message);
      refetch()
      return newTag;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to create tag");
    }
  };

  const handleKeyDown = async (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const existingTag = tagsData?.find(
        (tag) => tag.label.toLowerCase() === inputValue.toLowerCase()
      );

      if (existingTag) {
        toast.error("Tag already exists");
      } else {
        const newTag = await createTag(inputValue.trim());
        if (newTag) {
          onValuesChange([...selectedValues, newTag.value]);
        }
      }
      setInputValue("");
    }
  };

  const handleSelectChange = (selectedOptions) => {
    onValuesChange(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
  };

  const handleRemoveTag = async (tagId) => {
    try {
      const response = await api.deleteTag({
        orgId,
        contentId: tagId,
      });

    //   onValuesChange(selectedValues.filter((id) => id !== tagId));
      toast.success(response.message);
      refetch();
    } catch (error) {
      toast.error("Failed to delete tag");
    }
  };

  return (
    <div>
      <Select
        isMulti
        isLoading={isLoading}
        options={tagsData || []}
        value={(tagsData || []).filter((tag) =>
          selectedValues.includes(tag.value)
        )}
        onChange={handleSelectChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onKeyDown={handleKeyDown}
        placeholder="Search or create a tag"
        noOptionsMessage={() => "No tags found. Press enter to create."}
        className="basic-multi-select"
        classNamePrefix="select"
      />

      <div className="mt-4 flex flex-wrap">
        {selectedValues.map((tagId) => {
          const tag = (tagsData || []).find((t) => t.value === tagId);
          return (
            <span
              key={tagId}
              className="w-fit flex items-center bg-gray-200 rounded-full px-3 py-1 mb-2 mr-2"
            >
              {tag?.label}
              <button
                onClick={() => handleRemoveTag(tagId)}
                className="ml-2 text-red-500 focus:outline-none"
              >
                &times;
              </button>
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelectInput;
