import React,{Suspense } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import {
  useReportModal,
  documentTypes,
  documentSizes,
  unitOptions,
} from "../hooks/useReportModal";

import TypeCenterModal from "../../../../../components/Modal/TypeCenterModal";
import useSelectStyles from "../../../../../hooks/useSelectStyles";
import CustomDropdown from "../../../../../components/dropdown/CustomDropdown";
import { Link } from "react-router-dom";

const CenteredModal: any = React.lazy(() => import('../../../../../components/Modal/CenteredModal'))
interface CreateReportModalProps {
  openNewReportModal: boolean;
  setOpenNewReportModal: (isOpen: boolean) => void;
  selectedReport: any;
  setSelectedReport: (report: any) => void;
}

const CreateReportModal: React.FC<CreateReportModalProps> = ({
  openNewReportModal,
  setOpenNewReportModal,
  selectedReport,
  setSelectedReport,
}) => {
  const selectStyles = useSelectStyles();

  const {
    selectedDocumentType,
    setSelectedDocumentType,
    selectedDocumentSize,
    setSelectedDocumentSize,
    customWidth,
    setCustomWidth,
    customHeight,
    setCustomHeight,
    unit,
    setUnit,
    isCustomSize,
    isEditMode,
    initialValues,
    validationSchema,
    handleSubmit,
  } = useReportModal(selectedReport, setSelectedReport, openNewReportModal,
    setOpenNewReportModal,);


  const localSelectStyles = {
    control: (provided:any, state:any) => ({
      ...provided,
      display: "flex",
      flex: "1",
      fontSize: "0.875rem", // Tailwind text-sm
      appearance: "none",
      backgroundColor: "#E8EDF4", // Tailwind bg-primaryGray
      borderColor: state.isFocused ? "#3b82f6" : "#d1d5db", // Tailwind for focus:ring-primaryBlue and border-gray-300
      borderRadius: "0.125rem", // Tailwind rounded-sm
      padding: "0.25rem 1rem", // Tailwind py-3 px-4
      color: "#374151", // Tailwind text-gray-700
      boxShadow: state.isFocused ? "0 0 0 2px #3b82f6" : "", // Tailwind focus:ring-primaryBlue
      "&:hover": {
        borderColor: "#3b82f6", // Tailwind focus:border-primaryBlue
      },
    }),
    placeholder: (provided:any) => ({
      ...provided,
      color: "#9ca3af", // Tailwind placeholder-gray-400
    }),
    menu: (provided:any) => ({
      ...provided,
      backgroundColor: "white",
      borderRadius: "0.125rem", // Tailwind rounded-sm
      overflowY: "auto", // Ensure scrolling
      zIndex: 999, // Ensure menu is above other elements
    }),
    menuList: (provided:any) => ({
      ...provided,
      maxHeight: "110px", // Limit height for list scrolling
      overflowY: "auto", // Ensure scrolling inside menuList
    }),
  };
  

  return (
    <Suspense fallback={<div>Loading...</div>}>
    <CenteredModal
      title={isEditMode ? "Edit Report" : "Create Report"}
      hasHeader={true}
      open={openNewReportModal}
      setOpen={setOpenNewReportModal}
      width="w-[600px]"
      // width={'w-[550px] h-auto overflow-y-auto'}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="h-full text-left">
              <div className="mt-5 mb-4">
                <label className="block mb-1">Name</label>
                <Field
                  name="name"
                  className="input-style bg-primaryGray"
                  placeholder="Enter name"
                />
                <ErrorMessage
                  name="name"
                  component="p"
                  className="error-style"
                />
              </div>

              <div className="mb-4">
                <label className="block mb-1">Description</label>
                <Field
                  name="description"
                  className="input-style bg-primaryGray"
                  placeholder="Enter description"
                />
                <ErrorMessage
                  name="description"
                  component="p"
                  className="error-style"
                />
              </div>

              <div className="flex flex-col mb-4 ">
                <label className="block mb-1 text-base font-medium text-gray600">
                  Document Type
                </label>
                <Select
                  options={documentTypes}
                  value={selectedDocumentType}
                  onChange={setSelectedDocumentType}
                  styles={localSelectStyles}
                  placeholder="Select a role"
                  className="text-base"
                  menuPlacement="auto"
                />
              </div>

              {/* <section className='grid grid-cols-1 gap-5 mt-1'>
                       <div>
                        <label htmlFor="type" className='text-xs'>
                           Field Type <span className='text-lg text-red-500'>*</span>
                        </label>
                        <CustomDropdown
                        options={documentSizes}
                        value={selectedDocumentSize}
                        onChange={setSelectedDocumentSize}
                        // styles={selectStyles}
                      
                        // className="text-base"
                            // options={ documentSizes.map( (type: any) => {
                            //      return {alue: type, label: type, v }
                            //   })
                            // }
                            // value={{ label: values.t, value: values.type }} 
                            // onChange={ (value:any) => {
                            //   setFieldValue('type', value?.value)
                            // }}
                            onBlur={ (value: any) => {
                                if(!value?.value){
                                    // setFieldTouched('type', true, true)
                                  }
                            }}
                            name={'type'}
                            className={'bg-primaryGray select-custom'}
                            width={'w-full'}
                            placeholder={'Select Type'}
                          />
                          <p className='mt-2 text-red-500 text-sm'>
                            <ErrorMessage name='type' />
                          </p>
                       </div>
                 </section> */}

              <div className="mb-4">
                <label className="block mb-1 text-base font-medium text-gray600">
                  Document Size
                </label>
                <Select
                  options={documentSizes}
                  value={selectedDocumentSize}
                  onChange={setSelectedDocumentSize}
                  styles={localSelectStyles}
                  placeholder="Select a role"
                  className="text-base"
                />
              </div>

              {isCustomSize && (
                <div className="flex items-center gap-2 ">
                  <input
                    type="number"
                    placeholder="Width"
                    className="p-3 border rounded w-1/3"
                    value={customWidth}
                    onChange={(e) => setCustomWidth(e.target.value)}
                  />
                  <span className="text-gray-600">×</span>
                  <input
                    type="number"
                    placeholder="Height"
                    className="p-3 border rounded w-1/3"
                    value={customHeight}
                    onChange={(e) => setCustomHeight(e.target.value)}
                  />
                  <Select
                    options={unitOptions}
                    value={unit}
                    onChange={setUnit}
                    className="w-1/3"
                  />
                </div>
              )}
              {/* <Link to={"/admin/report/create"}> */}
              <div className="mt-10">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className=" bg-primaryBlue text-white w-full py-3 rounded-md"
                >
                  {isSubmitting ? (
                    <ClipLoader color="#fff" />
                  ) : isEditMode ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
              {/* </Link> */}
            </div>
          </Form>
        )}
      </Formik>
    </CenteredModal>
    </Suspense>
  );
};

export default CreateReportModal;
