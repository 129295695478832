import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ReportEditorProvider } from './hooks/ReportEditorContext'
import ViewReport from './viewReport/ViewReport'
import CreateReport from './createReport/CreateReport'
import TextEditor from './TextEditor/TextEditor'
import BrandEditor from './TextEditor/BrandEditor'
import { BrandProvider } from './hooks/useBrandContext'
import TestImage from './TestImage'
import IconEditor from './TextEditor/IconEditor'
import ShapesEditor from './TextEditor/ShapesEditor'
import Shapes from './createReport/components/Shapes'


const ReportRoute: React.FC = () : JSX.Element => {
  return (
    <React.Fragment>
       <ReportEditorProvider>
        <BrandProvider>
         <Routes>
            <Route path="/" element={<ViewReport />} />
            <Route path="/create" element={<CreateReport />} />
            <Route path="/editor" element={<TextEditor />} />
            <Route path="/brand" element={<BrandEditor />} />
            <Route path="/test" element={<TestImage />} />
            <Route path="/icon" element={<IconEditor />} />
            {/* <Route path="/shapes" element={<ShapesEditor />} /> */}
            {/* <Route path="/shapes" element={<Shapes />} /> */}
        </Routes>
        </BrandProvider>
        </ReportEditorProvider>
    </React.Fragment>
  )
}

export default ReportRoute