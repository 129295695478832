import React from 'react'
import HorizontalTab from '../../../../../components/Tab/HorizontalTab';
import AllCertificates from './AllCertificates';
// import CertificatesSummary from './CertificatesSummary';
import DefaultCertificatesTemplate from './DefaultCertificateTemplate';
import { Link } from 'react-router-dom';

const Certificates = () => {
    const tabsData = [
        {
          label: `My Certificates`,
          content: <AllCertificates />,
        },
        {
          label: `Default Template`,
          content: <DefaultCertificatesTemplate  />,
        },
        // {
        //   label: `Summary`,
        //   content: <CertificatesSummary />,
        // },
        
      ];
  return (
    <div className="p-5 sm:p-10">
        <div className="flex justify-between items-center mb-6">
        <h1 className="text-lg font-semibold text-[#091F46]">Certificates</h1>
        <Link to={"/admin/certificate/create-certificate"}>
            <button className="p-3 bg-customBlue text-white text-xs rounded-md">
              + Create Template
            </button>
          </Link>
        </div>
        <HorizontalTab tabsData={tabsData} />
    </div>
  )
}

export default Certificates